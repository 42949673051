/* eslint-disable */

/*!
  Highlight.js v11.6.0 (git: bed790f3f3)
  (c) 2006-2022 undefined and other contributors
  License: BSD-3-Clause
 */
var hljs = (function () {
	'use strict';
	var e = { exports: {} };
	function t(e) {
		return (
			e instanceof Map
				? (e.clear =
						e.delete =
						e.set =
							() => {
								throw Error('map is read-only');
							})
				: e instanceof Set &&
				  (e.add =
						e.clear =
						e.delete =
							() => {
								throw Error('set is read-only');
							}),
			Object.freeze(e),
			Object.getOwnPropertyNames(e).forEach((n) => {
				var i = e[n];
				'object' != typeof i || Object.isFrozen(i) || t(i);
			}),
			e
		);
	}
	(e.exports = t), (e.exports.default = t);
	class n {
		constructor(e) {
			void 0 === e.data && (e.data = {}),
				(this.data = e.data),
				(this.isMatchIgnored = !1);
		}
		ignoreMatch() {
			this.isMatchIgnored = !0;
		}
	}
	function i(e) {
		return e
			.replace(/&/g, '&amp;')
			.replace(/</g, '&lt;')
			.replace(/>/g, '&gt;')
			.replace(/"/g, '&quot;')
			.replace(/'/g, '&#x27;');
	}
	function r(e, ...t) {
		const n = Object.create(null);
		for (const t in e) n[t] = e[t];
		return (
			t.forEach((e) => {
				for (const t in e) n[t] = e[t];
			}),
			n
		);
	}
	const s = (e) => !!e.scope || (e.sublanguage && e.language);
	class o {
		constructor(e, t) {
			(this.buffer = ''),
				(this.classPrefix = t.classPrefix),
				e.walk(this);
		}
		addText(e) {
			this.buffer += i(e);
		}
		openNode(e) {
			if (!s(e)) return;
			let t = '';
			(t = e.sublanguage
				? 'language-' + e.language
				: ((e, { prefix: t }) => {
						if (e.includes('.')) {
							const n = e.split('.');
							return [
								`${t}${n.shift()}`,
								...n.map((e, t) => `${e}${'_'.repeat(t + 1)}`),
							].join(' ');
						}
						return `${t}${e}`;
				  })(e.scope, { prefix: this.classPrefix })),
				this.span(t);
		}
		closeNode(e) {
			s(e) && (this.buffer += '</span>');
		}
		value() {
			return this.buffer;
		}
		span(e) {
			this.buffer += `<span class="${e}">`;
		}
	}
	const a = (e = {}) => {
		const t = { children: [] };
		return Object.assign(t, e), t;
	};
	class c {
		constructor() {
			(this.rootNode = a()), (this.stack = [this.rootNode]);
		}
		get top() {
			return this.stack[this.stack.length - 1];
		}
		get root() {
			return this.rootNode;
		}
		add(e) {
			this.top.children.push(e);
		}
		openNode(e) {
			const t = a({ scope: e });
			this.add(t), this.stack.push(t);
		}
		closeNode() {
			if (this.stack.length > 1) return this.stack.pop();
		}
		closeAllNodes() {
			for (; this.closeNode(); );
		}
		toJSON() {
			return JSON.stringify(this.rootNode, null, 4);
		}
		walk(e) {
			return this.constructor._walk(e, this.rootNode);
		}
		static _walk(e, t) {
			return (
				'string' == typeof t
					? e.addText(t)
					: t.children &&
					  (e.openNode(t),
					  t.children.forEach((t) => this._walk(e, t)),
					  e.closeNode(t)),
				e
			);
		}
		static _collapse(e) {
			'string' != typeof e &&
				e.children &&
				(e.children.every((e) => 'string' == typeof e)
					? (e.children = [e.children.join('')])
					: e.children.forEach((e) => {
							c._collapse(e);
					  }));
		}
	}
	class l extends c {
		constructor(e) {
			super(), (this.options = e);
		}
		addKeyword(e, t) {
			'' !== e && (this.openNode(t), this.addText(e), this.closeNode());
		}
		addText(e) {
			'' !== e && this.add(e);
		}
		addSublanguage(e, t) {
			const n = e.root;
			(n.sublanguage = !0), (n.language = t), this.add(n);
		}
		toHTML() {
			return new o(this, this.options).value();
		}
		finalize() {
			return !0;
		}
	}
	function g(e) {
		return e ? ('string' == typeof e ? e : e.source) : null;
	}
	function d(e) {
		return p('(?=', e, ')');
	}
	function u(e) {
		return p('(?:', e, ')*');
	}
	function h(e) {
		return p('(?:', e, ')?');
	}
	function p(...e) {
		return e.map((e) => g(e)).join('');
	}
	function f(...e) {
		const t = ((e) => {
			const t = e[e.length - 1];
			return 'object' == typeof t && t.constructor === Object
				? (e.splice(e.length - 1, 1), t)
				: {};
		})(e);
		return (
			'(' + (t.capture ? '' : '?:') + e.map((e) => g(e)).join('|') + ')'
		);
	}
	function b(e) {
		return RegExp(e.toString() + '|').exec('').length - 1;
	}
	const m = /\[(?:[^\\\]]|\\.)*\]|\(\??|\\([1-9][0-9]*)|\\./;
	function E(e, { joinWith: t }) {
		let n = 0;
		return e
			.map((e) => {
				n += 1;
				const t = n;
				let i = g(e),
					r = '';
				for (; i.length > 0; ) {
					const e = m.exec(i);
					if (!e) {
						r += i;
						break;
					}
					(r += i.substring(0, e.index)),
						(i = i.substring(e.index + e[0].length)),
						'\\' === e[0][0] && e[1]
							? (r += '\\' + (Number(e[1]) + t))
							: ((r += e[0]), '(' === e[0] && n++);
				}
				return r;
			})
			.map((e) => `(${e})`)
			.join(t);
	}
	const x = '[a-zA-Z]\\w*',
		w = '[a-zA-Z_]\\w*',
		y = '\\b\\d+(\\.\\d+)?',
		_ =
			'(-?)(\\b0[xX][a-fA-F0-9]+|(\\b\\d+(\\.\\d*)?|\\.\\d+)([eE][-+]?\\d+)?)',
		O = '\\b(0b[01]+)',
		v = {
			begin: '\\\\[\\s\\S]',
			relevance: 0,
		},
		N = {
			scope: 'string',
			begin: "'",
			end: "'",
			illegal: '\\n',
			contains: [v],
		},
		k = {
			scope: 'string',
			begin: '"',
			end: '"',
			illegal: '\\n',
			contains: [v],
		},
		M = (e, t, n = {}) => {
			const i = r(
				{ scope: 'comment', begin: e, end: t, contains: [] },
				n,
			);
			i.contains.push({
				scope: 'doctag',
				begin: '[ ]*(?=(TODO|FIXME|NOTE|BUG|OPTIMIZE|HACK|XXX):)',
				end: /(TODO|FIXME|NOTE|BUG|OPTIMIZE|HACK|XXX):/,
				excludeBegin: !0,
				relevance: 0,
			});
			const s = f(
				'I',
				'a',
				'is',
				'so',
				'us',
				'to',
				'at',
				'if',
				'in',
				'it',
				'on',
				/[A-Za-z]+['](d|ve|re|ll|t|s|n)/,
				/[A-Za-z]+[-][a-z]+/,
				/[A-Za-z][a-z]{2,}/,
			);
			return (
				i.contains.push({
					begin: p(/[ ]+/, '(', s, /[.]?[:]?([.][ ]|[ ])/, '){3}'),
				}),
				i
			);
		},
		S = M('//', '$'),
		R = M('/\\*', '\\*/'),
		j = M('#', '$');
	var A = Object.freeze({
		__proto__: null,
		MATCH_NOTHING_RE: /\b\B/,
		IDENT_RE: x,
		UNDERSCORE_IDENT_RE: w,
		NUMBER_RE: y,
		C_NUMBER_RE: _,
		BINARY_NUMBER_RE: O,
		RE_STARTERS_RE:
			'!|!=|!==|%|%=|&|&&|&=|\\*|\\*=|\\+|\\+=|,|-|-=|/=|/|:|;|<<|<<=|<=|<|===|==|=|>>>=|>>=|>=|>>>|>>|>|\\?|\\[|\\{|\\(|\\^|\\^=|\\||\\|=|\\|\\||~',
		SHEBANG: (e = {}) => {
			const t = /^#![ ]*\//;
			return (
				e.binary && (e.begin = p(t, /.*\b/, e.binary, /\b.*/)),
				r(
					{
						scope: 'meta',
						begin: t,
						end: /$/,
						relevance: 0,
						'on:begin': (e, t) => {
							0 !== e.index && t.ignoreMatch();
						},
					},
					e,
				)
			);
		},
		BACKSLASH_ESCAPE: v,
		APOS_STRING_MODE: N,
		QUOTE_STRING_MODE: k,
		PHRASAL_WORDS_MODE: {
			begin: /\b(a|an|the|are|I'm|isn't|don't|doesn't|won't|but|just|should|pretty|simply|enough|gonna|going|wtf|so|such|will|you|your|they|like|more)\b/,
		},
		COMMENT: M,
		C_LINE_COMMENT_MODE: S,
		C_BLOCK_COMMENT_MODE: R,
		HASH_COMMENT_MODE: j,
		NUMBER_MODE: { scope: 'number', begin: y, relevance: 0 },
		C_NUMBER_MODE: { scope: 'number', begin: _, relevance: 0 },
		BINARY_NUMBER_MODE: { scope: 'number', begin: O, relevance: 0 },
		REGEXP_MODE: {
			begin: /(?=\/[^/\n]*\/)/,
			contains: [
				{
					scope: 'regexp',
					begin: /\//,
					end: /\/[gimuy]*/,
					illegal: /\n/,
					contains: [
						v,
						{ begin: /\[/, end: /\]/, relevance: 0, contains: [v] },
					],
				},
			],
		},
		TITLE_MODE: { scope: 'title', begin: x, relevance: 0 },
		UNDERSCORE_TITLE_MODE: { scope: 'title', begin: w, relevance: 0 },
		METHOD_GUARD: {
			begin: '\\.\\s*[a-zA-Z_]\\w*',
			relevance: 0,
		},
		END_SAME_AS_BEGIN: (e) =>
			Object.assign(e, {
				'on:begin': (e, t) => {
					t.data._beginMatch = e[1];
				},
				'on:end': (e, t) => {
					t.data._beginMatch !== e[1] && t.ignoreMatch();
				},
			}),
	});
	function I(e, t) {
		'.' === e.input[e.index - 1] && t.ignoreMatch();
	}
	function T(e, t) {
		void 0 !== e.className && ((e.scope = e.className), delete e.className);
	}
	function L(e, t) {
		t &&
			e.beginKeywords &&
			((e.begin =
				'\\b(' +
				e.beginKeywords.split(' ').join('|') +
				')(?!\\.)(?=\\b|\\s)'),
			(e.__beforeBegin = I),
			(e.keywords = e.keywords || e.beginKeywords),
			delete e.beginKeywords,
			void 0 === e.relevance && (e.relevance = 0));
	}
	function B(e, t) {
		Array.isArray(e.illegal) && (e.illegal = f(...e.illegal));
	}
	function D(e, t) {
		if (e.match) {
			if (e.begin || e.end)
				throw Error('begin & end are not supported with match');
			(e.begin = e.match), delete e.match;
		}
	}
	function H(e, t) {
		void 0 === e.relevance && (e.relevance = 1);
	}
	const P = (e, t) => {
			if (!e.beforeMatch) return;
			if (e.starts) throw Error('beforeMatch cannot be used with starts');
			const n = Object.assign({}, e);
			Object.keys(e).forEach((t) => {
				delete e[t];
			}),
				(e.keywords = n.keywords),
				(e.begin = p(n.beforeMatch, d(n.begin))),
				(e.starts = {
					relevance: 0,
					contains: [Object.assign(n, { endsParent: !0 })],
				}),
				(e.relevance = 0),
				delete n.beforeMatch;
		},
		C = [
			'of',
			'and',
			'for',
			'in',
			'not',
			'or',
			'if',
			'then',
			'parent',
			'list',
			'value',
		];
	function $(e, t, n = 'keyword') {
		const i = Object.create(null);
		return (
			'string' == typeof e
				? r(n, e.split(' '))
				: Array.isArray(e)
				? r(n, e)
				: Object.keys(e).forEach((n) => {
						Object.assign(i, $(e[n], t, n));
				  }),
			i
		);
		function r(e, n) {
			t && (n = n.map((e) => e.toLowerCase())),
				n.forEach((t) => {
					const n = t.split('|');
					i[n[0]] = [e, U(n[0], n[1])];
				});
		}
	}
	function U(e, t) {
		return t ? Number(t) : ((e) => C.includes(e.toLowerCase()))(e) ? 0 : 1;
	}
	const z = {},
		K = (e) => {
			console.error(e);
		},
		W = (e, ...t) => {
			console.log('WARN: ' + e, ...t);
		},
		X = (e, t) => {
			z[`${e}/${t}`] ||
				(console.log(`Deprecated as of ${e}. ${t}`),
				(z[`${e}/${t}`] = !0));
		},
		G = Error();
	function Z(e, t, { key: n }) {
		let i = 0;
		const r = e[n],
			s = {},
			o = {};
		for (let e = 1; e <= t.length; e++)
			(o[e + i] = r[e]), (s[e + i] = !0), (i += b(t[e - 1]));
		(e[n] = o), (e[n]._emit = s), (e[n]._multi = !0);
	}
	function F(e) {
		((e) => {
			e.scope &&
				'object' == typeof e.scope &&
				null !== e.scope &&
				((e.beginScope = e.scope), delete e.scope);
		})(e),
			'string' == typeof e.beginScope &&
				(e.beginScope = {
					_wrap: e.beginScope,
				}),
			'string' == typeof e.endScope &&
				(e.endScope = { _wrap: e.endScope }),
			((e) => {
				if (Array.isArray(e.begin)) {
					if (e.skip || e.excludeBegin || e.returnBegin)
						throw (
							(K(
								'skip, excludeBegin, returnBegin not compatible with beginScope: {}',
							),
							G)
						);
					if (
						'object' != typeof e.beginScope ||
						null === e.beginScope
					)
						throw (K('beginScope must be object'), G);
					Z(e, e.begin, { key: 'beginScope' }),
						(e.begin = E(e.begin, { joinWith: '' }));
				}
			})(e),
			((e) => {
				if (Array.isArray(e.end)) {
					if (e.skip || e.excludeEnd || e.returnEnd)
						throw (
							(K(
								'skip, excludeEnd, returnEnd not compatible with endScope: {}',
							),
							G)
						);
					if ('object' != typeof e.endScope || null === e.endScope)
						throw (K('endScope must be object'), G);
					Z(e, e.end, { key: 'endScope' }),
						(e.end = E(e.end, { joinWith: '' }));
				}
			})(e);
	}
	function V(e) {
		function t(t, n) {
			return RegExp(
				g(t),
				'm' +
					(e.case_insensitive ? 'i' : '') +
					(e.unicodeRegex ? 'u' : '') +
					(n ? 'g' : ''),
			);
		}
		class n {
			constructor() {
				(this.matchIndexes = {}),
					(this.regexes = []),
					(this.matchAt = 1),
					(this.position = 0);
			}
			addRule(e, t) {
				(t.position = this.position++),
					(this.matchIndexes[this.matchAt] = t),
					this.regexes.push([t, e]),
					(this.matchAt += b(e) + 1);
			}
			compile() {
				0 === this.regexes.length && (this.exec = () => null);
				const e = this.regexes.map((e) => e[1]);
				(this.matcherRe = t(E(e, { joinWith: '|' }), !0)),
					(this.lastIndex = 0);
			}
			exec(e) {
				this.matcherRe.lastIndex = this.lastIndex;
				const t = this.matcherRe.exec(e);
				if (!t) return null;
				const n = t.findIndex((e, t) => t > 0 && void 0 !== e),
					i = this.matchIndexes[n];
				return t.splice(0, n), Object.assign(t, i);
			}
		}
		class i {
			constructor() {
				(this.rules = []),
					(this.multiRegexes = []),
					(this.count = 0),
					(this.lastIndex = 0),
					(this.regexIndex = 0);
			}
			getMatcher(e) {
				if (this.multiRegexes[e]) return this.multiRegexes[e];
				const t = new n();
				return (
					this.rules.slice(e).forEach(([e, n]) => t.addRule(e, n)),
					t.compile(),
					(this.multiRegexes[e] = t),
					t
				);
			}
			resumingScanAtSamePosition() {
				return 0 !== this.regexIndex;
			}
			considerAll() {
				this.regexIndex = 0;
			}
			addRule(e, t) {
				this.rules.push([e, t]), 'begin' === t.type && this.count++;
			}
			exec(e) {
				const t = this.getMatcher(this.regexIndex);
				t.lastIndex = this.lastIndex;
				let n = t.exec(e);
				if (this.resumingScanAtSamePosition())
					if (n && n.index === this.lastIndex);
					else {
						const t = this.getMatcher(0);
						(t.lastIndex = this.lastIndex + 1), (n = t.exec(e));
					}
				return (
					n &&
						((this.regexIndex += n.position + 1),
						this.regexIndex === this.count && this.considerAll()),
					n
				);
			}
		}
		if (
			(e.compilerExtensions || (e.compilerExtensions = []),
			e.contains && e.contains.includes('self'))
		)
			throw Error(
				'ERR: contains `self` is not supported at the top-level of a language.  See documentation.',
			);
		return (
			(e.classNameAliases = r(e.classNameAliases || {})),
			(function n(s, o) {
				const a = s;
				if (s.isCompiled) return a;
				[T, D, F, P].forEach((e) => e(s, o)),
					e.compilerExtensions.forEach((e) => e(s, o)),
					(s.__beforeBegin = null),
					[L, B, H].forEach((e) => e(s, o)),
					(s.isCompiled = !0);
				let c = null;
				return (
					'object' == typeof s.keywords &&
						s.keywords.$pattern &&
						((s.keywords = Object.assign({}, s.keywords)),
						(c = s.keywords.$pattern),
						delete s.keywords.$pattern),
					(c = c || /\w+/),
					s.keywords &&
						(s.keywords = $(s.keywords, e.case_insensitive)),
					(a.keywordPatternRe = t(c, !0)),
					o &&
						(s.begin || (s.begin = /\B|\b/),
						(a.beginRe = t(a.begin)),
						s.end || s.endsWithParent || (s.end = /\B|\b/),
						s.end && (a.endRe = t(a.end)),
						(a.terminatorEnd = g(a.end) || ''),
						s.endsWithParent &&
							o.terminatorEnd &&
							(a.terminatorEnd +=
								(s.end ? '|' : '') + o.terminatorEnd)),
					s.illegal && (a.illegalRe = t(s.illegal)),
					s.contains || (s.contains = []),
					(s.contains = [].concat(
						...s.contains.map((e) =>
							((e) => (
								e.variants &&
									!e.cachedVariants &&
									(e.cachedVariants = e.variants.map((t) =>
										r(
											e,
											{
												variants: null,
											},
											t,
										),
									)),
								e.cachedVariants
									? e.cachedVariants
									: q(e)
									? r(e, {
											starts: e.starts
												? r(e.starts)
												: null,
									  })
									: Object.isFrozen(e)
									? r(e)
									: e
							))('self' === e ? s : e),
						),
					)),
					s.contains.forEach((e) => {
						n(e, a);
					}),
					s.starts && n(s.starts, o),
					(a.matcher = ((e) => {
						const t = new i();
						return (
							e.contains.forEach((e) =>
								t.addRule(e.begin, { rule: e, type: 'begin' }),
							),
							e.terminatorEnd &&
								t.addRule(e.terminatorEnd, { type: 'end' }),
							e.illegal &&
								t.addRule(e.illegal, { type: 'illegal' }),
							t
						);
					})(a)),
					a
				);
			})(e)
		);
	}
	function q(e) {
		return !!e && (e.endsWithParent || q(e.starts));
	}
	class J extends Error {
		constructor(e, t) {
			super(e), (this.name = 'HTMLInjectionError'), (this.html = t);
		}
	}
	const Y = i,
		Q = r,
		ee = Symbol('nomatch');
	var te = ((t) => {
		const i = Object.create(null),
			r = Object.create(null),
			s = [];
		let o = !0;
		const a =
				"Could not find the language '{}', did you forget to load/include a language module?",
			c = {
				disableAutodetect: !0,
				name: 'Plain text',
				contains: [],
			};
		let g = {
			ignoreUnescapedHTML: !1,
			throwUnescapedHTML: !1,
			noHighlightRe: /^(no-?highlight)$/i,
			languageDetectRe: /\blang(?:uage)?-([\w-]+)\b/i,
			classPrefix: 'hljs-',
			cssSelector: 'pre code',
			languages: null,
			__emitter: l,
		};
		function b(e) {
			return g.noHighlightRe.test(e);
		}
		function m(e, t, n) {
			let i = '',
				r = '';
			'object' == typeof t
				? ((i = e), (n = t.ignoreIllegals), (r = t.language))
				: (X(
						'10.7.0',
						'highlight(lang, code, ...args) has been deprecated.',
				  ),
				  X(
						'10.7.0',
						'Please use highlight(code, options) instead.\nhttps://github.com/highlightjs/highlight.js/issues/2277',
				  ),
				  (r = e),
				  (i = t)),
				void 0 === n && (n = !0);
			const s = { code: i, language: r };
			k('before:highlight', s);
			const o = s.result ? s.result : E(s.language, s.code, n);
			return (o.code = s.code), k('after:highlight', o), o;
		}
		function E(e, t, r, s) {
			const c = Object.create(null);
			function l() {
				if (!N.keywords) return void M.addText(S);
				let e = 0;
				N.keywordPatternRe.lastIndex = 0;
				let t = N.keywordPatternRe.exec(S),
					n = '';
				for (; t; ) {
					n += S.substring(e, t.index);
					const r = y.case_insensitive ? t[0].toLowerCase() : t[0],
						s = ((i = r), N.keywords[i]);
					if (s) {
						const [e, i] = s;
						if (
							(M.addText(n),
							(n = ''),
							(c[r] = (c[r] || 0) + 1),
							c[r] <= 7 && (R += i),
							e.startsWith('_'))
						)
							n += t[0];
						else {
							const n = y.classNameAliases[e] || e;
							M.addKeyword(t[0], n);
						}
					} else n += t[0];
					(e = N.keywordPatternRe.lastIndex),
						(t = N.keywordPatternRe.exec(S));
				}
				var i;
				(n += S.substring(e)), M.addText(n);
			}
			function d() {
				null != N.subLanguage
					? (() => {
							if ('' === S) return;
							let e = null;
							if ('string' == typeof N.subLanguage) {
								if (!i[N.subLanguage]) return void M.addText(S);
								(e = E(N.subLanguage, S, !0, k[N.subLanguage])),
									(k[N.subLanguage] = e._top);
							} else
								e = x(
									S,
									N.subLanguage.length ? N.subLanguage : null,
								);
							N.relevance > 0 && (R += e.relevance),
								M.addSublanguage(e._emitter, e.language);
					  })()
					: l(),
					(S = '');
			}
			function u(e, t) {
				let n = 1;
				const i = t.length - 1;
				for (; n <= i; ) {
					if (!e._emit[n]) {
						n++;
						continue;
					}
					const i = y.classNameAliases[e[n]] || e[n],
						r = t[n];
					i ? M.addKeyword(r, i) : ((S = r), l(), (S = '')), n++;
				}
			}
			function h(e, t) {
				return (
					e.scope &&
						'string' == typeof e.scope &&
						M.openNode(y.classNameAliases[e.scope] || e.scope),
					e.beginScope &&
						(e.beginScope._wrap
							? (M.addKeyword(
									S,
									y.classNameAliases[e.beginScope._wrap] ||
										e.beginScope._wrap,
							  ),
							  (S = ''))
							: e.beginScope._multi &&
							  (u(e.beginScope, t), (S = ''))),
					(N = Object.create(e, {
						parent: {
							value: N,
						},
					})),
					N
				);
			}
			function p(e, t, i) {
				let r = ((e, t) => {
					const n = e && e.exec(t);
					return n && 0 === n.index;
				})(e.endRe, i);
				if (r) {
					if (e['on:end']) {
						const i = new n(e);
						e['on:end'](t, i), i.isMatchIgnored && (r = !1);
					}
					if (r) {
						for (; e.endsParent && e.parent; ) e = e.parent;
						return e;
					}
				}
				if (e.endsWithParent) return p(e.parent, t, i);
			}
			function f(e) {
				return 0 === N.matcher.regexIndex
					? ((S += e[0]), 1)
					: ((I = !0), 0);
			}
			function b(e) {
				const n = e[0],
					i = t.substring(e.index),
					r = p(N, e, i);
				if (!r) return ee;
				const s = N;
				N.endScope && N.endScope._wrap
					? (d(), M.addKeyword(n, N.endScope._wrap))
					: N.endScope && N.endScope._multi
					? (d(), u(N.endScope, e))
					: s.skip
					? (S += n)
					: (s.returnEnd || s.excludeEnd || (S += n),
					  d(),
					  s.excludeEnd && (S = n));
				do {
					N.scope && M.closeNode(),
						N.skip || N.subLanguage || (R += N.relevance),
						(N = N.parent);
				} while (N !== r.parent);
				return r.starts && h(r.starts, e), s.returnEnd ? 0 : n.length;
			}
			let m = {};
			function w(i, s) {
				const a = s && s[0];
				if (((S += i), null == a)) return d(), 0;
				if (
					'begin' === m.type &&
					'end' === s.type &&
					m.index === s.index &&
					'' === a
				) {
					if (((S += t.slice(s.index, s.index + 1)), !o)) {
						const t = Error(`0 width match regex (${e})`);
						throw ((t.languageName = e), (t.badRule = m.rule), t);
					}
					return 1;
				}
				if (((m = s), 'begin' === s.type))
					return ((e) => {
						const t = e[0],
							i = e.rule,
							r = new n(i),
							s = [i.__beforeBegin, i['on:begin']];
						for (const n of s)
							if (n && (n(e, r), r.isMatchIgnored)) return f(t);
						return (
							i.skip
								? (S += t)
								: (i.excludeBegin && (S += t),
								  d(),
								  i.returnBegin || i.excludeBegin || (S = t)),
							h(i, e),
							i.returnBegin ? 0 : t.length
						);
					})(s);
				if ('illegal' === s.type && !r) {
					const e = Error(
						'Illegal lexeme "' +
							a +
							'" for mode "' +
							(N.scope || '<unnamed>') +
							'"',
					);
					throw ((e.mode = N), e);
				}
				if ('end' === s.type) {
					const e = b(s);
					if (e !== ee) return e;
				}
				if ('illegal' === s.type && '' === a) return 1;
				if (A > 1e5 && A > 3 * s.index)
					throw Error(
						'potential infinite loop, way more iterations than matches',
					);
				return (S += a), a.length;
			}
			const y = O(e);
			if (!y)
				throw (
					(K(a.replace('{}', e)),
					Error('Unknown language: "' + e + '"'))
				);
			const _ = V(y);
			let v = '',
				N = s || _;
			const k = {},
				M = new g.__emitter(g);
			(() => {
				const e = [];
				for (let t = N; t !== y; t = t.parent)
					t.scope && e.unshift(t.scope);
				e.forEach((e) => M.openNode(e));
			})();
			let S = '',
				R = 0,
				j = 0,
				A = 0,
				I = !1;
			try {
				for (N.matcher.considerAll(); ; ) {
					A++,
						I ? (I = !1) : N.matcher.considerAll(),
						(N.matcher.lastIndex = j);
					const e = N.matcher.exec(t);
					if (!e) break;
					const n = w(t.substring(j, e.index), e);
					j = e.index + n;
				}
				return (
					w(t.substring(j)),
					M.closeAllNodes(),
					M.finalize(),
					(v = M.toHTML()),
					{
						language: e,
						value: v,
						relevance: R,
						illegal: !1,
						_emitter: M,
						_top: N,
					}
				);
			} catch (n) {
				if (n.message && n.message.includes('Illegal'))
					return {
						language: e,
						value: Y(t),
						illegal: !0,
						relevance: 0,
						_illegalBy: {
							message: n.message,
							index: j,
							context: t.slice(j - 100, j + 100),
							mode: n.mode,
							resultSoFar: v,
						},
						_emitter: M,
					};
				if (o)
					return {
						language: e,
						value: Y(t),
						illegal: !1,
						relevance: 0,
						errorRaised: n,
						_emitter: M,
						_top: N,
					};
				throw n;
			}
		}
		function x(e, t) {
			t = t || g.languages || Object.keys(i);
			const n = ((e) => {
					const t = {
						value: Y(e),
						illegal: !1,
						relevance: 0,
						_top: c,
						_emitter: new g.__emitter(g),
					};
					return t._emitter.addText(e), t;
				})(e),
				r = t
					.filter(O)
					.filter(N)
					.map((t) => E(t, e, !1));
			r.unshift(n);
			const s = r.sort((e, t) => {
					if (e.relevance !== t.relevance)
						return t.relevance - e.relevance;
					if (e.language && t.language) {
						if (O(e.language).supersetOf === t.language) return 1;
						if (O(t.language).supersetOf === e.language) return -1;
					}
					return 0;
				}),
				[o, a] = s,
				l = o;
			return (l.secondBest = a), l;
		}
		function w(e) {
			let t = null;
			const n = ((e) => {
				let t = e.className + ' ';
				t += e.parentNode ? e.parentNode.className : '';
				const n = g.languageDetectRe.exec(t);
				if (n) {
					const t = O(n[1]);
					return (
						t ||
							(W(a.replace('{}', n[1])),
							W(
								'Falling back to no-highlight mode for this block.',
								e,
							)),
						t ? n[1] : 'no-highlight'
					);
				}
				return t.split(/\s+/).find((e) => b(e) || O(e));
			})(e);
			if (b(n)) return;
			if (
				(k('before:highlightElement', { el: e, language: n }),
				e.children.length > 0 &&
					(g.ignoreUnescapedHTML ||
						(console.warn(
							'One of your code blocks includes unescaped HTML. This is a potentially serious security risk.',
						),
						console.warn(
							'https://github.com/highlightjs/highlight.js/wiki/security',
						),
						console.warn('The element with unescaped HTML:'),
						console.warn(e)),
					g.throwUnescapedHTML))
			)
				throw new J(
					'One of your code blocks includes unescaped HTML.',
					e.innerHTML,
				);
			t = e;
			const i = t.textContent,
				s = n ? m(i, { language: n, ignoreIllegals: !0 }) : x(i);
			(e.innerHTML = s.value),
				((e, t, n) => {
					const i = (t && r[t]) || n;
					e.classList.add('hljs'), e.classList.add('language-' + i);
				})(e, n, s.language),
				(e.result = {
					language: s.language,
					re: s.relevance,
					relevance: s.relevance,
				}),
				s.secondBest &&
					(e.secondBest = {
						language: s.secondBest.language,
						relevance: s.secondBest.relevance,
					}),
				k('after:highlightElement', { el: e, result: s, text: i });
		}
		let y = !1;
		function _() {
			'loading' !== document.readyState
				? document.querySelectorAll(g.cssSelector).forEach(w)
				: (y = !0);
		}
		function O(e) {
			return (e = (e || '').toLowerCase()), i[e] || i[r[e]];
		}
		function v(e, { languageName: t }) {
			'string' == typeof e && (e = [e]),
				e.forEach((e) => {
					r[e.toLowerCase()] = t;
				});
		}
		function N(e) {
			const t = O(e);
			return t && !t.disableAutodetect;
		}
		function k(e, t) {
			const n = e;
			s.forEach((e) => {
				e[n] && e[n](t);
			});
		}
		'undefined' != typeof window &&
			window.addEventListener &&
			window.addEventListener(
				'DOMContentLoaded',
				() => {
					y && _();
				},
				!1,
			),
			Object.assign(t, {
				highlight: m,
				highlightAuto: x,
				highlightAll: _,
				highlightElement: w,
				highlightBlock: (e) => (
					X(
						'10.7.0',
						'highlightBlock will be removed entirely in v12.0',
					),
					X('10.7.0', 'Please use highlightElement now.'),
					w(e)
				),
				configure: (e) => {
					g = Q(g, e);
				},
				initHighlighting: () => {
					_(),
						X(
							'10.6.0',
							'initHighlighting() deprecated.  Use highlightAll() now.',
						);
				},
				initHighlightingOnLoad: () => {
					_(),
						X(
							'10.6.0',
							'initHighlightingOnLoad() deprecated.  Use highlightAll() now.',
						);
				},
				registerLanguage: (e, n) => {
					let r = null;
					try {
						r = n(t);
					} catch (t) {
						if (
							(K(
								"Language definition for '{}' could not be registered.".replace(
									'{}',
									e,
								),
							),
							!o)
						)
							throw t;
						K(t), (r = c);
					}
					r.name || (r.name = e),
						(i[e] = r),
						(r.rawDefinition = n.bind(null, t)),
						r.aliases &&
							v(r.aliases, {
								languageName: e,
							});
				},
				unregisterLanguage: (e) => {
					delete i[e];
					for (const t of Object.keys(r)) r[t] === e && delete r[t];
				},
				listLanguages: () => Object.keys(i),
				getLanguage: O,
				registerAliases: v,
				autoDetection: N,
				inherit: Q,
				addPlugin: (e) => {
					((e) => {
						e['before:highlightBlock'] &&
							!e['before:highlightElement'] &&
							(e['before:highlightElement'] = (t) => {
								e['before:highlightBlock'](
									Object.assign({ block: t.el }, t),
								);
							}),
							e['after:highlightBlock'] &&
								!e['after:highlightElement'] &&
								(e['after:highlightElement'] = (t) => {
									e['after:highlightBlock'](
										Object.assign({ block: t.el }, t),
									);
								});
					})(e),
						s.push(e);
				},
			}),
			(t.debugMode = () => {
				o = !1;
			}),
			(t.safeMode = () => {
				o = !0;
			}),
			(t.versionString = '11.6.0'),
			(t.regex = {
				concat: p,
				lookahead: d,
				either: f,
				optional: h,
				anyNumberOfTimes: u,
			});
		for (const t in A) 'object' == typeof A[t] && e.exports(A[t]);
		return Object.assign(t, A), t;
	})({});
	return te;
})();
'object' == typeof exports &&
	'undefined' != typeof module &&
	(module.exports = hljs); /*! `c` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = e.regex,
				t = e.COMMENT('//', '$', {
					contains: [{ begin: /\\\n/ }],
				}),
				s = '[a-zA-Z_]\\w*::',
				a =
					'(decltype\\(auto\\)|' +
					n.optional(s) +
					'[a-zA-Z_]\\w*' +
					n.optional('<[^<>]+>') +
					')',
				r = {
					className: 'type',
					variants: [
						{ begin: '\\b[a-z\\d_]*_t\\b' },
						{
							match: /\batomic_[a-z]{3,6}\b/,
						},
					],
				},
				i = {
					className: 'string',
					variants: [
						{
							begin: '(u8?|U|L)?"',
							end: '"',
							illegal: '\\n',
							contains: [e.BACKSLASH_ESCAPE],
						},
						{
							begin: "(u8?|U|L)?'(\\\\(x[0-9A-Fa-f]{2}|u[0-9A-Fa-f]{4,8}|[0-7]{3}|\\S)|.)",
							end: "'",
							illegal: '.',
						},
						e.END_SAME_AS_BEGIN({
							begin: /(?:u8?|U|L)?R"([^()\\ ]{0,16})\(/,
							end: /\)([^()\\ ]{0,16})"/,
						}),
					],
				},
				l = {
					className: 'number',
					variants: [
						{ begin: "\\b(0b[01']+)" },
						{
							begin: "(-?)\\b([\\d']+(\\.[\\d']*)?|\\.[\\d']+)((ll|LL|l|L)(u|U)?|(u|U)(ll|LL|l|L)?|f|F|b|B)",
						},
						{
							begin: "(-?)(\\b0[xX][a-fA-F0-9']+|(\\b[\\d']+(\\.[\\d']*)?|\\.[\\d']+)([eE][-+]?[\\d']+)?)",
						},
					],
					relevance: 0,
				},
				o = {
					className: 'meta',
					begin: /#\s*[a-z]+\b/,
					end: /$/,
					keywords: {
						keyword:
							'if else elif endif define undef warning error line pragma _Pragma ifdef ifndef include',
					},
					contains: [
						{ begin: /\\\n/, relevance: 0 },
						e.inherit(i, { className: 'string' }),
						{
							className: 'string',
							begin: /<.*?>/,
						},
						t,
						e.C_BLOCK_COMMENT_MODE,
					],
				},
				c = {
					className: 'title',
					begin: n.optional(s) + e.IDENT_RE,
					relevance: 0,
				},
				d = n.optional(s) + e.IDENT_RE + '\\s*\\(',
				u = {
					keyword: [
						'asm',
						'auto',
						'break',
						'case',
						'continue',
						'default',
						'do',
						'else',
						'enum',
						'extern',
						'for',
						'fortran',
						'goto',
						'if',
						'inline',
						'register',
						'restrict',
						'return',
						'sizeof',
						'struct',
						'switch',
						'typedef',
						'union',
						'volatile',
						'while',
						'_Alignas',
						'_Alignof',
						'_Atomic',
						'_Generic',
						'_Noreturn',
						'_Static_assert',
						'_Thread_local',
						'alignas',
						'alignof',
						'noreturn',
						'static_assert',
						'thread_local',
						'_Pragma',
					],
					type: [
						'float',
						'double',
						'signed',
						'unsigned',
						'int',
						'short',
						'long',
						'char',
						'void',
						'_Bool',
						'_Complex',
						'_Imaginary',
						'_Decimal32',
						'_Decimal64',
						'_Decimal128',
						'const',
						'static',
						'complex',
						'bool',
						'imaginary',
					],
					literal: 'true false NULL',
					built_in:
						'std string wstring cin cout cerr clog stdin stdout stderr stringstream istringstream ostringstream auto_ptr deque list queue stack vector map set pair bitset multiset multimap unordered_set unordered_map unordered_multiset unordered_multimap priority_queue make_pair array shared_ptr abort terminate abs acos asin atan2 atan calloc ceil cosh cos exit exp fabs floor fmod fprintf fputs free frexp fscanf future isalnum isalpha iscntrl isdigit isgraph islower isprint ispunct isspace isupper isxdigit tolower toupper labs ldexp log10 log malloc realloc memchr memcmp memcpy memset modf pow printf putchar puts scanf sinh sin snprintf sprintf sqrt sscanf strcat strchr strcmp strcpy strcspn strlen strncat strncmp strncpy strpbrk strrchr strspn strstr tanh tan vfprintf vprintf vsprintf endl initializer_list unique_ptr',
				},
				g = [o, r, t, e.C_BLOCK_COMMENT_MODE, l, i],
				m = {
					variants: [
						{ begin: /=/, end: /;/ },
						{
							begin: /\(/,
							end: /\)/,
						},
						{ beginKeywords: 'new throw return else', end: /;/ },
					],
					keywords: u,
					contains: g.concat([
						{
							begin: /\(/,
							end: /\)/,
							keywords: u,
							contains: g.concat(['self']),
							relevance: 0,
						},
					]),
					relevance: 0,
				},
				p = {
					begin: '(' + a + '[\\*&\\s]+)+' + d,
					returnBegin: !0,
					end: /[{;=]/,
					excludeEnd: !0,
					keywords: u,
					illegal: /[^\w\s\*&:<>.]/,
					contains: [
						{
							begin: 'decltype\\(auto\\)',
							keywords: u,
							relevance: 0,
						},
						{
							begin: d,
							returnBegin: !0,
							contains: [
								e.inherit(c, {
									className: 'title.function',
								}),
							],
							relevance: 0,
						},
						{ relevance: 0, match: /,/ },
						{
							className: 'params',
							begin: /\(/,
							end: /\)/,
							keywords: u,
							relevance: 0,
							contains: [
								t,
								e.C_BLOCK_COMMENT_MODE,
								i,
								l,
								r,
								{
									begin: /\(/,
									end: /\)/,
									keywords: u,
									relevance: 0,
									contains: [
										'self',
										t,
										e.C_BLOCK_COMMENT_MODE,
										i,
										l,
										r,
									],
								},
							],
						},
						r,
						t,
						e.C_BLOCK_COMMENT_MODE,
						o,
					],
				};
			return {
				name: 'C',
				aliases: ['h'],
				keywords: u,
				disableAutodetect: !0,
				illegal: '</',
				contains: [].concat(m, p, g, [
					o,
					{
						begin: e.IDENT_RE + '::',
						keywords: u,
					},
					{
						className: 'class',
						beginKeywords: 'enum class struct union',
						end: /[{;:<>=]/,
						contains: [
							{
								beginKeywords: 'final class struct',
							},
							e.TITLE_MODE,
						],
					},
				]),
				exports: { preprocessor: o, strings: i, keywords: u },
			};
		};
	})();
	hljs.registerLanguage('c', e);
})(); /*! `ini` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = e.regex,
				a = {
					className: 'number',
					relevance: 0,
					variants: [
						{ begin: /([+-]+)?[\d]+_[\d_]+/ },
						{ begin: e.NUMBER_RE },
					],
				},
				s = e.COMMENT();
			s.variants = [
				{ begin: /;/, end: /$/ },
				{ begin: /#/, end: /$/ },
			];
			const i = {
					className: 'variable',
					variants: [
						{ begin: /\$[\w\d"][\w\d_]*/ },
						{ begin: /\$\{(.*?)\}/ },
					],
				},
				t = {
					className: 'literal',
					begin: /\bon|off|true|false|yes|no\b/,
				},
				r = {
					className: 'string',
					contains: [e.BACKSLASH_ESCAPE],
					variants: [
						{ begin: "'''", end: "'''", relevance: 10 },
						{ begin: '"""', end: '"""', relevance: 10 },
						{ begin: '"', end: '"' },
						{ begin: "'", end: "'" },
					],
				},
				l = {
					begin: /\[/,
					end: /\]/,
					contains: [s, t, i, r, a, 'self'],
					relevance: 0,
				},
				c = n.either(/[A-Za-z0-9_-]+/, /"(\\"|[^"])*"/, /'[^']*'/);
			return {
				name: 'TOML, also INI',
				aliases: ['toml'],
				case_insensitive: !0,
				illegal: /\S/,
				contains: [
					s,
					{ className: 'section', begin: /\[+/, end: /\]+/ },
					{
						begin: n.concat(
							c,
							'(\\s*\\.\\s*',
							c,
							')*',
							n.lookahead(/\s*=\s*[^#\s]/),
						),
						className: 'attr',
						starts: { end: /$/, contains: [s, l, t, i, r, a] },
					},
				],
			};
		};
	})();
	hljs.registerLanguage('ini', e);
})(); /*! `xml` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const a = e.regex,
				n = a.concat(
					/[\p{L}_]/u,
					a.optional(/[\p{L}0-9_.-]*:/u),
					/[\p{L}0-9_.-]*/u,
				),
				s = {
					className: 'symbol',
					begin: /&[a-z]+;|&#[0-9]+;|&#x[a-f0-9]+;/,
				},
				t = {
					begin: /\s/,
					contains: [
						{
							className: 'keyword',
							begin: /#?[a-z_][a-z1-9_-]+/,
							illegal: /\n/,
						},
					],
				},
				i = e.inherit(t, { begin: /\(/, end: /\)/ }),
				c = e.inherit(e.APOS_STRING_MODE, {
					className: 'string',
				}),
				l = e.inherit(e.QUOTE_STRING_MODE, { className: 'string' }),
				r = {
					endsWithParent: !0,
					illegal: /</,
					relevance: 0,
					contains: [
						{
							className: 'attr',
							begin: /[\p{L}0-9._:-]+/u,
							relevance: 0,
						},
						{
							begin: /=\s*/,
							relevance: 0,
							contains: [
								{
									className: 'string',
									endsParent: !0,
									variants: [
										{ begin: /"/, end: /"/, contains: [s] },
										{
											begin: /'/,
											end: /'/,
											contains: [s],
										},
										{ begin: /[^\s"'=<>`]+/ },
									],
								},
							],
						},
					],
				};
			return {
				name: 'HTML, XML',
				aliases: [
					'html',
					'xhtml',
					'rss',
					'atom',
					'xjb',
					'xsd',
					'xsl',
					'plist',
					'wsf',
					'svg',
				],
				case_insensitive: !0,
				unicodeRegex: !0,
				contains: [
					{
						className: 'meta',
						begin: /<![a-z]/,
						end: />/,
						relevance: 10,
						contains: [
							t,
							l,
							c,
							i,
							{
								begin: /\[/,
								end: /\]/,
								contains: [
									{
										className: 'meta',
										begin: /<![a-z]/,
										end: />/,
										contains: [t, i, l, c],
									},
								],
							},
						],
					},
					e.COMMENT(/<!--/, /-->/, { relevance: 10 }),
					{ begin: /<!\[CDATA\[/, end: /\]\]>/, relevance: 10 },
					s,
					{
						className: 'meta',
						end: /\?>/,
						variants: [
							{ begin: /<\?xml/, relevance: 10, contains: [l] },
							{ begin: /<\?[a-z][a-z0-9]+/ },
						],
					},
					{
						className: 'tag',
						begin: /<style(?=\s|>)/,
						end: />/,
						keywords: { name: 'style' },
						contains: [r],
						starts: {
							end: /<\/style>/,
							returnEnd: !0,
							subLanguage: ['css', 'xml'],
						},
					},
					{
						className: 'tag',
						begin: /<script(?=\s|>)/,
						end: />/,
						keywords: { name: 'script' },
						contains: [r],
						starts: {
							end: /<\/script>/,
							returnEnd: !0,
							subLanguage: ['javascript', 'handlebars', 'xml'],
						},
					},
					{
						className: 'tag',
						begin: /<>|<\/>/,
					},
					{
						className: 'tag',
						begin: a.concat(
							/</,
							a.lookahead(
								a.concat(n, a.either(/\/>/, />/, /\s/)),
							),
						),
						end: /\/?>/,
						contains: [
							{
								className: 'name',
								begin: n,
								relevance: 0,
								starts: r,
							},
						],
					},
					{
						className: 'tag',
						begin: a.concat(/<\//, a.lookahead(a.concat(n, />/))),
						contains: [
							{
								className: 'name',
								begin: n,
								relevance: 0,
							},
							{ begin: />/, relevance: 0, endsParent: !0 },
						],
					},
				],
			};
		};
	})();
	hljs.registerLanguage('xml', e);
})(); /*! `typescript` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		const e = '[A-Za-z$_][0-9A-Za-z$_]*',
			n = [
				'as',
				'in',
				'of',
				'if',
				'for',
				'while',
				'finally',
				'var',
				'new',
				'function',
				'do',
				'return',
				'void',
				'else',
				'break',
				'catch',
				'instanceof',
				'with',
				'throw',
				'case',
				'default',
				'try',
				'switch',
				'continue',
				'typeof',
				'delete',
				'let',
				'yield',
				'const',
				'class',
				'debugger',
				'async',
				'await',
				'static',
				'import',
				'from',
				'export',
				'extends',
			],
			a = ['true', 'false', 'null', 'undefined', 'NaN', 'Infinity'],
			t = [
				'Object',
				'Function',
				'Boolean',
				'Symbol',
				'Math',
				'Date',
				'Number',
				'BigInt',
				'String',
				'RegExp',
				'Array',
				'Float32Array',
				'Float64Array',
				'Int8Array',
				'Uint8Array',
				'Uint8ClampedArray',
				'Int16Array',
				'Int32Array',
				'Uint16Array',
				'Uint32Array',
				'BigInt64Array',
				'BigUint64Array',
				'Set',
				'Map',
				'WeakSet',
				'WeakMap',
				'ArrayBuffer',
				'SharedArrayBuffer',
				'Atomics',
				'DataView',
				'JSON',
				'Promise',
				'Generator',
				'GeneratorFunction',
				'AsyncFunction',
				'Reflect',
				'Proxy',
				'Intl',
				'WebAssembly',
			],
			s = [
				'Error',
				'EvalError',
				'InternalError',
				'RangeError',
				'ReferenceError',
				'SyntaxError',
				'TypeError',
				'URIError',
			],
			r = [
				'setInterval',
				'setTimeout',
				'clearInterval',
				'clearTimeout',
				'require',
				'exports',
				'eval',
				'isFinite',
				'isNaN',
				'parseFloat',
				'parseInt',
				'decodeURI',
				'decodeURIComponent',
				'encodeURI',
				'encodeURIComponent',
				'escape',
				'unescape',
			],
			c = [
				'arguments',
				'this',
				'super',
				'console',
				'window',
				'document',
				'localStorage',
				'module',
				'global',
			],
			i = [].concat(r, t, s);
		function o(o) {
			const l = o.regex,
				d = e,
				b = {
					begin: /<[A-Za-z0-9\\._:-]+/,
					end: /\/[A-Za-z0-9\\._:-]+>|\/>/,
					isTrulyOpeningTag: (e, n) => {
						const a = e[0].length + e.index,
							t = e.input[a];
						if ('<' === t || ',' === t) return void n.ignoreMatch();
						let s;
						'>' === t &&
							(((e, { after: n }) => {
								const a = '</' + e[0].slice(1);
								return -1 !== e.input.indexOf(a, n);
							})(e, { after: a }) ||
								n.ignoreMatch()),
							(s = e.input
								.substring(a)
								.match(/^\s+extends\s+/)) &&
								0 === s.index &&
								n.ignoreMatch();
					},
				},
				g = {
					$pattern: e,
					keyword: n,
					literal: a,
					built_in: i,
					'variable.language': c,
				},
				u = '\\.([0-9](_?[0-9])*)',
				m = '0|[1-9](_?[0-9])*|0[0-7]*[89][0-9]*',
				E = {
					className: 'number',
					variants: [
						{
							begin: `(\\b(${m})((${u})|\\.)?|(${u}))[eE][+-]?([0-9](_?[0-9])*)\\b`,
						},
						{
							begin: `\\b(${m})\\b((${u})\\b|\\.)?|(${u})\\b`,
						},
						{
							begin: '\\b(0|[1-9](_?[0-9])*)n\\b',
						},
						{
							begin: '\\b0[xX][0-9a-fA-F](_?[0-9a-fA-F])*n?\\b',
						},
						{
							begin: '\\b0[bB][0-1](_?[0-1])*n?\\b',
						},
						{ begin: '\\b0[oO][0-7](_?[0-7])*n?\\b' },
						{
							begin: '\\b0[0-7]+n?\\b',
						},
					],
					relevance: 0,
				},
				y = {
					className: 'subst',
					begin: '\\$\\{',
					end: '\\}',
					keywords: g,
					contains: [],
				},
				A = {
					begin: 'html`',
					end: '',
					starts: {
						end: '`',
						returnEnd: !1,
						contains: [o.BACKSLASH_ESCAPE, y],
						subLanguage: 'xml',
					},
				},
				_ = {
					begin: 'css`',
					end: '',
					starts: {
						end: '`',
						returnEnd: !1,
						contains: [o.BACKSLASH_ESCAPE, y],
						subLanguage: 'css',
					},
				},
				p = {
					className: 'string',
					begin: '`',
					end: '`',
					contains: [o.BACKSLASH_ESCAPE, y],
				},
				N = {
					className: 'comment',
					variants: [
						o.COMMENT(/\/\*\*(?!\/)/, '\\*/', {
							relevance: 0,
							contains: [
								{
									begin: '(?=@[A-Za-z]+)',
									relevance: 0,
									contains: [
										{
											className: 'doctag',
											begin: '@[A-Za-z]+',
										},
										{
											className: 'type',
											begin: '\\{',
											end: '\\}',
											excludeEnd: !0,
											excludeBegin: !0,
											relevance: 0,
										},
										{
											className: 'variable',
											begin: d + '(?=\\s*(-)|$)',
											endsParent: !0,
											relevance: 0,
										},
										{ begin: /(?=[^\n])\s/, relevance: 0 },
									],
								},
							],
						}),
						o.C_BLOCK_COMMENT_MODE,
						o.C_LINE_COMMENT_MODE,
					],
				},
				f = [o.APOS_STRING_MODE, o.QUOTE_STRING_MODE, A, _, p, E];
			y.contains = f.concat({
				begin: /\{/,
				end: /\}/,
				keywords: g,
				contains: ['self'].concat(f),
			});
			const h = [].concat(N, y.contains),
				v = h.concat([
					{
						begin: /\(/,
						end: /\)/,
						keywords: g,
						contains: ['self'].concat(h),
					},
				]),
				S = {
					className: 'params',
					begin: /\(/,
					end: /\)/,
					excludeBegin: !0,
					excludeEnd: !0,
					keywords: g,
					contains: v,
				},
				w = {
					variants: [
						{
							match: [
								/class/,
								/\s+/,
								d,
								/\s+/,
								/extends/,
								/\s+/,
								l.concat(d, '(', l.concat(/\./, d), ')*'),
							],
							scope: {
								1: 'keyword',
								3: 'title.class',
								5: 'keyword',
								7: 'title.class.inherited',
							},
						},
						{
							match: [/class/, /\s+/, d],
							scope: { 1: 'keyword', 3: 'title.class' },
						},
					],
				},
				R = {
					relevance: 0,
					match: l.either(
						/\bJSON/,
						/\b[A-Z][a-z]+([A-Z][a-z]*|\d)*/,
						/\b[A-Z]{2,}([A-Z][a-z]+|\d)+([A-Z][a-z]*)*/,
						/\b[A-Z]{2,}[a-z]+([A-Z][a-z]+|\d)*([A-Z][a-z]*)*/,
					),
					className: 'title.class',
					keywords: { _: [...t, ...s] },
				},
				x = {
					variants: [
						{
							match: [/function/, /\s+/, d, /(?=\s*\()/],
						},
						{ match: [/function/, /\s*(?=\()/] },
					],
					className: { 1: 'keyword', 3: 'title.function' },
					label: 'func.def',
					contains: [S],
					illegal: /%/,
				},
				k = {
					match: l.concat(
						/\b/,
						((O = [...r, 'super']),
						l.concat('(?!', O.join('|'), ')')),
						d,
						l.lookahead(/\(/),
					),
					className: 'title.function',
					relevance: 0,
				};
			var O;
			const I = {
					begin: l.concat(
						/\./,
						l.lookahead(l.concat(d, /(?![0-9A-Za-z$_(])/)),
					),
					end: d,
					excludeBegin: !0,
					keywords: 'prototype',
					className: 'property',
					relevance: 0,
				},
				C = {
					match: [/get|set/, /\s+/, d, /(?=\()/],
					className: { 1: 'keyword', 3: 'title.function' },
					contains: [{ begin: /\(\)/ }, S],
				},
				T =
					'(\\([^()]*(\\([^()]*(\\([^()]*\\)[^()]*)*\\)[^()]*)*\\)|' +
					o.UNDERSCORE_IDENT_RE +
					')\\s*=>',
				M = {
					match: [
						/const|var|let/,
						/\s+/,
						d,
						/\s*/,
						/=\s*/,
						/(async\s*)?/,
						l.lookahead(T),
					],
					keywords: 'async',
					className: { 1: 'keyword', 3: 'title.function' },
					contains: [S],
				};
			return {
				name: 'Javascript',
				aliases: ['js', 'jsx', 'mjs', 'cjs'],
				keywords: g,
				exports: {
					PARAMS_CONTAINS: v,
					CLASS_REFERENCE: R,
				},
				illegal: /#(?![$_A-z])/,
				contains: [
					o.SHEBANG({
						label: 'shebang',
						binary: 'node',
						relevance: 5,
					}),
					{
						label: 'use_strict',
						className: 'meta',
						relevance: 10,
						begin: /^\s*['"]use (strict|asm)['"]/,
					},
					o.APOS_STRING_MODE,
					o.QUOTE_STRING_MODE,
					A,
					_,
					p,
					N,
					E,
					R,
					{
						className: 'attr',
						begin: d + l.lookahead(':'),
						relevance: 0,
					},
					M,
					{
						begin:
							'(' +
							o.RE_STARTERS_RE +
							'|\\b(case|return|throw)\\b)\\s*',
						keywords: 'return throw case',
						relevance: 0,
						contains: [
							N,
							o.REGEXP_MODE,
							{
								className: 'function',
								begin: T,
								returnBegin: !0,
								end: '\\s*=>',
								contains: [
									{
										className: 'params',
										variants: [
											{
												begin: o.UNDERSCORE_IDENT_RE,
												relevance: 0,
											},
											{
												className: null,
												begin: /\(\s*\)/,
												skip: !0,
											},
											{
												begin: /\(/,
												end: /\)/,
												excludeBegin: !0,
												excludeEnd: !0,
												keywords: g,
												contains: v,
											},
										],
									},
								],
							},
							{ begin: /,/, relevance: 0 },
							{ match: /\s+/, relevance: 0 },
							{
								variants: [
									{ begin: '<>', end: '</>' },
									{
										match: /<[A-Za-z0-9\\._:-]+\s*\/>/,
									},
									{
										begin: b.begin,
										'on:begin': b.isTrulyOpeningTag,
										end: b.end,
									},
								],
								subLanguage: 'xml',
								contains: [
									{
										begin: b.begin,
										end: b.end,
										skip: !0,
										contains: ['self'],
									},
								],
							},
						],
					},
					x,
					{
						beginKeywords: 'while if switch catch for',
					},
					{
						begin:
							'\\b(?!function)' +
							o.UNDERSCORE_IDENT_RE +
							'\\([^()]*(\\([^()]*(\\([^()]*\\)[^()]*)*\\)[^()]*)*\\)\\s*\\{',
						returnBegin: !0,
						label: 'func.def',
						contains: [
							S,
							o.inherit(o.TITLE_MODE, {
								begin: d,
								className: 'title.function',
							}),
						],
					},
					{ match: /\.\.\./, relevance: 0 },
					I,
					{ match: '\\$' + d, relevance: 0 },
					{
						match: [/\bconstructor(?=\s*\()/],
						className: { 1: 'title.function' },
						contains: [S],
					},
					k,
					{
						relevance: 0,
						match: /\b[A-Z][A-Z_0-9]+\b/,
						className: 'variable.constant',
					},
					w,
					C,
					{ match: /\$[(.]/ },
				],
			};
		}
		return (t) => {
			const s = o(t),
				r = [
					'any',
					'void',
					'number',
					'boolean',
					'string',
					'object',
					'never',
					'symbol',
					'bigint',
					'unknown',
				],
				l = {
					beginKeywords: 'namespace',
					end: /\{/,
					excludeEnd: !0,
					contains: [s.exports.CLASS_REFERENCE],
				},
				d = {
					beginKeywords: 'interface',
					end: /\{/,
					excludeEnd: !0,
					keywords: { keyword: 'interface extends', built_in: r },
					contains: [s.exports.CLASS_REFERENCE],
				},
				b = {
					$pattern: e,
					keyword: n.concat([
						'type',
						'namespace',
						'interface',
						'public',
						'private',
						'protected',
						'implements',
						'declare',
						'abstract',
						'readonly',
						'enum',
						'override',
					]),
					literal: a,
					built_in: i.concat(r),
					'variable.language': c,
				},
				g = { className: 'meta', begin: '@[A-Za-z$_][0-9A-Za-z$_]*' },
				u = (e, n, a) => {
					const t = e.contains.findIndex((e) => e.label === n);
					if (-1 === t) throw Error('can not find mode to replace');
					e.contains.splice(t, 1, a);
				};
			return (
				Object.assign(s.keywords, b),
				s.exports.PARAMS_CONTAINS.push(g),
				(s.contains = s.contains.concat([g, l, d])),
				u(s, 'shebang', t.SHEBANG()),
				u(s, 'use_strict', {
					className: 'meta',
					relevance: 10,
					begin: /^\s*['"]use strict['"]/,
				}),
				(s.contains.find((e) => 'func.def' === e.label).relevance = 0),
				Object.assign(s, {
					name: 'TypeScript',
					aliases: ['ts', 'tsx'],
				}),
				s
			);
		};
	})();
	hljs.registerLanguage('typescript', e);
})(); /*! `bash` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const s = e.regex,
				t = {},
				n = {
					begin: /\$\{/,
					end: /\}/,
					contains: ['self', { begin: /:-/, contains: [t] }],
				};
			Object.assign(t, {
				className: 'variable',
				variants: [
					{
						begin: s.concat(
							/\$[\w\d#@][\w\d_]*/,
							'(?![\\w\\d])(?![$])',
						),
					},
					n,
				],
			});
			const a = {
					className: 'subst',
					begin: /\$\(/,
					end: /\)/,
					contains: [e.BACKSLASH_ESCAPE],
				},
				i = {
					begin: /<<-?\s*(?=\w+)/,
					starts: {
						contains: [
							e.END_SAME_AS_BEGIN({
								begin: /(\w+)/,
								end: /(\w+)/,
								className: 'string',
							}),
						],
					},
				},
				c = {
					className: 'string',
					begin: /"/,
					end: /"/,
					contains: [e.BACKSLASH_ESCAPE, t, a],
				};
			a.contains.push(c);
			const o = {
					begin: /\$\(\(/,
					end: /\)\)/,
					contains: [
						{ begin: /\d+#[0-9a-f]+/, className: 'number' },
						e.NUMBER_MODE,
						t,
					],
				},
				r = e.SHEBANG({
					binary: '(fish|bash|zsh|sh|csh|ksh|tcsh|dash|scsh)',
					relevance: 10,
				}),
				l = {
					className: 'function',
					begin: /\w[\w\d_]*\s*\(\s*\)\s*\{/,
					returnBegin: !0,
					contains: [
						e.inherit(e.TITLE_MODE, { begin: /\w[\w\d_]*/ }),
					],
					relevance: 0,
				};
			return {
				name: 'Bash',
				aliases: ['sh'],
				keywords: {
					$pattern: /\b[a-z][a-z0-9._-]+\b/,
					keyword: [
						'if',
						'then',
						'else',
						'elif',
						'fi',
						'for',
						'while',
						'in',
						'do',
						'done',
						'case',
						'esac',
						'function',
					],
					literal: ['true', 'false'],
					built_in: [
						'break',
						'cd',
						'continue',
						'eval',
						'exec',
						'exit',
						'export',
						'getopts',
						'hash',
						'pwd',
						'readonly',
						'return',
						'shift',
						'test',
						'times',
						'trap',
						'umask',
						'unset',
						'alias',
						'bind',
						'builtin',
						'caller',
						'command',
						'declare',
						'echo',
						'enable',
						'help',
						'let',
						'local',
						'logout',
						'mapfile',
						'printf',
						'read',
						'readarray',
						'source',
						'type',
						'typeset',
						'ulimit',
						'unalias',
						'set',
						'shopt',
						'autoload',
						'bg',
						'bindkey',
						'bye',
						'cap',
						'chdir',
						'clone',
						'comparguments',
						'compcall',
						'compctl',
						'compdescribe',
						'compfiles',
						'compgroups',
						'compquote',
						'comptags',
						'comptry',
						'compvalues',
						'dirs',
						'disable',
						'disown',
						'echotc',
						'echoti',
						'emulate',
						'fc',
						'fg',
						'float',
						'functions',
						'getcap',
						'getln',
						'history',
						'integer',
						'jobs',
						'kill',
						'limit',
						'log',
						'noglob',
						'popd',
						'print',
						'pushd',
						'pushln',
						'rehash',
						'sched',
						'setcap',
						'setopt',
						'stat',
						'suspend',
						'ttyctl',
						'unfunction',
						'unhash',
						'unlimit',
						'unsetopt',
						'vared',
						'wait',
						'whence',
						'where',
						'which',
						'zcompile',
						'zformat',
						'zftp',
						'zle',
						'zmodload',
						'zparseopts',
						'zprof',
						'zpty',
						'zregexparse',
						'zsocket',
						'zstyle',
						'ztcp',
						'chcon',
						'chgrp',
						'chown',
						'chmod',
						'cp',
						'dd',
						'df',
						'dir',
						'dircolors',
						'ln',
						'ls',
						'mkdir',
						'mkfifo',
						'mknod',
						'mktemp',
						'mv',
						'realpath',
						'rm',
						'rmdir',
						'shred',
						'sync',
						'touch',
						'truncate',
						'vdir',
						'b2sum',
						'base32',
						'base64',
						'cat',
						'cksum',
						'comm',
						'csplit',
						'cut',
						'expand',
						'fmt',
						'fold',
						'head',
						'join',
						'md5sum',
						'nl',
						'numfmt',
						'od',
						'paste',
						'ptx',
						'pr',
						'sha1sum',
						'sha224sum',
						'sha256sum',
						'sha384sum',
						'sha512sum',
						'shuf',
						'sort',
						'split',
						'sum',
						'tac',
						'tail',
						'tr',
						'tsort',
						'unexpand',
						'uniq',
						'wc',
						'arch',
						'basename',
						'chroot',
						'date',
						'dirname',
						'du',
						'echo',
						'env',
						'expr',
						'factor',
						'groups',
						'hostid',
						'id',
						'link',
						'logname',
						'nice',
						'nohup',
						'nproc',
						'pathchk',
						'pinky',
						'printenv',
						'printf',
						'pwd',
						'readlink',
						'runcon',
						'seq',
						'sleep',
						'stat',
						'stdbuf',
						'stty',
						'tee',
						'test',
						'timeout',
						'tty',
						'uname',
						'unlink',
						'uptime',
						'users',
						'who',
						'whoami',
						'yes',
					],
				},
				contains: [
					r,
					e.SHEBANG(),
					l,
					o,
					e.HASH_COMMENT_MODE,
					i,
					{ match: /(\/[a-z._-]+)+/ },
					c,
					{
						className: '',
						begin: /\\"/,
					},
					{ className: 'string', begin: /'/, end: /'/ },
					t,
				],
			};
		};
	})();
	hljs.registerLanguage('bash', e);
})(); /*! `diff` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const a = e.regex;
			return {
				name: 'Diff',
				aliases: ['patch'],
				contains: [
					{
						className: 'meta',
						relevance: 10,
						match: a.either(
							/^@@ +-\d+,\d+ +\+\d+,\d+ +@@/,
							/^\*\*\* +\d+,\d+ +\*\*\*\*$/,
							/^--- +\d+,\d+ +----$/,
						),
					},
					{
						className: 'comment',
						variants: [
							{
								begin: a.either(
									/Index: /,
									/^index/,
									/={3,}/,
									/^-{3}/,
									/^\*{3} /,
									/^\+{3}/,
									/^diff --git/,
								),
								end: /$/,
							},
							{ match: /^\*{15}$/ },
						],
					},
					{ className: 'addition', begin: /^\+/, end: /$/ },
					{
						className: 'deletion',
						begin: /^-/,
						end: /$/,
					},
					{ className: 'addition', begin: /^!/, end: /$/ },
				],
			};
		};
	})();
	hljs.registerLanguage('diff', e);
})(); /*! `python` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = e.regex,
				a = /[\p{XID_Start}_]\p{XID_Continue}*/u,
				i = [
					'and',
					'as',
					'assert',
					'async',
					'await',
					'break',
					'case',
					'class',
					'continue',
					'def',
					'del',
					'elif',
					'else',
					'except',
					'finally',
					'for',
					'from',
					'global',
					'if',
					'import',
					'in',
					'is',
					'lambda',
					'match',
					'nonlocal|10',
					'not',
					'or',
					'pass',
					'raise',
					'return',
					'try',
					'while',
					'with',
					'yield',
				],
				s = {
					$pattern: /[A-Za-z]\w+|__\w+__/,
					keyword: i,
					built_in: [
						'__import__',
						'abs',
						'all',
						'any',
						'ascii',
						'bin',
						'bool',
						'breakpoint',
						'bytearray',
						'bytes',
						'callable',
						'chr',
						'classmethod',
						'compile',
						'complex',
						'delattr',
						'dict',
						'dir',
						'divmod',
						'enumerate',
						'eval',
						'exec',
						'filter',
						'float',
						'format',
						'frozenset',
						'getattr',
						'globals',
						'hasattr',
						'hash',
						'help',
						'hex',
						'id',
						'input',
						'int',
						'isinstance',
						'issubclass',
						'iter',
						'len',
						'list',
						'locals',
						'map',
						'max',
						'memoryview',
						'min',
						'next',
						'object',
						'oct',
						'open',
						'ord',
						'pow',
						'print',
						'property',
						'range',
						'repr',
						'reversed',
						'round',
						'set',
						'setattr',
						'slice',
						'sorted',
						'staticmethod',
						'str',
						'sum',
						'super',
						'tuple',
						'type',
						'vars',
						'zip',
					],
					literal: [
						'__debug__',
						'Ellipsis',
						'False',
						'None',
						'NotImplemented',
						'True',
					],
					type: [
						'Any',
						'Callable',
						'Coroutine',
						'Dict',
						'List',
						'Literal',
						'Generic',
						'Optional',
						'Sequence',
						'Set',
						'Tuple',
						'Type',
						'Union',
					],
				},
				t = { className: 'meta', begin: /^(>>>|\.\.\.) / },
				r = {
					className: 'subst',
					begin: /\{/,
					end: /\}/,
					keywords: s,
					illegal: /#/,
				},
				l = { begin: /\{\{/, relevance: 0 },
				b = {
					className: 'string',
					contains: [e.BACKSLASH_ESCAPE],
					variants: [
						{
							begin: /([uU]|[bB]|[rR]|[bB][rR]|[rR][bB])?'''/,
							end: /'''/,
							contains: [e.BACKSLASH_ESCAPE, t],
							relevance: 10,
						},
						{
							begin: /([uU]|[bB]|[rR]|[bB][rR]|[rR][bB])?"""/,
							end: /"""/,
							contains: [e.BACKSLASH_ESCAPE, t],
							relevance: 10,
						},
						{
							begin: /([fF][rR]|[rR][fF]|[fF])'''/,
							end: /'''/,
							contains: [e.BACKSLASH_ESCAPE, t, l, r],
						},
						{
							begin: /([fF][rR]|[rR][fF]|[fF])"""/,
							end: /"""/,
							contains: [e.BACKSLASH_ESCAPE, t, l, r],
						},
						{ begin: /([uU]|[rR])'/, end: /'/, relevance: 10 },
						{ begin: /([uU]|[rR])"/, end: /"/, relevance: 10 },
						{
							begin: /([bB]|[bB][rR]|[rR][bB])'/,
							end: /'/,
						},
						{ begin: /([bB]|[bB][rR]|[rR][bB])"/, end: /"/ },
						{
							begin: /([fF][rR]|[rR][fF]|[fF])'/,
							end: /'/,
							contains: [e.BACKSLASH_ESCAPE, l, r],
						},
						{
							begin: /([fF][rR]|[rR][fF]|[fF])"/,
							end: /"/,
							contains: [e.BACKSLASH_ESCAPE, l, r],
						},
						e.APOS_STRING_MODE,
						e.QUOTE_STRING_MODE,
					],
				},
				o = '[0-9](_?[0-9])*',
				c = `(\\b(${o}))?\\.(${o})|\\b(${o})\\.`,
				d = '\\b|' + i.join('|'),
				g = {
					className: 'number',
					relevance: 0,
					variants: [
						{
							begin: `(\\b(${o})|(${c}))[eE][+-]?(${o})[jJ]?(?=${d})`,
						},
						{ begin: `(${c})[jJ]?` },
						{
							begin: `\\b([1-9](_?[0-9])*|0+(_?0)*)[lLjJ]?(?=${d})`,
						},
						{
							begin: `\\b0[bB](_?[01])+[lL]?(?=${d})`,
						},
						{ begin: `\\b0[oO](_?[0-7])+[lL]?(?=${d})` },
						{ begin: `\\b0[xX](_?[0-9a-fA-F])+[lL]?(?=${d})` },
						{ begin: `\\b(${o})[jJ](?=${d})` },
					],
				},
				p = {
					className: 'comment',
					begin: n.lookahead(/# type:/),
					end: /$/,
					keywords: s,
					contains: [
						{ begin: /# type:/ },
						{ begin: /#/, end: /\b\B/, endsWithParent: !0 },
					],
				},
				m = {
					className: 'params',
					variants: [
						{ className: '', begin: /\(\s*\)/, skip: !0 },
						{
							begin: /\(/,
							end: /\)/,
							excludeBegin: !0,
							excludeEnd: !0,
							keywords: s,
							contains: ['self', t, g, b, e.HASH_COMMENT_MODE],
						},
					],
				};
			return (
				(r.contains = [b, g, t]),
				{
					name: 'Python',
					aliases: ['py', 'gyp', 'ipython'],
					unicodeRegex: !0,
					keywords: s,
					illegal: /(<\/|->|\?)|=>/,
					contains: [
						t,
						g,
						{ begin: /\bself\b/ },
						{ beginKeywords: 'if', relevance: 0 },
						b,
						p,
						e.HASH_COMMENT_MODE,
						{
							match: [/\bdef/, /\s+/, a],
							scope: {
								1: 'keyword',
								3: 'title.function',
							},
							contains: [m],
						},
						{
							variants: [
								{
									match: [
										/\bclass/,
										/\s+/,
										a,
										/\s*/,
										/\(\s*/,
										a,
										/\s*\)/,
									],
								},
								{ match: [/\bclass/, /\s+/, a] },
							],
							scope: {
								1: 'keyword',
								3: 'title.class',
								6: 'title.class.inherited',
							},
						},
						{
							className: 'meta',
							begin: /^[\t ]*@/,
							end: /(?=#)|$/,
							contains: [g, m, b],
						},
					],
				}
			);
		};
	})();
	hljs.registerLanguage('python', e);
})(); /*! `python-repl` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var a = (() => {
		'use strict';
		return (a) => ({
			aliases: ['pycon'],
			contains: [
				{
					className: 'meta.prompt',
					starts: {
						end: / |$/,
						starts: { end: '$', subLanguage: 'python' },
					},
					variants: [
						{ begin: /^>>>(?=[ ]|$)/ },
						{ begin: /^\.\.\.(?=[ ]|$)/ },
					],
				},
			],
		});
	})();
	hljs.registerLanguage('python-repl', a);
})(); /*! `ruby` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = e.regex,
				a =
					'([a-zA-Z_]\\w*[!?=]?|[-+~]@|<<|>>|=~|===?|<=>|[<>]=?|\\*\\*|[-/+%^&*~`|]|\\[\\]=?)',
				s = n.either(
					/\b([A-Z]+[a-z0-9]+)+/,
					/\b([A-Z]+[a-z0-9]+)+[A-Z]+/,
				),
				i = n.concat(s, /(::\w+)*/),
				r = {
					'variable.constant': ['__FILE__', '__LINE__'],
					'variable.language': ['self', 'super'],
					keyword: [
						'alias',
						'and',
						'attr_accessor',
						'attr_reader',
						'attr_writer',
						'begin',
						'BEGIN',
						'break',
						'case',
						'class',
						'defined',
						'do',
						'else',
						'elsif',
						'end',
						'END',
						'ensure',
						'for',
						'if',
						'in',
						'include',
						'module',
						'next',
						'not',
						'or',
						'redo',
						'require',
						'rescue',
						'retry',
						'return',
						'then',
						'undef',
						'unless',
						'until',
						'when',
						'while',
						'yield',
					],
					built_in: ['proc', 'lambda'],
					literal: ['true', 'false', 'nil'],
				},
				c = {
					className: 'doctag',
					begin: '@[A-Za-z]+',
				},
				t = { begin: '#<', end: '>' },
				b = [
					e.COMMENT('#', '$', { contains: [c] }),
					e.COMMENT('^=begin', '^=end', {
						contains: [c],
						relevance: 10,
					}),
					e.COMMENT('^__END__', e.MATCH_NOTHING_RE),
				],
				l = {
					className: 'subst',
					begin: /#\{/,
					end: /\}/,
					keywords: r,
				},
				d = {
					className: 'string',
					contains: [e.BACKSLASH_ESCAPE, l],
					variants: [
						{ begin: /'/, end: /'/ },
						{ begin: /"/, end: /"/ },
						{ begin: /`/, end: /`/ },
						{ begin: /%[qQwWx]?\(/, end: /\)/ },
						{ begin: /%[qQwWx]?\[/, end: /\]/ },
						{ begin: /%[qQwWx]?\{/, end: /\}/ },
						{ begin: /%[qQwWx]?</, end: />/ },
						{
							begin: /%[qQwWx]?\//,
							end: /\//,
						},
						{ begin: /%[qQwWx]?%/, end: /%/ },
						{ begin: /%[qQwWx]?-/, end: /-/ },
						{ begin: /%[qQwWx]?\|/, end: /\|/ },
						{ begin: /\B\?(\\\d{1,3})/ },
						{
							begin: /\B\?(\\x[A-Fa-f0-9]{1,2})/,
						},
						{ begin: /\B\?(\\u\{?[A-Fa-f0-9]{1,6}\}?)/ },
						{
							begin: /\B\?(\\M-\\C-|\\M-\\c|\\c\\M-|\\M-|\\C-\\M-)[\x20-\x7e]/,
						},
						{
							begin: /\B\?\\(c|C-)[\x20-\x7e]/,
						},
						{ begin: /\B\?\\?\S/ },
						{
							begin: n.concat(
								/<<[-~]?'?/,
								n.lookahead(
									/(\w+)(?=\W)[^\n]*\n(?:[^\n]*\n)*?\s*\1\b/,
								),
							),
							contains: [
								e.END_SAME_AS_BEGIN({
									begin: /(\w+)/,
									end: /(\w+)/,
									contains: [e.BACKSLASH_ESCAPE, l],
								}),
							],
						},
					],
				},
				g = '[0-9](_?[0-9])*',
				o = {
					className: 'number',
					relevance: 0,
					variants: [
						{
							begin: `\\b([1-9](_?[0-9])*|0)(\\.(${g}))?([eE][+-]?(${g})|r)?i?\\b`,
						},
						{
							begin: '\\b0[dD][0-9](_?[0-9])*r?i?\\b',
						},
						{ begin: '\\b0[bB][0-1](_?[0-1])*r?i?\\b' },
						{ begin: '\\b0[oO][0-7](_?[0-7])*r?i?\\b' },
						{
							begin: '\\b0[xX][0-9a-fA-F](_?[0-9a-fA-F])*r?i?\\b',
						},
						{
							begin: '\\b0(_?[0-7])+r?i?\\b',
						},
					],
				},
				_ = {
					variants: [
						{ match: /\(\)/ },
						{
							className: 'params',
							begin: /\(/,
							end: /(?=\))/,
							excludeBegin: !0,
							endsParent: !0,
							keywords: r,
						},
					],
				},
				u = [
					d,
					{
						variants: [
							{ match: [/class\s+/, i, /\s+<\s+/, i] },
							{
								match: [/class\s+/, i],
							},
						],
						scope: { 2: 'title.class', 4: 'title.class.inherited' },
						keywords: r,
					},
					{
						relevance: 0,
						match: [i, /\.new[ (]/],
						scope: { 1: 'title.class' },
					},
					{
						relevance: 0,
						match: /\b[A-Z][A-Z_0-9]+\b/,
						className: 'variable.constant',
					},
					{
						match: [/def/, /\s+/, a],
						scope: { 1: 'keyword', 3: 'title.function' },
						contains: [_],
					},
					{
						begin: e.IDENT_RE + '::',
					},
					{
						className: 'symbol',
						begin: e.UNDERSCORE_IDENT_RE + '(!|\\?)?:',
						relevance: 0,
					},
					{
						className: 'symbol',
						begin: ':(?!\\s)',
						contains: [d, { begin: a }],
						relevance: 0,
					},
					o,
					{
						className: 'variable',
						begin: "(\\$\\W)|((\\$|@@?)(\\w+))(?=[^@$?])(?![A-Za-z])(?![@$?'])",
					},
					{
						className: 'params',
						begin: /\|/,
						end: /\|/,
						excludeBegin: !0,
						excludeEnd: !0,
						relevance: 0,
						keywords: r,
					},
					{
						begin: '(' + e.RE_STARTERS_RE + '|unless)\\s*',
						keywords: 'unless',
						contains: [
							{
								className: 'regexp',
								contains: [e.BACKSLASH_ESCAPE, l],
								illegal: /\n/,
								variants: [
									{ begin: '/', end: '/[a-z]*' },
									{ begin: /%r\{/, end: /\}[a-z]*/ },
									{
										begin: '%r\\(',
										end: '\\)[a-z]*',
									},
									{ begin: '%r!', end: '![a-z]*' },
									{ begin: '%r\\[', end: '\\][a-z]*' },
								],
							},
						].concat(t, b),
						relevance: 0,
					},
				].concat(t, b);
			(l.contains = u), (_.contains = u);
			const w = [
				{ begin: /^\s*=>/, starts: { end: '$', contains: u } },
				{
					className: 'meta.prompt',
					begin: '^([>?]>|[\\w#]+\\(\\w+\\):\\d+:\\d+[>*]|(\\w+-)?\\d+\\.\\d+\\.\\d+(p\\d+)?[^\\d][^>]+>)(?=[ ])',
					starts: { end: '$', keywords: r, contains: u },
				},
			];
			return (
				b.unshift(t),
				{
					name: 'Ruby',
					aliases: ['rb', 'gemspec', 'podspec', 'thor', 'irb'],
					keywords: r,
					illegal: /\/\*/,
					contains: [e.SHEBANG({ binary: 'ruby' })]
						.concat(w)
						.concat(b)
						.concat(u),
				}
			);
		};
	})();
	hljs.registerLanguage('ruby', e);
})(); /*! `yaml` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = 'true false yes no null',
				a = "[\\w#;/?:@&=+$,.~*'()[\\]]+",
				s = {
					className: 'string',
					relevance: 0,
					variants: [
						{ begin: /'/, end: /'/ },
						{ begin: /"/, end: /"/ },
						{ begin: /\S+/ },
					],
					contains: [
						e.BACKSLASH_ESCAPE,
						{
							className: 'template-variable',
							variants: [
								{ begin: /\{\{/, end: /\}\}/ },
								{ begin: /%\{/, end: /\}/ },
							],
						},
					],
				},
				i = e.inherit(s, {
					variants: [
						{ begin: /'/, end: /'/ },
						{ begin: /"/, end: /"/ },
						{ begin: /[^\s,{}[\]]+/ },
					],
				}),
				l = {
					end: ',',
					endsWithParent: !0,
					excludeEnd: !0,
					keywords: n,
					relevance: 0,
				},
				t = {
					begin: /\{/,
					end: /\}/,
					contains: [l],
					illegal: '\\n',
					relevance: 0,
				},
				g = {
					begin: '\\[',
					end: '\\]',
					contains: [l],
					illegal: '\\n',
					relevance: 0,
				},
				b = [
					{
						className: 'attr',
						variants: [
							{
								begin: '\\w[\\w :\\/.-]*:(?=[ \t]|$)',
							},
							{ begin: '"\\w[\\w :\\/.-]*":(?=[ \t]|$)' },
							{
								begin: "'\\w[\\w :\\/.-]*':(?=[ \t]|$)",
							},
						],
					},
					{ className: 'meta', begin: '^---\\s*$', relevance: 10 },
					{
						className: 'string',
						begin: '[\\|>]([1-9]?[+-])?[ ]*\\n( +)[^ ][^\\n]*\\n(\\2[^\\n]+\\n?)*',
					},
					{
						begin: '<%[%=-]?',
						end: '[%-]?%>',
						subLanguage: 'ruby',
						excludeBegin: !0,
						excludeEnd: !0,
						relevance: 0,
					},
					{ className: 'type', begin: '!\\w+!' + a },
					{ className: 'type', begin: '!<' + a + '>' },
					{ className: 'type', begin: '!' + a },
					{ className: 'type', begin: '!!' + a },
					{
						className: 'meta',
						begin: '&' + e.UNDERSCORE_IDENT_RE + '$',
					},
					{
						className: 'meta',
						begin: '\\*' + e.UNDERSCORE_IDENT_RE + '$',
					},
					{ className: 'bullet', begin: '-(?=[ ]|$)', relevance: 0 },
					e.HASH_COMMENT_MODE,
					{ beginKeywords: n, keywords: { literal: n } },
					{
						className: 'number',
						begin: '\\b[0-9]{4}(-[0-9][0-9]){0,2}([Tt \\t][0-9][0-9]?(:[0-9][0-9]){2})?(\\.[0-9]*)?([ \\t])*(Z|[-+][0-9][0-9]?(:[0-9][0-9])?)?\\b',
					},
					{
						className: 'number',
						begin: e.C_NUMBER_RE + '\\b',
						relevance: 0,
					},
					t,
					g,
					s,
				],
				r = [...b];
			return (
				r.pop(),
				r.push(i),
				(l.contains = r),
				{
					name: 'YAML',
					case_insensitive: !0,
					aliases: ['yml'],
					contains: b,
				}
			);
		};
	})();
	hljs.registerLanguage('yaml', e);
})(); /*! `perl` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = e.regex,
				t = /[dualxmsipngr]{0,12}/,
				r = {
					$pattern: /[\w.]+/,
					keyword:
						'abs accept alarm and atan2 bind binmode bless break caller chdir chmod chomp chop chown chr chroot close closedir connect continue cos crypt dbmclose dbmopen defined delete die do dump each else elsif endgrent endhostent endnetent endprotoent endpwent endservent eof eval exec exists exit exp fcntl fileno flock for foreach fork format formline getc getgrent getgrgid getgrnam gethostbyaddr gethostbyname gethostent getlogin getnetbyaddr getnetbyname getnetent getpeername getpgrp getpriority getprotobyname getprotobynumber getprotoent getpwent getpwnam getpwuid getservbyname getservbyport getservent getsockname getsockopt given glob gmtime goto grep gt hex if index int ioctl join keys kill last lc lcfirst length link listen local localtime log lstat lt ma map mkdir msgctl msgget msgrcv msgsnd my ne next no not oct open opendir or ord our pack package pipe pop pos print printf prototype push q|0 qq quotemeta qw qx rand read readdir readline readlink readpipe recv redo ref rename require reset return reverse rewinddir rindex rmdir say scalar seek seekdir select semctl semget semop send setgrent sethostent setnetent setpgrp setpriority setprotoent setpwent setservent setsockopt shift shmctl shmget shmread shmwrite shutdown sin sleep socket socketpair sort splice split sprintf sqrt srand stat state study sub substr symlink syscall sysopen sysread sysseek system syswrite tell telldir tie tied time times tr truncate uc ucfirst umask undef unless unlink unpack unshift untie until use utime values vec wait waitpid wantarray warn when while write x|0 xor y|0',
				},
				s = {
					className: 'subst',
					begin: '[$@]\\{',
					end: '\\}',
					keywords: r,
				},
				i = { begin: /->\{/, end: /\}/ },
				a = {
					variants: [
						{ begin: /\$\d/ },
						{
							begin: n.concat(
								/[$%@](\^\w\b|#\w+(::\w+)*|\{\w+\}|\w+(::\w*)*)/,
								'(?![A-Za-z])(?![@$%])',
							),
						},
						{ begin: /[$%@][^\s\w{]/, relevance: 0 },
					],
				},
				c = [e.BACKSLASH_ESCAPE, s, a],
				o = [/!/, /\//, /\|/, /\?/, /'/, /"/, /#/],
				g = (e, r, s = '\\1') => {
					const i = '\\1' === s ? s : n.concat(s, r);
					return n.concat(
						n.concat('(?:', e, ')'),
						r,
						/(?:\\.|[^\\\/])*?/,
						i,
						/(?:\\.|[^\\\/])*?/,
						s,
						t,
					);
				},
				l = (e, r, s) =>
					n.concat(
						n.concat('(?:', e, ')'),
						r,
						/(?:\\.|[^\\\/])*?/,
						s,
						t,
					),
				d = [
					a,
					e.HASH_COMMENT_MODE,
					e.COMMENT(/^=\w/, /=cut/, {
						endsWithParent: !0,
					}),
					i,
					{
						className: 'string',
						contains: c,
						variants: [
							{
								begin: 'q[qwxr]?\\s*\\(',
								end: '\\)',
								relevance: 5,
							},
							{
								begin: 'q[qwxr]?\\s*\\[',
								end: '\\]',
								relevance: 5,
							},
							{
								begin: 'q[qwxr]?\\s*\\{',
								end: '\\}',
								relevance: 5,
							},
							{
								begin: 'q[qwxr]?\\s*\\|',
								end: '\\|',
								relevance: 5,
							},
							{ begin: 'q[qwxr]?\\s*<', end: '>', relevance: 5 },
							{ begin: 'qw\\s+q', end: 'q', relevance: 5 },
							{
								begin: "'",
								end: "'",
								contains: [e.BACKSLASH_ESCAPE],
							},
							{ begin: '"', end: '"' },
							{
								begin: '`',
								end: '`',
								contains: [e.BACKSLASH_ESCAPE],
							},
							{ begin: /\{\w+\}/, relevance: 0 },
							{
								begin: '-?\\w+\\s*=>',
								relevance: 0,
							},
						],
					},
					{
						className: 'number',
						begin: '(\\b0[0-7_]+)|(\\b0x[0-9a-fA-F_]+)|(\\b[1-9][0-9_]*(\\.[0-9_]+)?)|[0_]\\b',
						relevance: 0,
					},
					{
						begin:
							'(\\/\\/|' +
							e.RE_STARTERS_RE +
							'|\\b(split|return|print|reverse|grep)\\b)\\s*',
						keywords: 'split return print reverse grep',
						relevance: 0,
						contains: [
							e.HASH_COMMENT_MODE,
							{
								className: 'regexp',
								variants: [
									{
										begin: g(
											's|tr|y',
											n.either(...o, { capture: !0 }),
										),
									},
									{ begin: g('s|tr|y', '\\(', '\\)') },
									{
										begin: g('s|tr|y', '\\[', '\\]'),
									},
									{ begin: g('s|tr|y', '\\{', '\\}') },
								],
								relevance: 2,
							},
							{
								className: 'regexp',
								variants: [
									{ begin: /(m|qr)\/\//, relevance: 0 },
									{
										begin: l('(?:m|qr)?', /\//, /\//),
									},
									{
										begin: l(
											'm|qr',
											n.either(...o, { capture: !0 }),
											/\1/,
										),
									},
									{ begin: l('m|qr', /\(/, /\)/) },
									{ begin: l('m|qr', /\[/, /\]/) },
									{
										begin: l('m|qr', /\{/, /\}/),
									},
								],
							},
						],
					},
					{
						className: 'function',
						beginKeywords: 'sub',
						end: '(\\s*\\(.*?\\))?[;{]',
						excludeEnd: !0,
						relevance: 5,
						contains: [e.TITLE_MODE],
					},
					{
						begin: '-\\w\\b',
						relevance: 0,
					},
					{
						begin: '^__DATA__$',
						end: '^__END__$',
						subLanguage: 'mojolicious',
						contains: [
							{ begin: '^@@.*', end: '$', className: 'comment' },
						],
					},
				];
			return (
				(s.contains = d),
				(i.contains = d),
				{
					name: 'Perl',
					aliases: ['pl', 'pm'],
					keywords: r,
					contains: d,
				}
			);
		};
	})();
	hljs.registerLanguage('perl', e);
})(); /*! `lua` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const t = '\\[=*\\[',
				a = '\\]=*\\]',
				n = {
					begin: t,
					end: a,
					contains: ['self'],
				},
				o = [
					e.COMMENT('--(?!\\[=*\\[)', '$'),
					e.COMMENT('--\\[=*\\[', a, {
						contains: [n],
						relevance: 10,
					}),
				];
			return {
				name: 'Lua',
				keywords: {
					$pattern: e.UNDERSCORE_IDENT_RE,
					literal: 'true false nil',
					keyword:
						'and break do else elseif end for goto if in local not or repeat return then until while',
					built_in:
						'_G _ENV _VERSION __index __newindex __mode __call __metatable __tostring __len __gc __add __sub __mul __div __mod __pow __concat __unm __eq __lt __le assert collectgarbage dofile error getfenv getmetatable ipairs load loadfile loadstring module next pairs pcall print rawequal rawget rawset require select setfenv setmetatable tonumber tostring type unpack xpcall arg self coroutine resume yield status wrap create running debug getupvalue debug sethook getmetatable gethook setmetatable setlocal traceback setfenv getinfo setupvalue getlocal getregistry getfenv io lines write close flush open output type read stderr stdin input stdout popen tmpfile math log max acos huge ldexp pi cos tanh pow deg tan cosh sinh random randomseed frexp ceil floor rad abs sqrt modf asin min mod fmod log10 atan2 exp sin atan os exit setlocale date getenv difftime remove time clock tmpname rename execute package preload loadlib loaded loaders cpath config path seeall string sub upper len gfind rep find match char dump gmatch reverse byte format gsub lower table setn insert getn foreachi maxn foreach concat sort remove',
				},
				contains: o.concat([
					{
						className: 'function',
						beginKeywords: 'function',
						end: '\\)',
						contains: [
							e.inherit(e.TITLE_MODE, {
								begin: '([_a-zA-Z]\\w*\\.)*([_a-zA-Z]\\w*:)?[_a-zA-Z]\\w*',
							}),
							{
								className: 'params',
								begin: '\\(',
								endsWithParent: !0,
								contains: o,
							},
						].concat(o),
					},
					e.C_NUMBER_MODE,
					e.APOS_STRING_MODE,
					e.QUOTE_STRING_MODE,
					{
						className: 'string',
						begin: t,
						end: a,
						contains: [n],
						relevance: 5,
					},
				]),
			};
		};
	})();
	hljs.registerLanguage('lua', e);
})(); /*! `dockerfile` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => ({
			name: 'Dockerfile',
			aliases: ['docker'],
			case_insensitive: !0,
			keywords: [
				'from',
				'maintainer',
				'expose',
				'env',
				'arg',
				'user',
				'onbuild',
				'stopsignal',
			],
			contains: [
				e.HASH_COMMENT_MODE,
				e.APOS_STRING_MODE,
				e.QUOTE_STRING_MODE,
				e.NUMBER_MODE,
				{
					beginKeywords:
						'run cmd entrypoint volume add copy workdir label healthcheck shell',
					starts: { end: /[^\\]$/, subLanguage: 'bash' },
				},
			],
			illegal: '</',
		});
	})();
	hljs.registerLanguage('dockerfile', e);
})(); /*! `powershell` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = {
					$pattern: /-?[A-z\.\-]+\b/,
					keyword:
						'if else foreach return do while until elseif begin for trap data dynamicparam end break throw param continue finally in switch exit filter try process catch hidden static parameter',
					built_in:
						'ac asnp cat cd CFS chdir clc clear clhy cli clp cls clv cnsn compare copy cp cpi cpp curl cvpa dbp del diff dir dnsn ebp echo|0 epal epcsv epsn erase etsn exsn fc fhx fl ft fw gal gbp gc gcb gci gcm gcs gdr gerr ghy gi gin gjb gl gm gmo gp gps gpv group gsn gsnp gsv gtz gu gv gwmi h history icm iex ihy ii ipal ipcsv ipmo ipsn irm ise iwmi iwr kill lp ls man md measure mi mount move mp mv nal ndr ni nmo npssc nsn nv ogv oh popd ps pushd pwd r rbp rcjb rcsn rd rdr ren ri rjb rm rmdir rmo rni rnp rp rsn rsnp rujb rv rvpa rwmi sajb sal saps sasv sbp sc scb select set shcm si sl sleep sls sort sp spjb spps spsv start stz sujb sv swmi tee trcm type wget where wjb write',
				},
				s = { begin: '`[\\s\\S]', relevance: 0 },
				i = {
					className: 'variable',
					variants: [
						{
							begin: /\$\B/,
						},
						{ className: 'keyword', begin: /\$this/ },
						{ begin: /\$[\w\d][\w\d_:]*/ },
					],
				},
				a = {
					className: 'string',
					variants: [
						{ begin: /"/, end: /"/ },
						{ begin: /@"/, end: /^"@/ },
					],
					contains: [
						s,
						i,
						{
							className: 'variable',
							begin: /\$[A-z]/,
							end: /[^A-z]/,
						},
					],
				},
				t = {
					className: 'string',
					variants: [
						{ begin: /'/, end: /'/ },
						{ begin: /@'/, end: /^'@/ },
					],
				},
				r = e.inherit(e.COMMENT(null, null), {
					variants: [
						{ begin: /#/, end: /$/ },
						{ begin: /<#/, end: /#>/ },
					],
					contains: [
						{
							className: 'doctag',
							variants: [
								{
									begin: /\.(synopsis|description|example|inputs|outputs|notes|link|component|role|functionality)/,
								},
								{
									begin: /\.(parameter|forwardhelptargetname|forwardhelpcategory|remotehelprunspace|externalhelp)\s+\S+/,
								},
							],
						},
					],
				}),
				c = {
					className: 'class',
					beginKeywords: 'class enum',
					end: /\s*[{]/,
					excludeEnd: !0,
					relevance: 0,
					contains: [e.TITLE_MODE],
				},
				l = {
					className: 'function',
					begin: /function\s+/,
					end: /\s*\{|$/,
					excludeEnd: !0,
					returnBegin: !0,
					relevance: 0,
					contains: [
						{
							begin: 'function',
							relevance: 0,
							className: 'keyword',
						},
						{
							className: 'title',
							begin: /\w[\w\d]*((-)[\w\d]+)*/,
							relevance: 0,
						},
						{
							begin: /\(/,
							end: /\)/,
							className: 'params',
							relevance: 0,
							contains: [i],
						},
					],
				},
				o = {
					begin: /using\s/,
					end: /$/,
					returnBegin: !0,
					contains: [
						a,
						t,
						{
							className: 'keyword',
							begin: /(using|assembly|command|module|namespace|type)/,
						},
					],
				},
				p = {
					className: 'function',
					begin: /\[.*\]\s*[\w]+[ ]??\(/,
					end: /$/,
					returnBegin: !0,
					relevance: 0,
					contains: [
						{
							className: 'keyword',
							begin: '('.concat(
								n.keyword.toString().replace(/\s/g, '|'),
								')\\b',
							),
							endsParent: !0,
							relevance: 0,
						},
						e.inherit(e.TITLE_MODE, { endsParent: !0 }),
					],
				},
				g = [
					p,
					r,
					s,
					e.NUMBER_MODE,
					a,
					t,
					{
						className: 'built_in',
						variants: [
							{
								begin: '(Add|Clear|Close|Copy|Enter|Exit|Find|Format|Get|Hide|Join|Lock|Move|New|Open|Optimize|Pop|Push|Redo|Remove|Rename|Reset|Resize|Search|Select|Set|Show|Skip|Split|Step|Switch|Undo|Unlock|Watch|Backup|Checkpoint|Compare|Compress|Convert|ConvertFrom|ConvertTo|Dismount|Edit|Expand|Export|Group|Import|Initialize|Limit|Merge|Mount|Out|Publish|Restore|Save|Sync|Unpublish|Update|Approve|Assert|Build|Complete|Confirm|Deny|Deploy|Disable|Enable|Install|Invoke|Register|Request|Restart|Resume|Start|Stop|Submit|Suspend|Uninstall|Unregister|Wait|Debug|Measure|Ping|Repair|Resolve|Test|Trace|Connect|Disconnect|Read|Receive|Send|Write|Block|Grant|Protect|Revoke|Unblock|Unprotect|Use|ForEach|Sort|Tee|Where)+(-)[\\w\\d]+',
							},
						],
					},
					i,
					{ className: 'literal', begin: /\$(null|true|false)\b/ },
					{
						className: 'selector-tag',
						begin: /@\B/,
						relevance: 0,
					},
				],
				m = {
					begin: /\[/,
					end: /\]/,
					excludeBegin: !0,
					excludeEnd: !0,
					relevance: 0,
					contains: [].concat(
						'self',
						g,
						{
							begin: '(string|char|byte|int|long|bool|decimal|single|double|DateTime|xml|array|hashtable|void)',
							className: 'built_in',
							relevance: 0,
						},
						{ className: 'type', begin: /[\.\w\d]+/, relevance: 0 },
					),
				};
			return (
				p.contains.unshift(m),
				{
					name: 'PowerShell',
					aliases: ['pwsh', 'ps', 'ps1'],
					case_insensitive: !0,
					keywords: n,
					contains: g.concat(
						c,
						l,
						o,
						{
							variants: [
								{
									className: 'operator',
									begin: '(-and|-as|-band|-bnot|-bor|-bxor|-casesensitive|-ccontains|-ceq|-cge|-cgt|-cle|-clike|-clt|-cmatch|-cne|-cnotcontains|-cnotlike|-cnotmatch|-contains|-creplace|-csplit|-eq|-exact|-f|-file|-ge|-gt|-icontains|-ieq|-ige|-igt|-ile|-ilike|-ilt|-imatch|-in|-ine|-inotcontains|-inotlike|-inotmatch|-ireplace|-is|-isnot|-isplit|-join|-le|-like|-lt|-match|-ne|-not|-notcontains|-notin|-notlike|-notmatch|-or|-regex|-replace|-shl|-shr|-split|-wildcard|-xor)\\b',
								},
								{
									className: 'literal',
									begin: /(-){1,2}[\w\d-]+/,
									relevance: 0,
								},
							],
						},
						m,
					),
				}
			);
		};
	})();
	hljs.registerLanguage('powershell', e);
})(); /*! `php` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const t = e.regex,
				a = /(?![A-Za-z0-9])(?![$])/,
				r = t.concat(/[a-zA-Z_\x7f-\xff][a-zA-Z0-9_\x7f-\xff]*/, a),
				n = t.concat(
					/(\\?[A-Z][a-z0-9_\x7f-\xff]+|\\?[A-Z]+(?=[A-Z][a-z0-9_\x7f-\xff])){1,}/,
					a,
				),
				o = {
					scope: 'variable',
					match: '\\$+' + r,
				},
				c = {
					scope: 'subst',
					variants: [
						{ begin: /\$\w+/ },
						{
							begin: /\{\$/,
							end: /\}/,
						},
					],
				},
				i = e.inherit(e.APOS_STRING_MODE, { illegal: null }),
				s = '[ \t\n]',
				l = {
					scope: 'string',
					variants: [
						e.inherit(e.QUOTE_STRING_MODE, {
							illegal: null,
							contains: e.QUOTE_STRING_MODE.contains.concat(c),
						}),
						i,
						e.END_SAME_AS_BEGIN({
							begin: /<<<[ \t]*(\w+)\n/,
							end: /[ \t]*(\w+)\b/,
							contains: e.QUOTE_STRING_MODE.contains.concat(c),
						}),
					],
				},
				_ = {
					scope: 'number',
					variants: [
						{ begin: '\\b0[bB][01]+(?:_[01]+)*\\b' },
						{
							begin: '\\b0[oO][0-7]+(?:_[0-7]+)*\\b',
						},
						{
							begin: '\\b0[xX][\\da-fA-F]+(?:_[\\da-fA-F]+)*\\b',
						},
						{
							begin: '(?:\\b\\d+(?:_\\d+)*(\\.(?:\\d+(?:_\\d+)*))?|\\B\\.\\d+)(?:[eE][+-]?\\d+)?',
						},
					],
					relevance: 0,
				},
				d = ['false', 'null', 'true'],
				p = [
					'__CLASS__',
					'__DIR__',
					'__FILE__',
					'__FUNCTION__',
					'__COMPILER_HALT_OFFSET__',
					'__LINE__',
					'__METHOD__',
					'__NAMESPACE__',
					'__TRAIT__',
					'die',
					'echo',
					'exit',
					'include',
					'include_once',
					'print',
					'require',
					'require_once',
					'array',
					'abstract',
					'and',
					'as',
					'binary',
					'bool',
					'boolean',
					'break',
					'callable',
					'case',
					'catch',
					'class',
					'clone',
					'const',
					'continue',
					'declare',
					'default',
					'do',
					'double',
					'else',
					'elseif',
					'empty',
					'enddeclare',
					'endfor',
					'endforeach',
					'endif',
					'endswitch',
					'endwhile',
					'enum',
					'eval',
					'extends',
					'final',
					'finally',
					'float',
					'for',
					'foreach',
					'from',
					'global',
					'goto',
					'if',
					'implements',
					'instanceof',
					'insteadof',
					'int',
					'integer',
					'interface',
					'isset',
					'iterable',
					'list',
					'match|0',
					'mixed',
					'new',
					'never',
					'object',
					'or',
					'private',
					'protected',
					'public',
					'readonly',
					'real',
					'return',
					'string',
					'switch',
					'throw',
					'trait',
					'try',
					'unset',
					'use',
					'var',
					'void',
					'while',
					'xor',
					'yield',
				],
				b = [
					'Error|0',
					'AppendIterator',
					'ArgumentCountError',
					'ArithmeticError',
					'ArrayIterator',
					'ArrayObject',
					'AssertionError',
					'BadFunctionCallException',
					'BadMethodCallException',
					'CachingIterator',
					'CallbackFilterIterator',
					'CompileError',
					'Countable',
					'DirectoryIterator',
					'DivisionByZeroError',
					'DomainException',
					'EmptyIterator',
					'ErrorException',
					'Exception',
					'FilesystemIterator',
					'FilterIterator',
					'GlobIterator',
					'InfiniteIterator',
					'InvalidArgumentException',
					'IteratorIterator',
					'LengthException',
					'LimitIterator',
					'LogicException',
					'MultipleIterator',
					'NoRewindIterator',
					'OutOfBoundsException',
					'OutOfRangeException',
					'OuterIterator',
					'OverflowException',
					'ParentIterator',
					'ParseError',
					'RangeException',
					'RecursiveArrayIterator',
					'RecursiveCachingIterator',
					'RecursiveCallbackFilterIterator',
					'RecursiveDirectoryIterator',
					'RecursiveFilterIterator',
					'RecursiveIterator',
					'RecursiveIteratorIterator',
					'RecursiveRegexIterator',
					'RecursiveTreeIterator',
					'RegexIterator',
					'RuntimeException',
					'SeekableIterator',
					'SplDoublyLinkedList',
					'SplFileInfo',
					'SplFileObject',
					'SplFixedArray',
					'SplHeap',
					'SplMaxHeap',
					'SplMinHeap',
					'SplObjectStorage',
					'SplObserver',
					'SplPriorityQueue',
					'SplQueue',
					'SplStack',
					'SplSubject',
					'SplTempFileObject',
					'TypeError',
					'UnderflowException',
					'UnexpectedValueException',
					'UnhandledMatchError',
					'ArrayAccess',
					'BackedEnum',
					'Closure',
					'Fiber',
					'Generator',
					'Iterator',
					'IteratorAggregate',
					'Serializable',
					'Stringable',
					'Throwable',
					'Traversable',
					'UnitEnum',
					'WeakReference',
					'WeakMap',
					'Directory',
					'__PHP_Incomplete_Class',
					'parent',
					'php_user_filter',
					'self',
					'static',
					'stdClass',
				],
				E = {
					keyword: p,
					literal: ((e) => {
						const t = [];
						return (
							e.forEach((e) => {
								t.push(e),
									e.toLowerCase() === e
										? t.push(e.toUpperCase())
										: t.push(e.toLowerCase());
							}),
							t
						);
					})(d),
					built_in: b,
				},
				u = (e) => e.map((e) => e.replace(/\|\d+$/, '')),
				g = {
					variants: [
						{
							match: [
								/new/,
								t.concat(s, '+'),
								t.concat('(?!', u(b).join('\\b|'), '\\b)'),
								n,
							],
							scope: {
								1: 'keyword',
								4: 'title.class',
							},
						},
					],
				},
				h = t.concat(r, '\\b(?!\\()'),
				m = {
					variants: [
						{
							match: [
								t.concat(/::/, t.lookahead(/(?!class\b)/)),
								h,
							],
							scope: { 2: 'variable.constant' },
						},
						{
							match: [/::/, /class/],
							scope: { 2: 'variable.language' },
						},
						{
							match: [
								n,
								t.concat(/::/, t.lookahead(/(?!class\b)/)),
								h,
							],
							scope: { 1: 'title.class', 3: 'variable.constant' },
						},
						{
							match: [
								n,
								t.concat('::', t.lookahead(/(?!class\b)/)),
							],
							scope: { 1: 'title.class' },
						},
						{
							match: [n, /::/, /class/],
							scope: { 1: 'title.class', 3: 'variable.language' },
						},
					],
				},
				I = {
					scope: 'attr',
					match: t.concat(r, t.lookahead(':'), t.lookahead(/(?!::)/)),
				},
				f = {
					relevance: 0,
					begin: /\(/,
					end: /\)/,
					keywords: E,
					contains: [I, o, m, e.C_BLOCK_COMMENT_MODE, l, _, g],
				},
				O = {
					relevance: 0,
					match: [
						/\b/,
						t.concat(
							'(?!fn\\b|function\\b|',
							u(p).join('\\b|'),
							'|',
							u(b).join('\\b|'),
							'\\b)',
						),
						r,
						t.concat(s, '*'),
						t.lookahead(/(?=\()/),
					],
					scope: { 3: 'title.function.invoke' },
					contains: [f],
				};
			f.contains.push(O);
			const v = [I, m, e.C_BLOCK_COMMENT_MODE, l, _, g];
			return {
				case_insensitive: !1,
				keywords: E,
				contains: [
					{
						begin: t.concat(/#\[\s*/, n),
						beginScope: 'meta',
						end: /]/,
						endScope: 'meta',
						keywords: { literal: d, keyword: ['new', 'array'] },
						contains: [
							{
								begin: /\[/,
								end: /]/,
								keywords: {
									literal: d,
									keyword: ['new', 'array'],
								},
								contains: ['self', ...v],
							},
							...v,
							{ scope: 'meta', match: n },
						],
					},
					e.HASH_COMMENT_MODE,
					e.COMMENT('//', '$'),
					e.COMMENT('/\\*', '\\*/', {
						contains: [
							{
								scope: 'doctag',
								match: '@[A-Za-z]+',
							},
						],
					}),
					{
						match: /__halt_compiler\(\);/,
						keywords: '__halt_compiler',
						starts: {
							scope: 'comment',
							end: e.MATCH_NOTHING_RE,
							contains: [
								{ match: /\?>/, scope: 'meta', endsParent: !0 },
							],
						},
					},
					{
						scope: 'meta',
						variants: [
							{
								begin: /<\?php/,
								relevance: 10,
							},
							{ begin: /<\?=/ },
							{ begin: /<\?/, relevance: 0.1 },
							{
								begin: /\?>/,
							},
						],
					},
					{ scope: 'variable.language', match: /\$this\b/ },
					o,
					O,
					m,
					{
						match: [/const/, /\s/, r],
						scope: { 1: 'keyword', 3: 'variable.constant' },
					},
					g,
					{
						scope: 'function',
						relevance: 0,
						beginKeywords: 'fn function',
						end: /[;{]/,
						excludeEnd: !0,
						illegal: '[$%\\[]',
						contains: [
							{ beginKeywords: 'use' },
							e.UNDERSCORE_TITLE_MODE,
							{ begin: '=>', endsParent: !0 },
							{
								scope: 'params',
								begin: '\\(',
								end: '\\)',
								excludeBegin: !0,
								excludeEnd: !0,
								keywords: E,
								contains: [
									'self',
									o,
									m,
									e.C_BLOCK_COMMENT_MODE,
									l,
									_,
								],
							},
						],
					},
					{
						scope: 'class',
						variants: [
							{
								beginKeywords: 'enum',
								illegal: /[($"]/,
							},
							{
								beginKeywords: 'class interface trait',
								illegal: /[:($"]/,
							},
						],
						relevance: 0,
						end: /\{/,
						excludeEnd: !0,
						contains: [
							{
								beginKeywords: 'extends implements',
							},
							e.UNDERSCORE_TITLE_MODE,
						],
					},
					{
						beginKeywords: 'namespace',
						relevance: 0,
						end: ';',
						illegal: /[.']/,
						contains: [
							e.inherit(e.UNDERSCORE_TITLE_MODE, {
								scope: 'title.class',
							}),
						],
					},
					{
						beginKeywords: 'use',
						relevance: 0,
						end: ';',
						contains: [
							{
								match: /\b(as|const|function)\b/,
								scope: 'keyword',
							},
							e.UNDERSCORE_TITLE_MODE,
						],
					},
					l,
					_,
				],
			};
		};
	})();
	hljs.registerLanguage('php', e);
})(); /*! `php-template` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var n = (() => {
		'use strict';
		return (n) => ({
			name: 'PHP template',
			subLanguage: 'xml',
			contains: [
				{
					begin: /<\?(php|=)?/,
					end: /\?>/,
					subLanguage: 'php',
					contains: [
						{ begin: '/\\*', end: '\\*/', skip: !0 },
						{ begin: 'b"', end: '"', skip: !0 },
						{
							begin: "b'",
							end: "'",
							skip: !0,
						},
						n.inherit(n.APOS_STRING_MODE, {
							illegal: null,
							className: null,
							contains: null,
							skip: !0,
						}),
						n.inherit(n.QUOTE_STRING_MODE, {
							illegal: null,
							className: null,
							contains: null,
							skip: !0,
						}),
					],
				},
			],
		});
	})();
	hljs.registerLanguage('php-template', n);
})(); /*! `scss` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		const e = [
				'a',
				'abbr',
				'address',
				'article',
				'aside',
				'audio',
				'b',
				'blockquote',
				'body',
				'button',
				'canvas',
				'caption',
				'cite',
				'code',
				'dd',
				'del',
				'details',
				'dfn',
				'div',
				'dl',
				'dt',
				'em',
				'fieldset',
				'figcaption',
				'figure',
				'footer',
				'form',
				'h1',
				'h2',
				'h3',
				'h4',
				'h5',
				'h6',
				'header',
				'hgroup',
				'html',
				'i',
				'iframe',
				'img',
				'input',
				'ins',
				'kbd',
				'label',
				'legend',
				'li',
				'main',
				'mark',
				'menu',
				'nav',
				'object',
				'ol',
				'p',
				'q',
				'quote',
				'samp',
				'section',
				'span',
				'strong',
				'summary',
				'sup',
				'table',
				'tbody',
				'td',
				'textarea',
				'tfoot',
				'th',
				'thead',
				'time',
				'tr',
				'ul',
				'var',
				'video',
			],
			r = [
				'any-hover',
				'any-pointer',
				'aspect-ratio',
				'color',
				'color-gamut',
				'color-index',
				'device-aspect-ratio',
				'device-height',
				'device-width',
				'display-mode',
				'forced-colors',
				'grid',
				'height',
				'hover',
				'inverted-colors',
				'monochrome',
				'orientation',
				'overflow-block',
				'overflow-inline',
				'pointer',
				'prefers-color-scheme',
				'prefers-contrast',
				'prefers-reduced-motion',
				'prefers-reduced-transparency',
				'resolution',
				'scan',
				'scripting',
				'update',
				'width',
				'min-width',
				'max-width',
				'min-height',
				'max-height',
			],
			i = [
				'active',
				'any-link',
				'blank',
				'checked',
				'current',
				'default',
				'defined',
				'dir',
				'disabled',
				'drop',
				'empty',
				'enabled',
				'first',
				'first-child',
				'first-of-type',
				'fullscreen',
				'future',
				'focus',
				'focus-visible',
				'focus-within',
				'has',
				'host',
				'host-context',
				'hover',
				'indeterminate',
				'in-range',
				'invalid',
				'is',
				'lang',
				'last-child',
				'last-of-type',
				'left',
				'link',
				'local-link',
				'not',
				'nth-child',
				'nth-col',
				'nth-last-child',
				'nth-last-col',
				'nth-last-of-type',
				'nth-of-type',
				'only-child',
				'only-of-type',
				'optional',
				'out-of-range',
				'past',
				'placeholder-shown',
				'read-only',
				'read-write',
				'required',
				'right',
				'root',
				'scope',
				'target',
				'target-within',
				'user-invalid',
				'valid',
				'visited',
				'where',
			],
			t = [
				'after',
				'backdrop',
				'before',
				'cue',
				'cue-region',
				'first-letter',
				'first-line',
				'grammar-error',
				'marker',
				'part',
				'placeholder',
				'selection',
				'slotted',
				'spelling-error',
			],
			o = [
				'align-content',
				'align-items',
				'align-self',
				'all',
				'animation',
				'animation-delay',
				'animation-direction',
				'animation-duration',
				'animation-fill-mode',
				'animation-iteration-count',
				'animation-name',
				'animation-play-state',
				'animation-timing-function',
				'backface-visibility',
				'background',
				'background-attachment',
				'background-blend-mode',
				'background-clip',
				'background-color',
				'background-image',
				'background-origin',
				'background-position',
				'background-repeat',
				'background-size',
				'block-size',
				'border',
				'border-block',
				'border-block-color',
				'border-block-end',
				'border-block-end-color',
				'border-block-end-style',
				'border-block-end-width',
				'border-block-start',
				'border-block-start-color',
				'border-block-start-style',
				'border-block-start-width',
				'border-block-style',
				'border-block-width',
				'border-bottom',
				'border-bottom-color',
				'border-bottom-left-radius',
				'border-bottom-right-radius',
				'border-bottom-style',
				'border-bottom-width',
				'border-collapse',
				'border-color',
				'border-image',
				'border-image-outset',
				'border-image-repeat',
				'border-image-slice',
				'border-image-source',
				'border-image-width',
				'border-inline',
				'border-inline-color',
				'border-inline-end',
				'border-inline-end-color',
				'border-inline-end-style',
				'border-inline-end-width',
				'border-inline-start',
				'border-inline-start-color',
				'border-inline-start-style',
				'border-inline-start-width',
				'border-inline-style',
				'border-inline-width',
				'border-left',
				'border-left-color',
				'border-left-style',
				'border-left-width',
				'border-radius',
				'border-right',
				'border-right-color',
				'border-right-style',
				'border-right-width',
				'border-spacing',
				'border-style',
				'border-top',
				'border-top-color',
				'border-top-left-radius',
				'border-top-right-radius',
				'border-top-style',
				'border-top-width',
				'border-width',
				'bottom',
				'box-decoration-break',
				'box-shadow',
				'box-sizing',
				'break-after',
				'break-before',
				'break-inside',
				'caption-side',
				'caret-color',
				'clear',
				'clip',
				'clip-path',
				'clip-rule',
				'color',
				'column-count',
				'column-fill',
				'column-gap',
				'column-rule',
				'column-rule-color',
				'column-rule-style',
				'column-rule-width',
				'column-span',
				'column-width',
				'columns',
				'contain',
				'content',
				'content-visibility',
				'counter-increment',
				'counter-reset',
				'cue',
				'cue-after',
				'cue-before',
				'cursor',
				'direction',
				'display',
				'empty-cells',
				'filter',
				'flex',
				'flex-basis',
				'flex-direction',
				'flex-flow',
				'flex-grow',
				'flex-shrink',
				'flex-wrap',
				'float',
				'flow',
				'font',
				'font-display',
				'font-family',
				'font-feature-settings',
				'font-kerning',
				'font-language-override',
				'font-size',
				'font-size-adjust',
				'font-smoothing',
				'font-stretch',
				'font-style',
				'font-synthesis',
				'font-variant',
				'font-variant-caps',
				'font-variant-east-asian',
				'font-variant-ligatures',
				'font-variant-numeric',
				'font-variant-position',
				'font-variation-settings',
				'font-weight',
				'gap',
				'glyph-orientation-vertical',
				'grid',
				'grid-area',
				'grid-auto-columns',
				'grid-auto-flow',
				'grid-auto-rows',
				'grid-column',
				'grid-column-end',
				'grid-column-start',
				'grid-gap',
				'grid-row',
				'grid-row-end',
				'grid-row-start',
				'grid-template',
				'grid-template-areas',
				'grid-template-columns',
				'grid-template-rows',
				'hanging-punctuation',
				'height',
				'hyphens',
				'icon',
				'image-orientation',
				'image-rendering',
				'image-resolution',
				'ime-mode',
				'inline-size',
				'isolation',
				'justify-content',
				'left',
				'letter-spacing',
				'line-break',
				'line-height',
				'list-style',
				'list-style-image',
				'list-style-position',
				'list-style-type',
				'margin',
				'margin-block',
				'margin-block-end',
				'margin-block-start',
				'margin-bottom',
				'margin-inline',
				'margin-inline-end',
				'margin-inline-start',
				'margin-left',
				'margin-right',
				'margin-top',
				'marks',
				'mask',
				'mask-border',
				'mask-border-mode',
				'mask-border-outset',
				'mask-border-repeat',
				'mask-border-slice',
				'mask-border-source',
				'mask-border-width',
				'mask-clip',
				'mask-composite',
				'mask-image',
				'mask-mode',
				'mask-origin',
				'mask-position',
				'mask-repeat',
				'mask-size',
				'mask-type',
				'max-block-size',
				'max-height',
				'max-inline-size',
				'max-width',
				'min-block-size',
				'min-height',
				'min-inline-size',
				'min-width',
				'mix-blend-mode',
				'nav-down',
				'nav-index',
				'nav-left',
				'nav-right',
				'nav-up',
				'none',
				'normal',
				'object-fit',
				'object-position',
				'opacity',
				'order',
				'orphans',
				'outline',
				'outline-color',
				'outline-offset',
				'outline-style',
				'outline-width',
				'overflow',
				'overflow-wrap',
				'overflow-x',
				'overflow-y',
				'padding',
				'padding-block',
				'padding-block-end',
				'padding-block-start',
				'padding-bottom',
				'padding-inline',
				'padding-inline-end',
				'padding-inline-start',
				'padding-left',
				'padding-right',
				'padding-top',
				'page-break-after',
				'page-break-before',
				'page-break-inside',
				'pause',
				'pause-after',
				'pause-before',
				'perspective',
				'perspective-origin',
				'pointer-events',
				'position',
				'quotes',
				'resize',
				'rest',
				'rest-after',
				'rest-before',
				'right',
				'row-gap',
				'scroll-margin',
				'scroll-margin-block',
				'scroll-margin-block-end',
				'scroll-margin-block-start',
				'scroll-margin-bottom',
				'scroll-margin-inline',
				'scroll-margin-inline-end',
				'scroll-margin-inline-start',
				'scroll-margin-left',
				'scroll-margin-right',
				'scroll-margin-top',
				'scroll-padding',
				'scroll-padding-block',
				'scroll-padding-block-end',
				'scroll-padding-block-start',
				'scroll-padding-bottom',
				'scroll-padding-inline',
				'scroll-padding-inline-end',
				'scroll-padding-inline-start',
				'scroll-padding-left',
				'scroll-padding-right',
				'scroll-padding-top',
				'scroll-snap-align',
				'scroll-snap-stop',
				'scroll-snap-type',
				'scrollbar-color',
				'scrollbar-gutter',
				'scrollbar-width',
				'shape-image-threshold',
				'shape-margin',
				'shape-outside',
				'speak',
				'speak-as',
				'src',
				'tab-size',
				'table-layout',
				'text-align',
				'text-align-all',
				'text-align-last',
				'text-combine-upright',
				'text-decoration',
				'text-decoration-color',
				'text-decoration-line',
				'text-decoration-style',
				'text-emphasis',
				'text-emphasis-color',
				'text-emphasis-position',
				'text-emphasis-style',
				'text-indent',
				'text-justify',
				'text-orientation',
				'text-overflow',
				'text-rendering',
				'text-shadow',
				'text-transform',
				'text-underline-position',
				'top',
				'transform',
				'transform-box',
				'transform-origin',
				'transform-style',
				'transition',
				'transition-delay',
				'transition-duration',
				'transition-property',
				'transition-timing-function',
				'unicode-bidi',
				'vertical-align',
				'visibility',
				'voice-balance',
				'voice-duration',
				'voice-family',
				'voice-pitch',
				'voice-range',
				'voice-rate',
				'voice-stress',
				'voice-volume',
				'white-space',
				'widows',
				'width',
				'will-change',
				'word-break',
				'word-spacing',
				'word-wrap',
				'writing-mode',
				'z-index',
			].reverse();
		return (n) => {
			const a = ((e) => ({
					IMPORTANT: { scope: 'meta', begin: '!important' },
					BLOCK_COMMENT: e.C_BLOCK_COMMENT_MODE,
					HEXCOLOR: {
						scope: 'number',
						begin: /#(([0-9a-fA-F]{3,4})|(([0-9a-fA-F]{2}){3,4}))\b/,
					},
					FUNCTION_DISPATCH: {
						className: 'built_in',
						begin: /[\w-]+(?=\()/,
					},
					ATTRIBUTE_SELECTOR_MODE: {
						scope: 'selector-attr',
						begin: /\[/,
						end: /\]/,
						illegal: '$',
						contains: [e.APOS_STRING_MODE, e.QUOTE_STRING_MODE],
					},
					CSS_NUMBER_MODE: {
						scope: 'number',
						begin:
							e.NUMBER_RE +
							'(%|em|ex|ch|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc|px|deg|grad|rad|turn|s|ms|Hz|kHz|dpi|dpcm|dppx)?',
						relevance: 0,
					},
					CSS_VARIABLE: {
						className: 'attr',
						begin: /--[A-Za-z][A-Za-z0-9_-]*/,
					},
				}))(n),
				l = t,
				s = i,
				d = '@[a-z-]+',
				c = {
					className: 'variable',
					begin: '(\\$[a-zA-Z-][a-zA-Z0-9_-]*)\\b',
					relevance: 0,
				};
			return {
				name: 'SCSS',
				case_insensitive: !0,
				illegal: "[=/|']",
				contains: [
					n.C_LINE_COMMENT_MODE,
					n.C_BLOCK_COMMENT_MODE,
					a.CSS_NUMBER_MODE,
					{
						className: 'selector-id',
						begin: '#[A-Za-z0-9_-]+',
						relevance: 0,
					},
					{
						className: 'selector-class',
						begin: '\\.[A-Za-z0-9_-]+',
						relevance: 0,
					},
					a.ATTRIBUTE_SELECTOR_MODE,
					{
						className: 'selector-tag',
						begin: '\\b(' + e.join('|') + ')\\b',
						relevance: 0,
					},
					{
						className: 'selector-pseudo',
						begin: ':(' + s.join('|') + ')',
					},
					{
						className: 'selector-pseudo',
						begin: ':(:)?(' + l.join('|') + ')',
					},
					c,
					{ begin: /\(/, end: /\)/, contains: [a.CSS_NUMBER_MODE] },
					a.CSS_VARIABLE,
					{
						className: 'attribute',
						begin: '\\b(' + o.join('|') + ')\\b',
					},
					{
						begin: '\\b(whitespace|wait|w-resize|visible|vertical-text|vertical-ideographic|uppercase|upper-roman|upper-alpha|underline|transparent|top|thin|thick|text|text-top|text-bottom|tb-rl|table-header-group|table-footer-group|sw-resize|super|strict|static|square|solid|small-caps|separate|se-resize|scroll|s-resize|rtl|row-resize|ridge|right|repeat|repeat-y|repeat-x|relative|progress|pointer|overline|outside|outset|oblique|nowrap|not-allowed|normal|none|nw-resize|no-repeat|no-drop|newspaper|ne-resize|n-resize|move|middle|medium|ltr|lr-tb|lowercase|lower-roman|lower-alpha|loose|list-item|line|line-through|line-edge|lighter|left|keep-all|justify|italic|inter-word|inter-ideograph|inside|inset|inline|inline-block|inherit|inactive|ideograph-space|ideograph-parenthesis|ideograph-numeric|ideograph-alpha|horizontal|hidden|help|hand|groove|fixed|ellipsis|e-resize|double|dotted|distribute|distribute-space|distribute-letter|distribute-all-lines|disc|disabled|default|decimal|dashed|crosshair|collapse|col-resize|circle|char|center|capitalize|break-word|break-all|bottom|both|bolder|bold|block|bidi-override|below|baseline|auto|always|all-scroll|absolute|table|table-cell)\\b',
					},
					{
						begin: /:/,
						end: /[;}{]/,
						relevance: 0,
						contains: [
							a.BLOCK_COMMENT,
							c,
							a.HEXCOLOR,
							a.CSS_NUMBER_MODE,
							n.QUOTE_STRING_MODE,
							n.APOS_STRING_MODE,
							a.IMPORTANT,
							a.FUNCTION_DISPATCH,
						],
					},
					{
						begin: '@(page|font-face)',
						keywords: { $pattern: d, keyword: '@page @font-face' },
					},
					{
						begin: '@',
						end: '[{;]',
						returnBegin: !0,
						keywords: {
							$pattern: /[a-z-]+/,
							keyword: 'and or not only',
							attribute: r.join(' '),
						},
						contains: [
							{ begin: d, className: 'keyword' },
							{ begin: /[a-z-]+(?=:)/, className: 'attribute' },
							c,
							n.QUOTE_STRING_MODE,
							n.APOS_STRING_MODE,
							a.HEXCOLOR,
							a.CSS_NUMBER_MODE,
						],
					},
					a.FUNCTION_DISPATCH,
				],
			};
		};
	})();
	hljs.registerLanguage('scss', e);
})(); /*! `plaintext` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var t = (() => {
		'use strict';
		return (t) => ({
			name: 'Plain text',
			aliases: ['text', 'txt'],
			disableAutodetect: !0,
		});
	})();
	hljs.registerLanguage('plaintext', t);
})(); /*! `java` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		var e = '\\.([0-9](_*[0-9])*)',
			a = '[0-9a-fA-F](_*[0-9a-fA-F])*',
			n = {
				className: 'number',
				variants: [
					{
						begin: `(\\b([0-9](_*[0-9])*)((${e})|\\.)?|(${e}))[eE][+-]?([0-9](_*[0-9])*)[fFdD]?\\b`,
					},
					{
						begin: `\\b([0-9](_*[0-9])*)((${e})[fFdD]?\\b|\\.([fFdD]\\b)?)`,
					},
					{
						begin: `(${e})[fFdD]?\\b`,
					},
					{ begin: '\\b([0-9](_*[0-9])*)[fFdD]\\b' },
					{
						begin: `\\b0[xX]((${a})\\.?|(${a})?\\.(${a}))[pP][+-]?([0-9](_*[0-9])*)[fFdD]?\\b`,
					},
					{ begin: '\\b(0|[1-9](_*[0-9])*)[lL]?\\b' },
					{ begin: `\\b0[xX](${a})[lL]?\\b` },
					{
						begin: '\\b0(_*[0-7])*[lL]?\\b',
					},
					{ begin: '\\b0[bB][01](_*[01])*[lL]?\\b' },
				],
				relevance: 0,
			};
		function s(e, a, n) {
			return -1 === n ? '' : e.replace(a, (t) => s(e, a, n - 1));
		}
		return (e) => {
			const a = e.regex,
				t = '[\xc0-\u02b8a-zA-Z_$][\xc0-\u02b8a-zA-Z_$0-9]*',
				i =
					t +
					s(
						'(?:<' + t + '~~~(?:\\s*,\\s*' + t + '~~~)*>)?',
						/~~~/g,
						2,
					),
				r = {
					keyword: [
						'synchronized',
						'abstract',
						'private',
						'var',
						'static',
						'if',
						'const ',
						'for',
						'while',
						'strictfp',
						'finally',
						'protected',
						'import',
						'native',
						'final',
						'void',
						'enum',
						'else',
						'break',
						'transient',
						'catch',
						'instanceof',
						'volatile',
						'case',
						'assert',
						'package',
						'default',
						'public',
						'try',
						'switch',
						'continue',
						'throws',
						'protected',
						'public',
						'private',
						'module',
						'requires',
						'exports',
						'do',
						'sealed',
					],
					literal: ['false', 'true', 'null'],
					type: [
						'char',
						'boolean',
						'long',
						'float',
						'int',
						'byte',
						'short',
						'double',
					],
					built_in: ['super', 'this'],
				},
				l = {
					className: 'meta',
					begin: '@' + t,
					contains: [
						{
							begin: /\(/,
							end: /\)/,
							contains: ['self'],
						},
					],
				},
				c = {
					className: 'params',
					begin: /\(/,
					end: /\)/,
					keywords: r,
					relevance: 0,
					contains: [e.C_BLOCK_COMMENT_MODE],
					endsParent: !0,
				};
			return {
				name: 'Java',
				aliases: ['jsp'],
				keywords: r,
				illegal: /<\/|#/,
				contains: [
					e.COMMENT('/\\*\\*', '\\*/', {
						relevance: 0,
						contains: [
							{ begin: /\w+@/, relevance: 0 },
							{ className: 'doctag', begin: '@[A-Za-z]+' },
						],
					}),
					{
						begin: /import java\.[a-z]+\./,
						keywords: 'import',
						relevance: 2,
					},
					e.C_LINE_COMMENT_MODE,
					e.C_BLOCK_COMMENT_MODE,
					{
						begin: /"""/,
						end: /"""/,
						className: 'string',
						contains: [e.BACKSLASH_ESCAPE],
					},
					e.APOS_STRING_MODE,
					e.QUOTE_STRING_MODE,
					{
						match: [
							/\b(?:class|interface|enum|extends|implements|new)/,
							/\s+/,
							t,
						],
						className: {
							1: 'keyword',
							3: 'title.class',
						},
					},
					{ match: /non-sealed/, scope: 'keyword' },
					{
						begin: [
							a.concat(/(?!else)/, t),
							/\s+/,
							t,
							/\s+/,
							/=(?!=)/,
						],
						className: { 1: 'type', 3: 'variable', 5: 'operator' },
					},
					{
						begin: [/record/, /\s+/, t],
						className: { 1: 'keyword', 3: 'title.class' },
						contains: [
							c,
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					{
						beginKeywords: 'new throw return else',
						relevance: 0,
					},
					{
						begin: [
							'(?:' + i + '\\s+)',
							e.UNDERSCORE_IDENT_RE,
							/\s*(?=\()/,
						],
						className: {
							2: 'title.function',
						},
						keywords: r,
						contains: [
							{
								className: 'params',
								begin: /\(/,
								end: /\)/,
								keywords: r,
								relevance: 0,
								contains: [
									l,
									e.APOS_STRING_MODE,
									e.QUOTE_STRING_MODE,
									n,
									e.C_BLOCK_COMMENT_MODE,
								],
							},
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					n,
					l,
				],
			};
		};
	})();
	hljs.registerLanguage('java', e);
})(); /*! `csharp` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = {
					keyword: [
						'abstract',
						'as',
						'base',
						'break',
						'case',
						'catch',
						'class',
						'const',
						'continue',
						'do',
						'else',
						'event',
						'explicit',
						'extern',
						'finally',
						'fixed',
						'for',
						'foreach',
						'goto',
						'if',
						'implicit',
						'in',
						'interface',
						'internal',
						'is',
						'lock',
						'namespace',
						'new',
						'operator',
						'out',
						'override',
						'params',
						'private',
						'protected',
						'public',
						'readonly',
						'record',
						'ref',
						'return',
						'scoped',
						'sealed',
						'sizeof',
						'stackalloc',
						'static',
						'struct',
						'switch',
						'this',
						'throw',
						'try',
						'typeof',
						'unchecked',
						'unsafe',
						'using',
						'virtual',
						'void',
						'volatile',
						'while',
					].concat([
						'add',
						'alias',
						'and',
						'ascending',
						'async',
						'await',
						'by',
						'descending',
						'equals',
						'from',
						'get',
						'global',
						'group',
						'init',
						'into',
						'join',
						'let',
						'nameof',
						'not',
						'notnull',
						'on',
						'or',
						'orderby',
						'partial',
						'remove',
						'select',
						'set',
						'unmanaged',
						'value|0',
						'var',
						'when',
						'where',
						'with',
						'yield',
					]),
					built_in: [
						'bool',
						'byte',
						'char',
						'decimal',
						'delegate',
						'double',
						'dynamic',
						'enum',
						'float',
						'int',
						'long',
						'nint',
						'nuint',
						'object',
						'sbyte',
						'short',
						'string',
						'ulong',
						'uint',
						'ushort',
					],
					literal: ['default', 'false', 'null', 'true'],
				},
				a = e.inherit(e.TITLE_MODE, {
					begin: '[a-zA-Z](\\.?\\w)*',
				}),
				i = {
					className: 'number',
					variants: [
						{
							begin: "\\b(0b[01']+)",
						},
						{
							begin: "(-?)\\b([\\d']+(\\.[\\d']*)?|\\.[\\d']+)(u|U|l|L|ul|UL|f|F|b|B)",
						},
						{
							begin: "(-?)(\\b0[xX][a-fA-F0-9']+|(\\b[\\d']+(\\.[\\d']*)?|\\.[\\d']+)([eE][-+]?[\\d']+)?)",
						},
					],
					relevance: 0,
				},
				s = {
					className: 'string',
					begin: '@"',
					end: '"',
					contains: [{ begin: '""' }],
				},
				t = e.inherit(s, { illegal: /\n/ }),
				r = { className: 'subst', begin: /\{/, end: /\}/, keywords: n },
				l = e.inherit(r, { illegal: /\n/ }),
				c = {
					className: 'string',
					begin: /\$"/,
					end: '"',
					illegal: /\n/,
					contains: [
						{ begin: /\{\{/ },
						{ begin: /\}\}/ },
						e.BACKSLASH_ESCAPE,
						l,
					],
				},
				o = {
					className: 'string',
					begin: /\$@"/,
					end: '"',
					contains: [
						{
							begin: /\{\{/,
						},
						{ begin: /\}\}/ },
						{ begin: '""' },
						r,
					],
				},
				d = e.inherit(o, {
					illegal: /\n/,
					contains: [
						{ begin: /\{\{/ },
						{ begin: /\}\}/ },
						{ begin: '""' },
						l,
					],
				});
			(r.contains = [
				o,
				c,
				s,
				e.APOS_STRING_MODE,
				e.QUOTE_STRING_MODE,
				i,
				e.C_BLOCK_COMMENT_MODE,
			]),
				(l.contains = [
					d,
					c,
					t,
					e.APOS_STRING_MODE,
					e.QUOTE_STRING_MODE,
					i,
					e.inherit(e.C_BLOCK_COMMENT_MODE, {
						illegal: /\n/,
					}),
				]);
			const g = {
					variants: [
						o,
						c,
						s,
						e.APOS_STRING_MODE,
						e.QUOTE_STRING_MODE,
					],
				},
				E = {
					begin: '<',
					end: '>',
					contains: [{ beginKeywords: 'in out' }, a],
				},
				_ =
					e.IDENT_RE +
					'(<' +
					e.IDENT_RE +
					'(\\s*,\\s*' +
					e.IDENT_RE +
					')*>)?(\\[\\])?',
				b = {
					begin: '@' + e.IDENT_RE,
					relevance: 0,
				};
			return {
				name: 'C#',
				aliases: ['cs', 'c#'],
				keywords: n,
				illegal: /::/,
				contains: [
					e.COMMENT('///', '$', {
						returnBegin: !0,
						contains: [
							{
								className: 'doctag',
								variants: [
									{ begin: '///', relevance: 0 },
									{
										begin: '\x3c!--|--\x3e',
									},
									{ begin: '</?', end: '>' },
								],
							},
						],
					}),
					e.C_LINE_COMMENT_MODE,
					e.C_BLOCK_COMMENT_MODE,
					{
						className: 'meta',
						begin: '#',
						end: '$',
						keywords: {
							keyword:
								'if else elif endif define undef warning error line region endregion pragma checksum',
						},
					},
					g,
					i,
					{
						beginKeywords: 'class interface',
						relevance: 0,
						end: /[{;=]/,
						illegal: /[^\s:,]/,
						contains: [
							{ beginKeywords: 'where class' },
							a,
							E,
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					{
						beginKeywords: 'namespace',
						relevance: 0,
						end: /[{;=]/,
						illegal: /[^\s:]/,
						contains: [
							a,
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					{
						beginKeywords: 'record',
						relevance: 0,
						end: /[{;=]/,
						illegal: /[^\s:]/,
						contains: [
							a,
							E,
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					{
						className: 'meta',
						begin: '^\\s*\\[(?=[\\w])',
						excludeBegin: !0,
						end: '\\]',
						excludeEnd: !0,
						contains: [
							{
								className: 'string',
								begin: /"/,
								end: /"/,
							},
						],
					},
					{
						beginKeywords: 'new return throw await else',
						relevance: 0,
					},
					{
						className: 'function',
						begin:
							'(' +
							_ +
							'\\s+)+' +
							e.IDENT_RE +
							'\\s*(<[^=]+>\\s*)?\\(',
						returnBegin: !0,
						end: /\s*[{;=]/,
						excludeEnd: !0,
						keywords: n,
						contains: [
							{
								beginKeywords:
									'public private protected static internal protected abstract async extern override unsafe virtual new sealed partial',
								relevance: 0,
							},
							{
								begin: e.IDENT_RE + '\\s*(<[^=]+>\\s*)?\\(',
								returnBegin: !0,
								contains: [e.TITLE_MODE, E],
								relevance: 0,
							},
							{ match: /\(\)/ },
							{
								className: 'params',
								begin: /\(/,
								end: /\)/,
								excludeBegin: !0,
								excludeEnd: !0,
								keywords: n,
								relevance: 0,
								contains: [g, i, e.C_BLOCK_COMMENT_MODE],
							},
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					b,
				],
			};
		};
	})();
	hljs.registerLanguage('csharp', e);
})(); /*! `nix` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = {
					keyword: [
						'rec',
						'with',
						'let',
						'in',
						'inherit',
						'assert',
						'if',
						'else',
						'then',
					],
					literal: ['true', 'false', 'or', 'and', 'null'],
					built_in: [
						'import',
						'abort',
						'baseNameOf',
						'dirOf',
						'isNull',
						'builtins',
						'map',
						'removeAttrs',
						'throw',
						'toString',
						'derivation',
					],
				},
				i = {
					className: 'subst',
					begin: /\$\{/,
					end: /\}/,
					keywords: n,
				},
				s = {
					className: 'string',
					contains: [i],
					variants: [
						{ begin: "''", end: "''" },
						{ begin: '"', end: '"' },
					],
				},
				t = [
					e.NUMBER_MODE,
					e.HASH_COMMENT_MODE,
					e.C_BLOCK_COMMENT_MODE,
					s,
					{
						begin: /[a-zA-Z0-9-_]+(\s*=)/,
						returnBegin: !0,
						relevance: 0,
						contains: [
							{
								className: 'attr',
								begin: /\S+/,
								relevance: 0.2,
							},
						],
					},
				];
			return (
				(i.contains = t),
				{ name: 'Nix', aliases: ['nixos'], keywords: n, contains: t }
			);
		};
	})();
	hljs.registerLanguage('nix', e);
})(); /*! `rust` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const t = e.regex,
				a = {
					className: 'title.function.invoke',
					relevance: 0,
					begin: t.concat(
						/\b/,
						/(?!let\b)/,
						e.IDENT_RE,
						t.lookahead(/\s*\(/),
					),
				},
				n = '([ui](8|16|32|64|128|size)|f(32|64))?',
				s = [
					'drop ',
					'Copy',
					'Send',
					'Sized',
					'Sync',
					'Drop',
					'Fn',
					'FnMut',
					'FnOnce',
					'ToOwned',
					'Clone',
					'Debug',
					'PartialEq',
					'PartialOrd',
					'Eq',
					'Ord',
					'AsRef',
					'AsMut',
					'Into',
					'From',
					'Default',
					'Iterator',
					'Extend',
					'IntoIterator',
					'DoubleEndedIterator',
					'ExactSizeIterator',
					'SliceConcatExt',
					'ToString',
					'assert!',
					'assert_eq!',
					'bitflags!',
					'bytes!',
					'cfg!',
					'col!',
					'concat!',
					'concat_idents!',
					'debug_assert!',
					'debug_assert_eq!',
					'env!',
					'panic!',
					'file!',
					'format!',
					'format_args!',
					'include_bytes!',
					'include_str!',
					'line!',
					'local_data_key!',
					'module_path!',
					'option_env!',
					'print!',
					'println!',
					'select!',
					'stringify!',
					'try!',
					'unimplemented!',
					'unreachable!',
					'vec!',
					'write!',
					'writeln!',
					'macro_rules!',
					'assert_ne!',
					'debug_assert_ne!',
				],
				r = [
					'i8',
					'i16',
					'i32',
					'i64',
					'i128',
					'isize',
					'u8',
					'u16',
					'u32',
					'u64',
					'u128',
					'usize',
					'f32',
					'f64',
					'str',
					'char',
					'bool',
					'Box',
					'Option',
					'Result',
					'String',
					'Vec',
				];
			return {
				name: 'Rust',
				aliases: ['rs'],
				keywords: {
					$pattern: e.IDENT_RE + '!?',
					type: r,
					keyword: [
						'abstract',
						'as',
						'async',
						'await',
						'become',
						'box',
						'break',
						'const',
						'continue',
						'crate',
						'do',
						'dyn',
						'else',
						'enum',
						'extern',
						'false',
						'final',
						'fn',
						'for',
						'if',
						'impl',
						'in',
						'let',
						'loop',
						'macro',
						'match',
						'mod',
						'move',
						'mut',
						'override',
						'priv',
						'pub',
						'ref',
						'return',
						'self',
						'Self',
						'static',
						'struct',
						'super',
						'trait',
						'true',
						'try',
						'type',
						'typeof',
						'unsafe',
						'unsized',
						'use',
						'virtual',
						'where',
						'while',
						'yield',
					],
					literal: ['true', 'false', 'Some', 'None', 'Ok', 'Err'],
					built_in: s,
				},
				illegal: '</',
				contains: [
					e.C_LINE_COMMENT_MODE,
					e.COMMENT('/\\*', '\\*/', { contains: ['self'] }),
					e.inherit(e.QUOTE_STRING_MODE, {
						begin: /b?"/,
						illegal: null,
					}),
					{
						className: 'string',
						variants: [
							{ begin: /b?r(#*)"(.|\n)*?"\1(?!#)/ },
							{
								begin: /b?'\\?(x\w{2}|u\w{4}|U\w{8}|.)'/,
							},
						],
					},
					{ className: 'symbol', begin: /'[a-zA-Z_][a-zA-Z0-9_]*/ },
					{
						className: 'number',
						variants: [
							{
								begin: '\\b0b([01_]+)' + n,
							},
							{ begin: '\\b0o([0-7_]+)' + n },
							{
								begin: '\\b0x([A-Fa-f0-9_]+)' + n,
							},
							{
								begin:
									'\\b(\\d[\\d_]*(\\.[0-9_]+)?([eE][+-]?[0-9_]+)?)' +
									n,
							},
						],
						relevance: 0,
					},
					{
						begin: [/fn/, /\s+/, e.UNDERSCORE_IDENT_RE],
						className: { 1: 'keyword', 3: 'title.function' },
					},
					{
						className: 'meta',
						begin: '#!?\\[',
						end: '\\]',
						contains: [
							{
								className: 'string',
								begin: /"/,
								end: /"/,
							},
						],
					},
					{
						begin: [
							/let/,
							/\s+/,
							/(?:mut\s+)?/,
							e.UNDERSCORE_IDENT_RE,
						],
						className: {
							1: 'keyword',
							3: 'keyword',
							4: 'variable',
						},
					},
					{
						begin: [
							/for/,
							/\s+/,
							e.UNDERSCORE_IDENT_RE,
							/\s+/,
							/in/,
						],
						className: {
							1: 'keyword',
							3: 'variable',
							5: 'keyword',
						},
					},
					{
						begin: [/type/, /\s+/, e.UNDERSCORE_IDENT_RE],
						className: { 1: 'keyword', 3: 'title.class' },
					},
					{
						begin: [
							/(?:trait|enum|struct|union|impl|for)/,
							/\s+/,
							e.UNDERSCORE_IDENT_RE,
						],
						className: { 1: 'keyword', 3: 'title.class' },
					},
					{
						begin: e.IDENT_RE + '::',
						keywords: {
							keyword: 'Self',
							built_in: s,
							type: r,
						},
					},
					{ className: 'punctuation', begin: '->' },
					a,
				],
			};
		};
	})();
	hljs.registerLanguage('rust', e);
})(); /*! `go` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = {
				keyword: [
					'break',
					'case',
					'chan',
					'const',
					'continue',
					'default',
					'defer',
					'else',
					'fallthrough',
					'for',
					'func',
					'go',
					'goto',
					'if',
					'import',
					'interface',
					'map',
					'package',
					'range',
					'return',
					'select',
					'struct',
					'switch',
					'type',
					'var',
				],
				type: [
					'bool',
					'byte',
					'complex64',
					'complex128',
					'error',
					'float32',
					'float64',
					'int8',
					'int16',
					'int32',
					'int64',
					'string',
					'uint8',
					'uint16',
					'uint32',
					'uint64',
					'int',
					'uint',
					'uintptr',
					'rune',
				],
				literal: ['true', 'false', 'iota', 'nil'],
				built_in: [
					'append',
					'cap',
					'close',
					'complex',
					'copy',
					'imag',
					'len',
					'make',
					'new',
					'panic',
					'print',
					'println',
					'real',
					'recover',
					'delete',
				],
			};
			return {
				name: 'Go',
				aliases: ['golang'],
				keywords: n,
				illegal: '</',
				contains: [
					e.C_LINE_COMMENT_MODE,
					e.C_BLOCK_COMMENT_MODE,
					{
						className: 'string',
						variants: [
							e.QUOTE_STRING_MODE,
							e.APOS_STRING_MODE,
							{ begin: '`', end: '`' },
						],
					},
					{
						className: 'number',
						variants: [
							{ begin: e.C_NUMBER_RE + '[i]', relevance: 1 },
							e.C_NUMBER_MODE,
						],
					},
					{ begin: /:=/ },
					{
						className: 'function',
						beginKeywords: 'func',
						end: '\\s*(\\{|$)',
						excludeEnd: !0,
						contains: [
							e.TITLE_MODE,
							{
								className: 'params',
								begin: /\(/,
								end: /\)/,
								endsParent: !0,
								keywords: n,
								illegal: /["']/,
							},
						],
					},
				],
			};
		};
	})();
	hljs.registerLanguage('go', e);
})(); /*! `wasm` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			e.regex;
			const a = e.COMMENT(/\(;/, /;\)/);
			return (
				a.contains.push('self'),
				{
					name: 'WebAssembly',
					keywords: {
						$pattern: /[\w.]+/,
						keyword: [
							'anyfunc',
							'block',
							'br',
							'br_if',
							'br_table',
							'call',
							'call_indirect',
							'data',
							'drop',
							'elem',
							'else',
							'end',
							'export',
							'func',
							'global.get',
							'global.set',
							'local.get',
							'local.set',
							'local.tee',
							'get_global',
							'get_local',
							'global',
							'if',
							'import',
							'local',
							'loop',
							'memory',
							'memory.grow',
							'memory.size',
							'module',
							'mut',
							'nop',
							'offset',
							'param',
							'result',
							'return',
							'select',
							'set_global',
							'set_local',
							'start',
							'table',
							'tee_local',
							'then',
							'type',
							'unreachable',
						],
					},
					contains: [
						e.COMMENT(/;;/, /$/),
						a,
						{
							match: [/(?:offset|align)/, /\s*/, /=/],
							className: { 1: 'keyword', 3: 'operator' },
						},
						{ className: 'variable', begin: /\$[\w_]+/ },
						{
							match: /(\((?!;)|\))+/,
							className: 'punctuation',
							relevance: 0,
						},
						{
							begin: [
								/(?:func|call|call_indirect)/,
								/\s+/,
								/\$[^\s)]+/,
							],
							className: { 1: 'keyword', 3: 'title.function' },
						},
						e.QUOTE_STRING_MODE,
						{ match: /(i32|i64|f32|f64)(?!\.)/, className: 'type' },
						{
							className: 'keyword',
							match: /\b(f32|f64|i32|i64)(?:\.(?:abs|add|and|ceil|clz|const|convert_[su]\/i(?:32|64)|copysign|ctz|demote\/f64|div(?:_[su])?|eqz?|extend_[su]\/i32|floor|ge(?:_[su])?|gt(?:_[su])?|le(?:_[su])?|load(?:(?:8|16|32)_[su])?|lt(?:_[su])?|max|min|mul|nearest|neg?|or|popcnt|promote\/f32|reinterpret\/[fi](?:32|64)|rem_[su]|rot[lr]|shl|shr_[su]|store(?:8|16|32)?|sqrt|sub|trunc(?:_[su]\/f(?:32|64))?|wrap\/i64|xor))\b/,
						},
						{
							className: 'number',
							relevance: 0,
							match: /[+-]?\b(?:\d(?:_?\d)*(?:\.\d(?:_?\d)*)?(?:[eE][+-]?\d(?:_?\d)*)?|0x[\da-fA-F](?:_?[\da-fA-F])*(?:\.[\da-fA-F](?:_?[\da-fA-D])*)?(?:[pP][+-]?\d(?:_?\d)*)?)\b|\binf\b|\bnan(?::0x[\da-fA-F](?:_?[\da-fA-D])*)?\b/,
						},
					],
				}
			);
		};
	})();
	hljs.registerLanguage('wasm', e);
})(); /*! `markdown` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = {
					begin: /<\/?[A-Za-z_]/,
					end: '>',
					subLanguage: 'xml',
					relevance: 0,
				},
				a = {
					variants: [
						{ begin: /\[.+?\]\[.*?\]/, relevance: 0 },
						{
							begin: /\[.+?\]\(((data|javascript|mailto):|(?:http|ftp)s?:\/\/).*?\)/,
							relevance: 2,
						},
						{
							begin: e.regex.concat(
								/\[.+?\]\(/,
								/[A-Za-z][A-Za-z0-9+.-]*/,
								/:\/\/.*?\)/,
							),
							relevance: 2,
						},
						{ begin: /\[.+?\]\([./?&#].*?\)/, relevance: 1 },
						{
							begin: /\[.*?\]\(.*?\)/,
							relevance: 0,
						},
					],
					returnBegin: !0,
					contains: [
						{ match: /\[(?=\])/ },
						{
							className: 'string',
							relevance: 0,
							begin: '\\[',
							end: '\\]',
							excludeBegin: !0,
							returnEnd: !0,
						},
						{
							className: 'link',
							relevance: 0,
							begin: '\\]\\(',
							end: '\\)',
							excludeBegin: !0,
							excludeEnd: !0,
						},
						{
							className: 'symbol',
							relevance: 0,
							begin: '\\]\\[',
							end: '\\]',
							excludeBegin: !0,
							excludeEnd: !0,
						},
					],
				},
				i = {
					className: 'strong',
					contains: [],
					variants: [
						{ begin: /_{2}/, end: /_{2}/ },
						{ begin: /\*{2}/, end: /\*{2}/ },
					],
				},
				s = {
					className: 'emphasis',
					contains: [],
					variants: [
						{ begin: /\*(?!\*)/, end: /\*/ },
						{
							begin: /_(?!_)/,
							end: /_/,
							relevance: 0,
						},
					],
				},
				c = e.inherit(i, { contains: [] }),
				t = e.inherit(s, { contains: [] });
			i.contains.push(t), s.contains.push(c);
			let g = [n, a];
			return (
				[i, s, c, t].forEach((e) => {
					e.contains = e.contains.concat(g);
				}),
				(g = g.concat(i, s)),
				{
					name: 'Markdown',
					aliases: ['md', 'mkdown', 'mkd'],
					contains: [
						{
							className: 'section',
							variants: [
								{ begin: '^#{1,6}', end: '$', contains: g },
								{
									begin: '(?=^.+?\\n[=-]{2,}$)',
									contains: [
										{ begin: '^[=-]*$' },
										{ begin: '^', end: '\\n', contains: g },
									],
								},
							],
						},
						n,
						{
							className: 'bullet',
							begin: '^[ \t]*([*+-]|(\\d+\\.))(?=\\s+)',
							end: '\\s+',
							excludeEnd: !0,
						},
						i,
						s,
						{
							className: 'quote',
							begin: '^>\\s+',
							contains: g,
							end: '$',
						},
						{
							className: 'code',
							variants: [
								{ begin: '(`{3,})[^`](.|\\n)*?\\1`*[ ]*' },
								{
									begin: '(~{3,})[^~](.|\\n)*?\\1~*[ ]*',
								},
								{ begin: '```', end: '```+[ ]*$' },
								{
									begin: '~~~',
									end: '~~~+[ ]*$',
								},
								{ begin: '`.+?`' },
								{
									begin: '(?=^( {4}|\\t))',
									contains: [
										{ begin: '^( {4}|\\t)', end: '(\\n)$' },
									],
									relevance: 0,
								},
							],
						},
						{
							begin: '^[-\\*]{3,}',
							end: '$',
						},
						a,
						{
							begin: /^\[[^\n]+\]:/,
							returnBegin: !0,
							contains: [
								{
									className: 'symbol',
									begin: /\[/,
									end: /\]/,
									excludeBegin: !0,
									excludeEnd: !0,
								},
								{
									className: 'link',
									begin: /:\s*/,
									end: /$/,
									excludeBegin: !0,
								},
							],
						},
					],
				}
			);
		};
	})();
	hljs.registerLanguage('markdown', e);
})(); /*! `css` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		const e = [
				'a',
				'abbr',
				'address',
				'article',
				'aside',
				'audio',
				'b',
				'blockquote',
				'body',
				'button',
				'canvas',
				'caption',
				'cite',
				'code',
				'dd',
				'del',
				'details',
				'dfn',
				'div',
				'dl',
				'dt',
				'em',
				'fieldset',
				'figcaption',
				'figure',
				'footer',
				'form',
				'h1',
				'h2',
				'h3',
				'h4',
				'h5',
				'h6',
				'header',
				'hgroup',
				'html',
				'i',
				'iframe',
				'img',
				'input',
				'ins',
				'kbd',
				'label',
				'legend',
				'li',
				'main',
				'mark',
				'menu',
				'nav',
				'object',
				'ol',
				'p',
				'q',
				'quote',
				'samp',
				'section',
				'span',
				'strong',
				'summary',
				'sup',
				'table',
				'tbody',
				'td',
				'textarea',
				'tfoot',
				'th',
				'thead',
				'time',
				'tr',
				'ul',
				'var',
				'video',
			],
			i = [
				'any-hover',
				'any-pointer',
				'aspect-ratio',
				'color',
				'color-gamut',
				'color-index',
				'device-aspect-ratio',
				'device-height',
				'device-width',
				'display-mode',
				'forced-colors',
				'grid',
				'height',
				'hover',
				'inverted-colors',
				'monochrome',
				'orientation',
				'overflow-block',
				'overflow-inline',
				'pointer',
				'prefers-color-scheme',
				'prefers-contrast',
				'prefers-reduced-motion',
				'prefers-reduced-transparency',
				'resolution',
				'scan',
				'scripting',
				'update',
				'width',
				'min-width',
				'max-width',
				'min-height',
				'max-height',
			],
			r = [
				'active',
				'any-link',
				'blank',
				'checked',
				'current',
				'default',
				'defined',
				'dir',
				'disabled',
				'drop',
				'empty',
				'enabled',
				'first',
				'first-child',
				'first-of-type',
				'fullscreen',
				'future',
				'focus',
				'focus-visible',
				'focus-within',
				'has',
				'host',
				'host-context',
				'hover',
				'indeterminate',
				'in-range',
				'invalid',
				'is',
				'lang',
				'last-child',
				'last-of-type',
				'left',
				'link',
				'local-link',
				'not',
				'nth-child',
				'nth-col',
				'nth-last-child',
				'nth-last-col',
				'nth-last-of-type',
				'nth-of-type',
				'only-child',
				'only-of-type',
				'optional',
				'out-of-range',
				'past',
				'placeholder-shown',
				'read-only',
				'read-write',
				'required',
				'right',
				'root',
				'scope',
				'target',
				'target-within',
				'user-invalid',
				'valid',
				'visited',
				'where',
			],
			t = [
				'after',
				'backdrop',
				'before',
				'cue',
				'cue-region',
				'first-letter',
				'first-line',
				'grammar-error',
				'marker',
				'part',
				'placeholder',
				'selection',
				'slotted',
				'spelling-error',
			],
			o = [
				'align-content',
				'align-items',
				'align-self',
				'all',
				'animation',
				'animation-delay',
				'animation-direction',
				'animation-duration',
				'animation-fill-mode',
				'animation-iteration-count',
				'animation-name',
				'animation-play-state',
				'animation-timing-function',
				'backface-visibility',
				'background',
				'background-attachment',
				'background-blend-mode',
				'background-clip',
				'background-color',
				'background-image',
				'background-origin',
				'background-position',
				'background-repeat',
				'background-size',
				'block-size',
				'border',
				'border-block',
				'border-block-color',
				'border-block-end',
				'border-block-end-color',
				'border-block-end-style',
				'border-block-end-width',
				'border-block-start',
				'border-block-start-color',
				'border-block-start-style',
				'border-block-start-width',
				'border-block-style',
				'border-block-width',
				'border-bottom',
				'border-bottom-color',
				'border-bottom-left-radius',
				'border-bottom-right-radius',
				'border-bottom-style',
				'border-bottom-width',
				'border-collapse',
				'border-color',
				'border-image',
				'border-image-outset',
				'border-image-repeat',
				'border-image-slice',
				'border-image-source',
				'border-image-width',
				'border-inline',
				'border-inline-color',
				'border-inline-end',
				'border-inline-end-color',
				'border-inline-end-style',
				'border-inline-end-width',
				'border-inline-start',
				'border-inline-start-color',
				'border-inline-start-style',
				'border-inline-start-width',
				'border-inline-style',
				'border-inline-width',
				'border-left',
				'border-left-color',
				'border-left-style',
				'border-left-width',
				'border-radius',
				'border-right',
				'border-right-color',
				'border-right-style',
				'border-right-width',
				'border-spacing',
				'border-style',
				'border-top',
				'border-top-color',
				'border-top-left-radius',
				'border-top-right-radius',
				'border-top-style',
				'border-top-width',
				'border-width',
				'bottom',
				'box-decoration-break',
				'box-shadow',
				'box-sizing',
				'break-after',
				'break-before',
				'break-inside',
				'caption-side',
				'caret-color',
				'clear',
				'clip',
				'clip-path',
				'clip-rule',
				'color',
				'column-count',
				'column-fill',
				'column-gap',
				'column-rule',
				'column-rule-color',
				'column-rule-style',
				'column-rule-width',
				'column-span',
				'column-width',
				'columns',
				'contain',
				'content',
				'content-visibility',
				'counter-increment',
				'counter-reset',
				'cue',
				'cue-after',
				'cue-before',
				'cursor',
				'direction',
				'display',
				'empty-cells',
				'filter',
				'flex',
				'flex-basis',
				'flex-direction',
				'flex-flow',
				'flex-grow',
				'flex-shrink',
				'flex-wrap',
				'float',
				'flow',
				'font',
				'font-display',
				'font-family',
				'font-feature-settings',
				'font-kerning',
				'font-language-override',
				'font-size',
				'font-size-adjust',
				'font-smoothing',
				'font-stretch',
				'font-style',
				'font-synthesis',
				'font-variant',
				'font-variant-caps',
				'font-variant-east-asian',
				'font-variant-ligatures',
				'font-variant-numeric',
				'font-variant-position',
				'font-variation-settings',
				'font-weight',
				'gap',
				'glyph-orientation-vertical',
				'grid',
				'grid-area',
				'grid-auto-columns',
				'grid-auto-flow',
				'grid-auto-rows',
				'grid-column',
				'grid-column-end',
				'grid-column-start',
				'grid-gap',
				'grid-row',
				'grid-row-end',
				'grid-row-start',
				'grid-template',
				'grid-template-areas',
				'grid-template-columns',
				'grid-template-rows',
				'hanging-punctuation',
				'height',
				'hyphens',
				'icon',
				'image-orientation',
				'image-rendering',
				'image-resolution',
				'ime-mode',
				'inline-size',
				'isolation',
				'justify-content',
				'left',
				'letter-spacing',
				'line-break',
				'line-height',
				'list-style',
				'list-style-image',
				'list-style-position',
				'list-style-type',
				'margin',
				'margin-block',
				'margin-block-end',
				'margin-block-start',
				'margin-bottom',
				'margin-inline',
				'margin-inline-end',
				'margin-inline-start',
				'margin-left',
				'margin-right',
				'margin-top',
				'marks',
				'mask',
				'mask-border',
				'mask-border-mode',
				'mask-border-outset',
				'mask-border-repeat',
				'mask-border-slice',
				'mask-border-source',
				'mask-border-width',
				'mask-clip',
				'mask-composite',
				'mask-image',
				'mask-mode',
				'mask-origin',
				'mask-position',
				'mask-repeat',
				'mask-size',
				'mask-type',
				'max-block-size',
				'max-height',
				'max-inline-size',
				'max-width',
				'min-block-size',
				'min-height',
				'min-inline-size',
				'min-width',
				'mix-blend-mode',
				'nav-down',
				'nav-index',
				'nav-left',
				'nav-right',
				'nav-up',
				'none',
				'normal',
				'object-fit',
				'object-position',
				'opacity',
				'order',
				'orphans',
				'outline',
				'outline-color',
				'outline-offset',
				'outline-style',
				'outline-width',
				'overflow',
				'overflow-wrap',
				'overflow-x',
				'overflow-y',
				'padding',
				'padding-block',
				'padding-block-end',
				'padding-block-start',
				'padding-bottom',
				'padding-inline',
				'padding-inline-end',
				'padding-inline-start',
				'padding-left',
				'padding-right',
				'padding-top',
				'page-break-after',
				'page-break-before',
				'page-break-inside',
				'pause',
				'pause-after',
				'pause-before',
				'perspective',
				'perspective-origin',
				'pointer-events',
				'position',
				'quotes',
				'resize',
				'rest',
				'rest-after',
				'rest-before',
				'right',
				'row-gap',
				'scroll-margin',
				'scroll-margin-block',
				'scroll-margin-block-end',
				'scroll-margin-block-start',
				'scroll-margin-bottom',
				'scroll-margin-inline',
				'scroll-margin-inline-end',
				'scroll-margin-inline-start',
				'scroll-margin-left',
				'scroll-margin-right',
				'scroll-margin-top',
				'scroll-padding',
				'scroll-padding-block',
				'scroll-padding-block-end',
				'scroll-padding-block-start',
				'scroll-padding-bottom',
				'scroll-padding-inline',
				'scroll-padding-inline-end',
				'scroll-padding-inline-start',
				'scroll-padding-left',
				'scroll-padding-right',
				'scroll-padding-top',
				'scroll-snap-align',
				'scroll-snap-stop',
				'scroll-snap-type',
				'scrollbar-color',
				'scrollbar-gutter',
				'scrollbar-width',
				'shape-image-threshold',
				'shape-margin',
				'shape-outside',
				'speak',
				'speak-as',
				'src',
				'tab-size',
				'table-layout',
				'text-align',
				'text-align-all',
				'text-align-last',
				'text-combine-upright',
				'text-decoration',
				'text-decoration-color',
				'text-decoration-line',
				'text-decoration-style',
				'text-emphasis',
				'text-emphasis-color',
				'text-emphasis-position',
				'text-emphasis-style',
				'text-indent',
				'text-justify',
				'text-orientation',
				'text-overflow',
				'text-rendering',
				'text-shadow',
				'text-transform',
				'text-underline-position',
				'top',
				'transform',
				'transform-box',
				'transform-origin',
				'transform-style',
				'transition',
				'transition-delay',
				'transition-duration',
				'transition-property',
				'transition-timing-function',
				'unicode-bidi',
				'vertical-align',
				'visibility',
				'voice-balance',
				'voice-duration',
				'voice-family',
				'voice-pitch',
				'voice-range',
				'voice-rate',
				'voice-stress',
				'voice-volume',
				'white-space',
				'widows',
				'width',
				'will-change',
				'word-break',
				'word-spacing',
				'word-wrap',
				'writing-mode',
				'z-index',
			].reverse();
		return (n) => {
			const a = n.regex,
				l = ((e) => ({
					IMPORTANT: { scope: 'meta', begin: '!important' },
					BLOCK_COMMENT: e.C_BLOCK_COMMENT_MODE,
					HEXCOLOR: {
						scope: 'number',
						begin: /#(([0-9a-fA-F]{3,4})|(([0-9a-fA-F]{2}){3,4}))\b/,
					},
					FUNCTION_DISPATCH: {
						className: 'built_in',
						begin: /[\w-]+(?=\()/,
					},
					ATTRIBUTE_SELECTOR_MODE: {
						scope: 'selector-attr',
						begin: /\[/,
						end: /\]/,
						illegal: '$',
						contains: [e.APOS_STRING_MODE, e.QUOTE_STRING_MODE],
					},
					CSS_NUMBER_MODE: {
						scope: 'number',
						begin:
							e.NUMBER_RE +
							'(%|em|ex|ch|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc|px|deg|grad|rad|turn|s|ms|Hz|kHz|dpi|dpcm|dppx)?',
						relevance: 0,
					},
					CSS_VARIABLE: {
						className: 'attr',
						begin: /--[A-Za-z][A-Za-z0-9_-]*/,
					},
				}))(n),
				s = [n.APOS_STRING_MODE, n.QUOTE_STRING_MODE];
			return {
				name: 'CSS',
				case_insensitive: !0,
				illegal: /[=|'\$]/,
				keywords: { keyframePosition: 'from to' },
				classNameAliases: { keyframePosition: 'selector-tag' },
				contains: [
					l.BLOCK_COMMENT,
					{
						begin: /-(webkit|moz|ms|o)-(?=[a-z])/,
					},
					l.CSS_NUMBER_MODE,
					{
						className: 'selector-id',
						begin: /#[A-Za-z0-9_-]+/,
						relevance: 0,
					},
					{
						className: 'selector-class',
						begin: '\\.[a-zA-Z-][a-zA-Z0-9_-]*',
						relevance: 0,
					},
					l.ATTRIBUTE_SELECTOR_MODE,
					{
						className: 'selector-pseudo',
						variants: [
							{
								begin: ':(' + r.join('|') + ')',
							},
							{ begin: ':(:)?(' + t.join('|') + ')' },
						],
					},
					l.CSS_VARIABLE,
					{
						className: 'attribute',
						begin: '\\b(' + o.join('|') + ')\\b',
					},
					{
						begin: /:/,
						end: /[;}{]/,
						contains: [
							l.BLOCK_COMMENT,
							l.HEXCOLOR,
							l.IMPORTANT,
							l.CSS_NUMBER_MODE,
							...s,
							{
								begin: /(url|data-uri)\(/,
								end: /\)/,
								relevance: 0,
								keywords: { built_in: 'url data-uri' },
								contains: [
									...s,
									{
										className: 'string',
										begin: /[^)]/,
										endsWithParent: !0,
										excludeEnd: !0,
									},
								],
							},
							l.FUNCTION_DISPATCH,
						],
					},
					{
						begin: a.lookahead(/@/),
						end: '[{;]',
						relevance: 0,
						illegal: /:/,
						contains: [
							{
								className: 'keyword',
								begin: /@-?\w[\w]*(-\w+)*/,
							},
							{
								begin: /\s/,
								endsWithParent: !0,
								excludeEnd: !0,
								relevance: 0,
								keywords: {
									$pattern: /[a-z-]+/,
									keyword: 'and or not only',
									attribute: i.join(' '),
								},
								contains: [
									{
										begin: /[a-z-]+(?=:)/,
										className: 'attribute',
									},
									...s,
									l.CSS_NUMBER_MODE,
								],
							},
						],
					},
					{
						className: 'selector-tag',
						begin: '\\b(' + e.join('|') + ')\\b',
					},
				],
			};
		};
	})();
	hljs.registerLanguage('css', e);
})(); /*! `graphql` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const a = e.regex;
			return {
				name: 'GraphQL',
				aliases: ['gql'],
				case_insensitive: !0,
				disableAutodetect: !1,
				keywords: {
					keyword: [
						'query',
						'mutation',
						'subscription',
						'type',
						'input',
						'schema',
						'directive',
						'interface',
						'union',
						'scalar',
						'fragment',
						'enum',
						'on',
					],
					literal: ['true', 'false', 'null'],
				},
				contains: [
					e.HASH_COMMENT_MODE,
					e.QUOTE_STRING_MODE,
					e.NUMBER_MODE,
					{
						scope: 'punctuation',
						match: /[.]{3}/,
						relevance: 0,
					},
					{
						scope: 'punctuation',
						begin: /[\!\(\)\:\=\[\]\{\|\}]{1}/,
						relevance: 0,
					},
					{
						scope: 'variable',
						begin: /\$/,
						end: /\W/,
						excludeEnd: !0,
						relevance: 0,
					},
					{ scope: 'meta', match: /@\w+/, excludeEnd: !0 },
					{
						scope: 'symbol',
						begin: a.concat(
							/[_A-Za-z][_0-9A-Za-z]*/,
							a.lookahead(/\s*:/),
						),
						relevance: 0,
					},
				],
				illegal: [/[;<']/, /BEGIN/],
			};
		};
	})();
	hljs.registerLanguage('graphql', e);
})(); /*! `vbnet` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = e.regex,
				t = /\d{1,2}\/\d{1,2}\/\d{4}/,
				a = /\d{4}-\d{1,2}-\d{1,2}/,
				i = /(\d|1[012])(:\d+){0,2} *(AM|PM)/,
				s = /\d{1,2}(:\d{1,2}){1,2}/,
				r = {
					className: 'literal',
					variants: [
						{ begin: n.concat(/# */, n.either(a, t), / *#/) },
						{
							begin: n.concat(/# */, s, / *#/),
						},
						{ begin: n.concat(/# */, i, / *#/) },
						{
							begin: n.concat(
								/# */,
								n.either(a, t),
								/ +/,
								n.either(i, s),
								/ *#/,
							),
						},
					],
				},
				l = e.COMMENT(/'''/, /$/, {
					contains: [
						{ className: 'doctag', begin: /<\/?/, end: />/ },
					],
				}),
				o = e.COMMENT(null, /$/, {
					variants: [{ begin: /'/ }, { begin: /([\t ]|^)REM(?=\s)/ }],
				});
			return {
				name: 'Visual Basic .NET',
				aliases: ['vb'],
				case_insensitive: !0,
				classNameAliases: { label: 'symbol' },
				keywords: {
					keyword:
						'addhandler alias aggregate ansi as async assembly auto binary by byref byval call case catch class compare const continue custom declare default delegate dim distinct do each equals else elseif end enum erase error event exit explicit finally for friend from function get global goto group handles if implements imports in inherits interface into iterator join key let lib loop me mid module mustinherit mustoverride mybase myclass namespace narrowing new next notinheritable notoverridable of off on operator option optional order overloads overridable overrides paramarray partial preserve private property protected public raiseevent readonly redim removehandler resume return select set shadows shared skip static step stop structure strict sub synclock take text then throw to try unicode until using when where while widening with withevents writeonly yield',
					built_in:
						'addressof and andalso await directcast gettype getxmlnamespace is isfalse isnot istrue like mod nameof new not or orelse trycast typeof xor cbool cbyte cchar cdate cdbl cdec cint clng cobj csbyte cshort csng cstr cuint culng cushort',
					type: 'boolean byte char date decimal double integer long object sbyte short single string uinteger ulong ushort',
					literal: 'true false nothing',
				},
				illegal: '//|\\{|\\}|endif|gosub|variant|wend|^\\$ ',
				contains: [
					{
						className: 'string',
						begin: /"(""|[^/n])"C\b/,
					},
					{
						className: 'string',
						begin: /"/,
						end: /"/,
						illegal: /\n/,
						contains: [{ begin: /""/ }],
					},
					r,
					{
						className: 'number',
						relevance: 0,
						variants: [
							{
								begin: /\b\d[\d_]*((\.[\d_]+(E[+-]?[\d_]+)?)|(E[+-]?[\d_]+))[RFD@!#]?/,
							},
							{ begin: /\b\d[\d_]*((U?[SIL])|[%&])?/ },
							{ begin: /&H[\dA-F_]+((U?[SIL])|[%&])?/ },
							{
								begin: /&O[0-7_]+((U?[SIL])|[%&])?/,
							},
							{ begin: /&B[01_]+((U?[SIL])|[%&])?/ },
						],
					},
					{
						className: 'label',
						begin: /^\w+:/,
					},
					l,
					o,
					{
						className: 'meta',
						begin: /[\t ]*#(const|disable|else|elseif|enable|end|externalsource|if|region)\b/,
						end: /$/,
						keywords: {
							keyword:
								'const disable else elseif enable end externalsource if region then',
						},
						contains: [o],
					},
				],
			};
		};
	})();
	hljs.registerLanguage('vbnet', e);
})(); /*! `sql` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const r = e.regex,
				t = e.COMMENT('--', '$'),
				n = ['true', 'false', 'unknown'],
				a = [
					'bigint',
					'binary',
					'blob',
					'boolean',
					'char',
					'character',
					'clob',
					'date',
					'dec',
					'decfloat',
					'decimal',
					'float',
					'int',
					'integer',
					'interval',
					'nchar',
					'nclob',
					'national',
					'numeric',
					'real',
					'row',
					'smallint',
					'time',
					'timestamp',
					'varchar',
					'varying',
					'varbinary',
				],
				i = [
					'abs',
					'acos',
					'array_agg',
					'asin',
					'atan',
					'avg',
					'cast',
					'ceil',
					'ceiling',
					'coalesce',
					'corr',
					'cos',
					'cosh',
					'count',
					'covar_pop',
					'covar_samp',
					'cume_dist',
					'dense_rank',
					'deref',
					'element',
					'exp',
					'extract',
					'first_value',
					'floor',
					'json_array',
					'json_arrayagg',
					'json_exists',
					'json_object',
					'json_objectagg',
					'json_query',
					'json_table',
					'json_table_primitive',
					'json_value',
					'lag',
					'last_value',
					'lead',
					'listagg',
					'ln',
					'log',
					'log10',
					'lower',
					'max',
					'min',
					'mod',
					'nth_value',
					'ntile',
					'nullif',
					'percent_rank',
					'percentile_cont',
					'percentile_disc',
					'position',
					'position_regex',
					'power',
					'rank',
					'regr_avgx',
					'regr_avgy',
					'regr_count',
					'regr_intercept',
					'regr_r2',
					'regr_slope',
					'regr_sxx',
					'regr_sxy',
					'regr_syy',
					'row_number',
					'sin',
					'sinh',
					'sqrt',
					'stddev_pop',
					'stddev_samp',
					'substring',
					'substring_regex',
					'sum',
					'tan',
					'tanh',
					'translate',
					'translate_regex',
					'treat',
					'trim',
					'trim_array',
					'unnest',
					'upper',
					'value_of',
					'var_pop',
					'var_samp',
					'width_bucket',
				],
				s = [
					'create table',
					'insert into',
					'primary key',
					'foreign key',
					'not null',
					'alter table',
					'add constraint',
					'grouping sets',
					'on overflow',
					'character set',
					'respect nulls',
					'ignore nulls',
					'nulls first',
					'nulls last',
					'depth first',
					'breadth first',
				],
				o = i,
				c = [
					'abs',
					'acos',
					'all',
					'allocate',
					'alter',
					'and',
					'any',
					'are',
					'array',
					'array_agg',
					'array_max_cardinality',
					'as',
					'asensitive',
					'asin',
					'asymmetric',
					'at',
					'atan',
					'atomic',
					'authorization',
					'avg',
					'begin',
					'begin_frame',
					'begin_partition',
					'between',
					'bigint',
					'binary',
					'blob',
					'boolean',
					'both',
					'by',
					'call',
					'called',
					'cardinality',
					'cascaded',
					'case',
					'cast',
					'ceil',
					'ceiling',
					'char',
					'char_length',
					'character',
					'character_length',
					'check',
					'classifier',
					'clob',
					'close',
					'coalesce',
					'collate',
					'collect',
					'column',
					'commit',
					'condition',
					'connect',
					'constraint',
					'contains',
					'convert',
					'copy',
					'corr',
					'corresponding',
					'cos',
					'cosh',
					'count',
					'covar_pop',
					'covar_samp',
					'create',
					'cross',
					'cube',
					'cume_dist',
					'current',
					'current_catalog',
					'current_date',
					'current_default_transform_group',
					'current_path',
					'current_role',
					'current_row',
					'current_schema',
					'current_time',
					'current_timestamp',
					'current_path',
					'current_role',
					'current_transform_group_for_type',
					'current_user',
					'cursor',
					'cycle',
					'date',
					'day',
					'deallocate',
					'dec',
					'decimal',
					'decfloat',
					'declare',
					'default',
					'define',
					'delete',
					'dense_rank',
					'deref',
					'describe',
					'deterministic',
					'disconnect',
					'distinct',
					'double',
					'drop',
					'dynamic',
					'each',
					'element',
					'else',
					'empty',
					'end',
					'end_frame',
					'end_partition',
					'end-exec',
					'equals',
					'escape',
					'every',
					'except',
					'exec',
					'execute',
					'exists',
					'exp',
					'external',
					'extract',
					'false',
					'fetch',
					'filter',
					'first_value',
					'float',
					'floor',
					'for',
					'foreign',
					'frame_row',
					'free',
					'from',
					'full',
					'function',
					'fusion',
					'get',
					'global',
					'grant',
					'group',
					'grouping',
					'groups',
					'having',
					'hold',
					'hour',
					'identity',
					'in',
					'indicator',
					'initial',
					'inner',
					'inout',
					'insensitive',
					'insert',
					'int',
					'integer',
					'intersect',
					'intersection',
					'interval',
					'into',
					'is',
					'join',
					'json_array',
					'json_arrayagg',
					'json_exists',
					'json_object',
					'json_objectagg',
					'json_query',
					'json_table',
					'json_table_primitive',
					'json_value',
					'lag',
					'language',
					'large',
					'last_value',
					'lateral',
					'lead',
					'leading',
					'left',
					'like',
					'like_regex',
					'listagg',
					'ln',
					'local',
					'localtime',
					'localtimestamp',
					'log',
					'log10',
					'lower',
					'match',
					'match_number',
					'match_recognize',
					'matches',
					'max',
					'member',
					'merge',
					'method',
					'min',
					'minute',
					'mod',
					'modifies',
					'module',
					'month',
					'multiset',
					'national',
					'natural',
					'nchar',
					'nclob',
					'new',
					'no',
					'none',
					'normalize',
					'not',
					'nth_value',
					'ntile',
					'null',
					'nullif',
					'numeric',
					'octet_length',
					'occurrences_regex',
					'of',
					'offset',
					'old',
					'omit',
					'on',
					'one',
					'only',
					'open',
					'or',
					'order',
					'out',
					'outer',
					'over',
					'overlaps',
					'overlay',
					'parameter',
					'partition',
					'pattern',
					'per',
					'percent',
					'percent_rank',
					'percentile_cont',
					'percentile_disc',
					'period',
					'portion',
					'position',
					'position_regex',
					'power',
					'precedes',
					'precision',
					'prepare',
					'primary',
					'procedure',
					'ptf',
					'range',
					'rank',
					'reads',
					'real',
					'recursive',
					'ref',
					'references',
					'referencing',
					'regr_avgx',
					'regr_avgy',
					'regr_count',
					'regr_intercept',
					'regr_r2',
					'regr_slope',
					'regr_sxx',
					'regr_sxy',
					'regr_syy',
					'release',
					'result',
					'return',
					'returns',
					'revoke',
					'right',
					'rollback',
					'rollup',
					'row',
					'row_number',
					'rows',
					'running',
					'savepoint',
					'scope',
					'scroll',
					'search',
					'second',
					'seek',
					'select',
					'sensitive',
					'session_user',
					'set',
					'show',
					'similar',
					'sin',
					'sinh',
					'skip',
					'smallint',
					'some',
					'specific',
					'specifictype',
					'sql',
					'sqlexception',
					'sqlstate',
					'sqlwarning',
					'sqrt',
					'start',
					'static',
					'stddev_pop',
					'stddev_samp',
					'submultiset',
					'subset',
					'substring',
					'substring_regex',
					'succeeds',
					'sum',
					'symmetric',
					'system',
					'system_time',
					'system_user',
					'table',
					'tablesample',
					'tan',
					'tanh',
					'then',
					'time',
					'timestamp',
					'timezone_hour',
					'timezone_minute',
					'to',
					'trailing',
					'translate',
					'translate_regex',
					'translation',
					'treat',
					'trigger',
					'trim',
					'trim_array',
					'true',
					'truncate',
					'uescape',
					'union',
					'unique',
					'unknown',
					'unnest',
					'update',
					'upper',
					'user',
					'using',
					'value',
					'values',
					'value_of',
					'var_pop',
					'var_samp',
					'varbinary',
					'varchar',
					'varying',
					'versioning',
					'when',
					'whenever',
					'where',
					'width_bucket',
					'window',
					'with',
					'within',
					'without',
					'year',
					'add',
					'asc',
					'collation',
					'desc',
					'final',
					'first',
					'last',
					'view',
				].filter((e) => !i.includes(e)),
				l = {
					begin: r.concat(/\b/, r.either(...o), /\s*\(/),
					relevance: 0,
					keywords: { built_in: o },
				};
			return {
				name: 'SQL',
				case_insensitive: !0,
				illegal: /[{}]|<\//,
				keywords: {
					$pattern: /\b[\w\.]+/,
					keyword: ((e, { exceptions: r, when: t } = {}) => {
						const n = t;
						return (
							(r = r || []),
							e.map((e) =>
								e.match(/\|\d+$/) || r.includes(e)
									? e
									: n(e)
									? e + '|0'
									: e,
							)
						);
					})(c, { when: (e) => e.length < 3 }),
					literal: n,
					type: a,
					built_in: [
						'current_catalog',
						'current_date',
						'current_default_transform_group',
						'current_path',
						'current_role',
						'current_schema',
						'current_transform_group_for_type',
						'current_user',
						'session_user',
						'system_time',
						'system_user',
						'current_time',
						'localtime',
						'current_timestamp',
						'localtimestamp',
					],
				},
				contains: [
					{
						begin: r.either(...s),
						relevance: 0,
						keywords: {
							$pattern: /[\w\.]+/,
							keyword: c.concat(s),
							literal: n,
							type: a,
						},
					},
					{
						className: 'type',
						begin: r.either(
							'double precision',
							'large object',
							'with timezone',
							'without timezone',
						),
					},
					l,
					{ className: 'variable', begin: /@[a-z0-9]+/ },
					{
						className: 'string',
						variants: [
							{
								begin: /'/,
								end: /'/,
								contains: [{ begin: /''/ }],
							},
						],
					},
					{
						begin: /"/,
						end: /"/,
						contains: [
							{
								begin: /""/,
							},
						],
					},
					e.C_NUMBER_MODE,
					e.C_BLOCK_COMMENT_MODE,
					t,
					{
						className: 'operator',
						begin: /[-+*/=%^~]|&&?|\|\|?|!=?|<(?:=>?|<|>)?|>[>=]?/,
						relevance: 0,
					},
				],
			};
		};
	})();
	hljs.registerLanguage('sql', e);
})(); /*! `json` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const a = ['true', 'false', 'null'],
				n = {
					scope: 'literal',
					beginKeywords: a.join(' '),
				};
			return {
				name: 'JSON',
				keywords: {
					literal: a,
				},
				contains: [
					{
						className: 'attr',
						begin: /"(\\.|[^\\"\r\n])*"(?=\s*:)/,
						relevance: 1.01,
					},
					{
						match: /[{}[\],:]/,
						className: 'punctuation',
						relevance: 0,
					},
					e.QUOTE_STRING_MODE,
					n,
					e.C_NUMBER_MODE,
					e.C_LINE_COMMENT_MODE,
					e.C_BLOCK_COMMENT_MODE,
				],
				illegal: '\\S',
			};
		};
	})();
	hljs.registerLanguage('json', e);
})(); /*! `kotlin` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		var e = '\\.([0-9](_*[0-9])*)',
			n = '[0-9a-fA-F](_*[0-9a-fA-F])*',
			a = {
				className: 'number',
				variants: [
					{
						begin: `(\\b([0-9](_*[0-9])*)((${e})|\\.)?|(${e}))[eE][+-]?([0-9](_*[0-9])*)[fFdD]?\\b`,
					},
					{
						begin: `\\b([0-9](_*[0-9])*)((${e})[fFdD]?\\b|\\.([fFdD]\\b)?)`,
					},
					{
						begin: `(${e})[fFdD]?\\b`,
					},
					{ begin: '\\b([0-9](_*[0-9])*)[fFdD]\\b' },
					{
						begin: `\\b0[xX]((${n})\\.?|(${n})?\\.(${n}))[pP][+-]?([0-9](_*[0-9])*)[fFdD]?\\b`,
					},
					{ begin: '\\b(0|[1-9](_*[0-9])*)[lL]?\\b' },
					{ begin: `\\b0[xX](${n})[lL]?\\b` },
					{
						begin: '\\b0(_*[0-7])*[lL]?\\b',
					},
					{ begin: '\\b0[bB][01](_*[01])*[lL]?\\b' },
				],
				relevance: 0,
			};
		return (e) => {
			const n = {
					keyword:
						'abstract as val var vararg get set class object open private protected public noinline crossinline dynamic final enum if else do while for when throw try catch finally import package is in fun override companion reified inline lateinit init interface annotation data sealed internal infix operator out by constructor super tailrec where const inner suspend typealias external expect actual',
					built_in:
						'Byte Short Char Int Long Boolean Float Double Void Unit Nothing',
					literal: 'true false null',
				},
				i = { className: 'symbol', begin: e.UNDERSCORE_IDENT_RE + '@' },
				s = {
					className: 'subst',
					begin: /\$\{/,
					end: /\}/,
					contains: [e.C_NUMBER_MODE],
				},
				t = {
					className: 'variable',
					begin: '\\$' + e.UNDERSCORE_IDENT_RE,
				},
				r = {
					className: 'string',
					variants: [
						{ begin: '"""', end: '"""(?=[^"])', contains: [t, s] },
						{
							begin: "'",
							end: "'",
							illegal: /\n/,
							contains: [e.BACKSLASH_ESCAPE],
						},
						{
							begin: '"',
							end: '"',
							illegal: /\n/,
							contains: [e.BACKSLASH_ESCAPE, t, s],
						},
					],
				};
			s.contains.push(r);
			const l = {
					className: 'meta',
					begin:
						'@(?:file|property|field|get|set|receiver|param|setparam|delegate)\\s*:(?:\\s*' +
						e.UNDERSCORE_IDENT_RE +
						')?',
				},
				c = {
					className: 'meta',
					begin: '@' + e.UNDERSCORE_IDENT_RE,
					contains: [
						{
							begin: /\(/,
							end: /\)/,
							contains: [
								e.inherit(r, { className: 'string' }),
								'self',
							],
						},
					],
				},
				o = a,
				b = e.COMMENT('/\\*', '\\*/', {
					contains: [e.C_BLOCK_COMMENT_MODE],
				}),
				E = {
					variants: [
						{ className: 'type', begin: e.UNDERSCORE_IDENT_RE },
						{ begin: /\(/, end: /\)/, contains: [] },
					],
				},
				d = E;
			return (
				(d.variants[1].contains = [E]),
				(E.variants[1].contains = [d]),
				{
					name: 'Kotlin',
					aliases: ['kt', 'kts'],
					keywords: n,
					contains: [
						e.COMMENT('/\\*\\*', '\\*/', {
							relevance: 0,
							contains: [
								{ className: 'doctag', begin: '@[A-Za-z]+' },
							],
						}),
						e.C_LINE_COMMENT_MODE,
						b,
						{
							className: 'keyword',
							begin: /\b(break|continue|return|this)\b/,
							starts: {
								contains: [
									{ className: 'symbol', begin: /@\w+/ },
								],
							},
						},
						i,
						l,
						c,
						{
							className: 'function',
							beginKeywords: 'fun',
							end: '[(]|$',
							returnBegin: !0,
							excludeEnd: !0,
							keywords: n,
							relevance: 5,
							contains: [
								{
									begin: e.UNDERSCORE_IDENT_RE + '\\s*\\(',
									returnBegin: !0,
									relevance: 0,
									contains: [e.UNDERSCORE_TITLE_MODE],
								},
								{
									className: 'type',
									begin: /</,
									end: />/,
									keywords: 'reified',
									relevance: 0,
								},
								{
									className: 'params',
									begin: /\(/,
									end: /\)/,
									endsParent: !0,
									keywords: n,
									relevance: 0,
									contains: [
										{
											begin: /:/,
											end: /[=,\/]/,
											endsWithParent: !0,
											contains: [
												E,
												e.C_LINE_COMMENT_MODE,
												b,
											],
											relevance: 0,
										},
										e.C_LINE_COMMENT_MODE,
										b,
										l,
										c,
										r,
										e.C_NUMBER_MODE,
									],
								},
								b,
							],
						},
						{
							begin: [
								/class|interface|trait/,
								/\s+/,
								e.UNDERSCORE_IDENT_RE,
							],
							beginScope: {
								3: 'title.class',
							},
							keywords: 'class interface trait',
							end: /[:\{(]|$/,
							excludeEnd: !0,
							illegal: 'extends implements',
							contains: [
								{
									beginKeywords:
										'public protected internal private constructor',
								},
								e.UNDERSCORE_TITLE_MODE,
								{
									className: 'type',
									begin: /</,
									end: />/,
									excludeBegin: !0,
									excludeEnd: !0,
									relevance: 0,
								},
								{
									className: 'type',
									begin: /[,:]\s*/,
									end: /[<\(,){\s]|$/,
									excludeBegin: !0,
									returnEnd: !0,
								},
								l,
								c,
							],
						},
						r,
						{
							className: 'meta',
							begin: '^#!/usr/bin/env',
							end: '$',
							illegal: '\n',
						},
						o,
					],
				}
			);
		};
	})();
	hljs.registerLanguage('kotlin', e);
})(); /*! `objectivec` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const n = /[a-zA-Z@][a-zA-Z0-9_]*/,
				_ = {
					$pattern: n,
					keyword: [
						'@interface',
						'@class',
						'@protocol',
						'@implementation',
					],
				};
			return {
				name: 'Objective-C',
				aliases: ['mm', 'objc', 'obj-c', 'obj-c++', 'objective-c++'],
				keywords: {
					'variable.language': ['this', 'super'],
					$pattern: n,
					keyword: [
						'while',
						'export',
						'sizeof',
						'typedef',
						'const',
						'struct',
						'for',
						'union',
						'volatile',
						'static',
						'mutable',
						'if',
						'do',
						'return',
						'goto',
						'enum',
						'else',
						'break',
						'extern',
						'asm',
						'case',
						'default',
						'register',
						'explicit',
						'typename',
						'switch',
						'continue',
						'inline',
						'readonly',
						'assign',
						'readwrite',
						'self',
						'@synchronized',
						'id',
						'typeof',
						'nonatomic',
						'IBOutlet',
						'IBAction',
						'strong',
						'weak',
						'copy',
						'in',
						'out',
						'inout',
						'bycopy',
						'byref',
						'oneway',
						'__strong',
						'__weak',
						'__block',
						'__autoreleasing',
						'@private',
						'@protected',
						'@public',
						'@try',
						'@property',
						'@end',
						'@throw',
						'@catch',
						'@finally',
						'@autoreleasepool',
						'@synthesize',
						'@dynamic',
						'@selector',
						'@optional',
						'@required',
						'@encode',
						'@package',
						'@import',
						'@defs',
						'@compatibility_alias',
						'__bridge',
						'__bridge_transfer',
						'__bridge_retained',
						'__bridge_retain',
						'__covariant',
						'__contravariant',
						'__kindof',
						'_Nonnull',
						'_Nullable',
						'_Null_unspecified',
						'__FUNCTION__',
						'__PRETTY_FUNCTION__',
						'__attribute__',
						'getter',
						'setter',
						'retain',
						'unsafe_unretained',
						'nonnull',
						'nullable',
						'null_unspecified',
						'null_resettable',
						'class',
						'instancetype',
						'NS_DESIGNATED_INITIALIZER',
						'NS_UNAVAILABLE',
						'NS_REQUIRES_SUPER',
						'NS_RETURNS_INNER_POINTER',
						'NS_INLINE',
						'NS_AVAILABLE',
						'NS_DEPRECATED',
						'NS_ENUM',
						'NS_OPTIONS',
						'NS_SWIFT_UNAVAILABLE',
						'NS_ASSUME_NONNULL_BEGIN',
						'NS_ASSUME_NONNULL_END',
						'NS_REFINED_FOR_SWIFT',
						'NS_SWIFT_NAME',
						'NS_SWIFT_NOTHROW',
						'NS_DURING',
						'NS_HANDLER',
						'NS_ENDHANDLER',
						'NS_VALUERETURN',
						'NS_VOIDRETURN',
					],
					literal: [
						'false',
						'true',
						'FALSE',
						'TRUE',
						'nil',
						'YES',
						'NO',
						'NULL',
					],
					built_in: [
						'dispatch_once_t',
						'dispatch_queue_t',
						'dispatch_sync',
						'dispatch_async',
						'dispatch_once',
					],
					type: [
						'int',
						'float',
						'char',
						'unsigned',
						'signed',
						'short',
						'long',
						'double',
						'wchar_t',
						'unichar',
						'void',
						'bool',
						'BOOL',
						'id|0',
						'_Bool',
					],
				},
				illegal: '</',
				contains: [
					{
						className: 'built_in',
						begin: '\\b(AV|CA|CF|CG|CI|CL|CM|CN|CT|MK|MP|MTK|MTL|NS|SCN|SK|UI|WK|XC)\\w+',
					},
					e.C_LINE_COMMENT_MODE,
					e.C_BLOCK_COMMENT_MODE,
					e.C_NUMBER_MODE,
					e.QUOTE_STRING_MODE,
					e.APOS_STRING_MODE,
					{
						className: 'string',
						variants: [
							{
								begin: '@"',
								end: '"',
								illegal: '\\n',
								contains: [e.BACKSLASH_ESCAPE],
							},
						],
					},
					{
						className: 'meta',
						begin: /#\s*[a-z]+\b/,
						end: /$/,
						keywords: {
							keyword:
								'if else elif endif define undef warning error line pragma ifdef ifndef include',
						},
						contains: [
							{ begin: /\\\n/, relevance: 0 },
							e.inherit(e.QUOTE_STRING_MODE, {
								className: 'string',
							}),
							{
								className: 'string',
								begin: /<.*?>/,
								end: /$/,
								illegal: '\\n',
							},
							e.C_LINE_COMMENT_MODE,
							e.C_BLOCK_COMMENT_MODE,
						],
					},
					{
						className: 'class',
						begin: '(' + _.keyword.join('|') + ')\\b',
						end: /(\{|$)/,
						excludeEnd: !0,
						keywords: _,
						contains: [e.UNDERSCORE_TITLE_MODE],
					},
					{ begin: '\\.' + e.UNDERSCORE_IDENT_RE, relevance: 0 },
				],
			};
		};
	})();
	hljs.registerLanguage('objectivec', e);
})(); /*! `less` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		const e = [
				'a',
				'abbr',
				'address',
				'article',
				'aside',
				'audio',
				'b',
				'blockquote',
				'body',
				'button',
				'canvas',
				'caption',
				'cite',
				'code',
				'dd',
				'del',
				'details',
				'dfn',
				'div',
				'dl',
				'dt',
				'em',
				'fieldset',
				'figcaption',
				'figure',
				'footer',
				'form',
				'h1',
				'h2',
				'h3',
				'h4',
				'h5',
				'h6',
				'header',
				'hgroup',
				'html',
				'i',
				'iframe',
				'img',
				'input',
				'ins',
				'kbd',
				'label',
				'legend',
				'li',
				'main',
				'mark',
				'menu',
				'nav',
				'object',
				'ol',
				'p',
				'q',
				'quote',
				'samp',
				'section',
				'span',
				'strong',
				'summary',
				'sup',
				'table',
				'tbody',
				'td',
				'textarea',
				'tfoot',
				'th',
				'thead',
				'time',
				'tr',
				'ul',
				'var',
				'video',
			],
			t = [
				'any-hover',
				'any-pointer',
				'aspect-ratio',
				'color',
				'color-gamut',
				'color-index',
				'device-aspect-ratio',
				'device-height',
				'device-width',
				'display-mode',
				'forced-colors',
				'grid',
				'height',
				'hover',
				'inverted-colors',
				'monochrome',
				'orientation',
				'overflow-block',
				'overflow-inline',
				'pointer',
				'prefers-color-scheme',
				'prefers-contrast',
				'prefers-reduced-motion',
				'prefers-reduced-transparency',
				'resolution',
				'scan',
				'scripting',
				'update',
				'width',
				'min-width',
				'max-width',
				'min-height',
				'max-height',
			],
			r = [
				'active',
				'any-link',
				'blank',
				'checked',
				'current',
				'default',
				'defined',
				'dir',
				'disabled',
				'drop',
				'empty',
				'enabled',
				'first',
				'first-child',
				'first-of-type',
				'fullscreen',
				'future',
				'focus',
				'focus-visible',
				'focus-within',
				'has',
				'host',
				'host-context',
				'hover',
				'indeterminate',
				'in-range',
				'invalid',
				'is',
				'lang',
				'last-child',
				'last-of-type',
				'left',
				'link',
				'local-link',
				'not',
				'nth-child',
				'nth-col',
				'nth-last-child',
				'nth-last-col',
				'nth-last-of-type',
				'nth-of-type',
				'only-child',
				'only-of-type',
				'optional',
				'out-of-range',
				'past',
				'placeholder-shown',
				'read-only',
				'read-write',
				'required',
				'right',
				'root',
				'scope',
				'target',
				'target-within',
				'user-invalid',
				'valid',
				'visited',
				'where',
			],
			i = [
				'after',
				'backdrop',
				'before',
				'cue',
				'cue-region',
				'first-letter',
				'first-line',
				'grammar-error',
				'marker',
				'part',
				'placeholder',
				'selection',
				'slotted',
				'spelling-error',
			],
			o = [
				'align-content',
				'align-items',
				'align-self',
				'all',
				'animation',
				'animation-delay',
				'animation-direction',
				'animation-duration',
				'animation-fill-mode',
				'animation-iteration-count',
				'animation-name',
				'animation-play-state',
				'animation-timing-function',
				'backface-visibility',
				'background',
				'background-attachment',
				'background-blend-mode',
				'background-clip',
				'background-color',
				'background-image',
				'background-origin',
				'background-position',
				'background-repeat',
				'background-size',
				'block-size',
				'border',
				'border-block',
				'border-block-color',
				'border-block-end',
				'border-block-end-color',
				'border-block-end-style',
				'border-block-end-width',
				'border-block-start',
				'border-block-start-color',
				'border-block-start-style',
				'border-block-start-width',
				'border-block-style',
				'border-block-width',
				'border-bottom',
				'border-bottom-color',
				'border-bottom-left-radius',
				'border-bottom-right-radius',
				'border-bottom-style',
				'border-bottom-width',
				'border-collapse',
				'border-color',
				'border-image',
				'border-image-outset',
				'border-image-repeat',
				'border-image-slice',
				'border-image-source',
				'border-image-width',
				'border-inline',
				'border-inline-color',
				'border-inline-end',
				'border-inline-end-color',
				'border-inline-end-style',
				'border-inline-end-width',
				'border-inline-start',
				'border-inline-start-color',
				'border-inline-start-style',
				'border-inline-start-width',
				'border-inline-style',
				'border-inline-width',
				'border-left',
				'border-left-color',
				'border-left-style',
				'border-left-width',
				'border-radius',
				'border-right',
				'border-right-color',
				'border-right-style',
				'border-right-width',
				'border-spacing',
				'border-style',
				'border-top',
				'border-top-color',
				'border-top-left-radius',
				'border-top-right-radius',
				'border-top-style',
				'border-top-width',
				'border-width',
				'bottom',
				'box-decoration-break',
				'box-shadow',
				'box-sizing',
				'break-after',
				'break-before',
				'break-inside',
				'caption-side',
				'caret-color',
				'clear',
				'clip',
				'clip-path',
				'clip-rule',
				'color',
				'column-count',
				'column-fill',
				'column-gap',
				'column-rule',
				'column-rule-color',
				'column-rule-style',
				'column-rule-width',
				'column-span',
				'column-width',
				'columns',
				'contain',
				'content',
				'content-visibility',
				'counter-increment',
				'counter-reset',
				'cue',
				'cue-after',
				'cue-before',
				'cursor',
				'direction',
				'display',
				'empty-cells',
				'filter',
				'flex',
				'flex-basis',
				'flex-direction',
				'flex-flow',
				'flex-grow',
				'flex-shrink',
				'flex-wrap',
				'float',
				'flow',
				'font',
				'font-display',
				'font-family',
				'font-feature-settings',
				'font-kerning',
				'font-language-override',
				'font-size',
				'font-size-adjust',
				'font-smoothing',
				'font-stretch',
				'font-style',
				'font-synthesis',
				'font-variant',
				'font-variant-caps',
				'font-variant-east-asian',
				'font-variant-ligatures',
				'font-variant-numeric',
				'font-variant-position',
				'font-variation-settings',
				'font-weight',
				'gap',
				'glyph-orientation-vertical',
				'grid',
				'grid-area',
				'grid-auto-columns',
				'grid-auto-flow',
				'grid-auto-rows',
				'grid-column',
				'grid-column-end',
				'grid-column-start',
				'grid-gap',
				'grid-row',
				'grid-row-end',
				'grid-row-start',
				'grid-template',
				'grid-template-areas',
				'grid-template-columns',
				'grid-template-rows',
				'hanging-punctuation',
				'height',
				'hyphens',
				'icon',
				'image-orientation',
				'image-rendering',
				'image-resolution',
				'ime-mode',
				'inline-size',
				'isolation',
				'justify-content',
				'left',
				'letter-spacing',
				'line-break',
				'line-height',
				'list-style',
				'list-style-image',
				'list-style-position',
				'list-style-type',
				'margin',
				'margin-block',
				'margin-block-end',
				'margin-block-start',
				'margin-bottom',
				'margin-inline',
				'margin-inline-end',
				'margin-inline-start',
				'margin-left',
				'margin-right',
				'margin-top',
				'marks',
				'mask',
				'mask-border',
				'mask-border-mode',
				'mask-border-outset',
				'mask-border-repeat',
				'mask-border-slice',
				'mask-border-source',
				'mask-border-width',
				'mask-clip',
				'mask-composite',
				'mask-image',
				'mask-mode',
				'mask-origin',
				'mask-position',
				'mask-repeat',
				'mask-size',
				'mask-type',
				'max-block-size',
				'max-height',
				'max-inline-size',
				'max-width',
				'min-block-size',
				'min-height',
				'min-inline-size',
				'min-width',
				'mix-blend-mode',
				'nav-down',
				'nav-index',
				'nav-left',
				'nav-right',
				'nav-up',
				'none',
				'normal',
				'object-fit',
				'object-position',
				'opacity',
				'order',
				'orphans',
				'outline',
				'outline-color',
				'outline-offset',
				'outline-style',
				'outline-width',
				'overflow',
				'overflow-wrap',
				'overflow-x',
				'overflow-y',
				'padding',
				'padding-block',
				'padding-block-end',
				'padding-block-start',
				'padding-bottom',
				'padding-inline',
				'padding-inline-end',
				'padding-inline-start',
				'padding-left',
				'padding-right',
				'padding-top',
				'page-break-after',
				'page-break-before',
				'page-break-inside',
				'pause',
				'pause-after',
				'pause-before',
				'perspective',
				'perspective-origin',
				'pointer-events',
				'position',
				'quotes',
				'resize',
				'rest',
				'rest-after',
				'rest-before',
				'right',
				'row-gap',
				'scroll-margin',
				'scroll-margin-block',
				'scroll-margin-block-end',
				'scroll-margin-block-start',
				'scroll-margin-bottom',
				'scroll-margin-inline',
				'scroll-margin-inline-end',
				'scroll-margin-inline-start',
				'scroll-margin-left',
				'scroll-margin-right',
				'scroll-margin-top',
				'scroll-padding',
				'scroll-padding-block',
				'scroll-padding-block-end',
				'scroll-padding-block-start',
				'scroll-padding-bottom',
				'scroll-padding-inline',
				'scroll-padding-inline-end',
				'scroll-padding-inline-start',
				'scroll-padding-left',
				'scroll-padding-right',
				'scroll-padding-top',
				'scroll-snap-align',
				'scroll-snap-stop',
				'scroll-snap-type',
				'scrollbar-color',
				'scrollbar-gutter',
				'scrollbar-width',
				'shape-image-threshold',
				'shape-margin',
				'shape-outside',
				'speak',
				'speak-as',
				'src',
				'tab-size',
				'table-layout',
				'text-align',
				'text-align-all',
				'text-align-last',
				'text-combine-upright',
				'text-decoration',
				'text-decoration-color',
				'text-decoration-line',
				'text-decoration-style',
				'text-emphasis',
				'text-emphasis-color',
				'text-emphasis-position',
				'text-emphasis-style',
				'text-indent',
				'text-justify',
				'text-orientation',
				'text-overflow',
				'text-rendering',
				'text-shadow',
				'text-transform',
				'text-underline-position',
				'top',
				'transform',
				'transform-box',
				'transform-origin',
				'transform-style',
				'transition',
				'transition-delay',
				'transition-duration',
				'transition-property',
				'transition-timing-function',
				'unicode-bidi',
				'vertical-align',
				'visibility',
				'voice-balance',
				'voice-duration',
				'voice-family',
				'voice-pitch',
				'voice-range',
				'voice-rate',
				'voice-stress',
				'voice-volume',
				'white-space',
				'widows',
				'width',
				'will-change',
				'word-break',
				'word-spacing',
				'word-wrap',
				'writing-mode',
				'z-index',
			].reverse(),
			n = r.concat(i);
		return (a) => {
			const l = ((e) => ({
					IMPORTANT: { scope: 'meta', begin: '!important' },
					BLOCK_COMMENT: e.C_BLOCK_COMMENT_MODE,
					HEXCOLOR: {
						scope: 'number',
						begin: /#(([0-9a-fA-F]{3,4})|(([0-9a-fA-F]{2}){3,4}))\b/,
					},
					FUNCTION_DISPATCH: {
						className: 'built_in',
						begin: /[\w-]+(?=\()/,
					},
					ATTRIBUTE_SELECTOR_MODE: {
						scope: 'selector-attr',
						begin: /\[/,
						end: /\]/,
						illegal: '$',
						contains: [e.APOS_STRING_MODE, e.QUOTE_STRING_MODE],
					},
					CSS_NUMBER_MODE: {
						scope: 'number',
						begin:
							e.NUMBER_RE +
							'(%|em|ex|ch|rem|vw|vh|vmin|vmax|cm|mm|in|pt|pc|px|deg|grad|rad|turn|s|ms|Hz|kHz|dpi|dpcm|dppx)?',
						relevance: 0,
					},
					CSS_VARIABLE: {
						className: 'attr',
						begin: /--[A-Za-z][A-Za-z0-9_-]*/,
					},
				}))(a),
				s = n,
				d = '([\\w-]+|@\\{[\\w-]+\\})',
				c = [],
				g = [],
				b = (e) => ({
					className: 'string',
					begin: '~?' + e + '.*?' + e,
				}),
				m = (e, t, r) => ({ className: e, begin: t, relevance: r }),
				p = {
					$pattern: /[a-z-]+/,
					keyword: 'and or not only',
					attribute: t.join(' '),
				},
				u = {
					begin: '\\(',
					end: '\\)',
					contains: g,
					keywords: p,
					relevance: 0,
				};
			g.push(
				a.C_LINE_COMMENT_MODE,
				a.C_BLOCK_COMMENT_MODE,
				b("'"),
				b('"'),
				l.CSS_NUMBER_MODE,
				{
					begin: '(url|data-uri)\\(',
					starts: {
						className: 'string',
						end: '[\\)\\n]',
						excludeEnd: !0,
					},
				},
				l.HEXCOLOR,
				u,
				m('variable', '@@?[\\w-]+', 10),
				m('variable', '@\\{[\\w-]+\\}'),
				m('built_in', '~?`[^`]*?`'),
				{
					className: 'attribute',
					begin: '[\\w-]+\\s*:',
					end: ':',
					returnBegin: !0,
					excludeEnd: !0,
				},
				l.IMPORTANT,
				{ beginKeywords: 'and not' },
				l.FUNCTION_DISPATCH,
			);
			const h = g.concat({
					begin: /\{/,
					end: /\}/,
					contains: c,
				}),
				f = {
					beginKeywords: 'when',
					endsWithParent: !0,
					contains: [{ beginKeywords: 'and not' }].concat(g),
				},
				k = {
					begin: d + '\\s*:',
					returnBegin: !0,
					end: /[;}]/,
					relevance: 0,
					contains: [
						{ begin: /-(webkit|moz|ms|o)-/ },
						l.CSS_VARIABLE,
						{
							className: 'attribute',
							begin: '\\b(' + o.join('|') + ')\\b',
							end: /(?=:)/,
							starts: {
								endsWithParent: !0,
								illegal: '[<=$]',
								relevance: 0,
								contains: g,
							},
						},
					],
				},
				w = {
					className: 'keyword',
					begin: '@(import|media|charset|font-face|(-[a-z]+-)?keyframes|supports|document|namespace|page|viewport|host)\\b',
					starts: {
						end: '[;{}]',
						keywords: p,
						returnEnd: !0,
						contains: g,
						relevance: 0,
					},
				},
				v = {
					className: 'variable',
					variants: [
						{ begin: '@[\\w-]+\\s*:', relevance: 15 },
						{
							begin: '@[\\w-]+',
						},
					],
					starts: { end: '[;}]', returnEnd: !0, contains: h },
				},
				y = {
					variants: [
						{
							begin: '[\\.#:&\\[>]',
							end: '[;{}]',
						},
						{ begin: d, end: /\{/ },
					],
					returnBegin: !0,
					returnEnd: !0,
					illegal: '[<=\'$"]',
					relevance: 0,
					contains: [
						a.C_LINE_COMMENT_MODE,
						a.C_BLOCK_COMMENT_MODE,
						f,
						m('keyword', 'all\\b'),
						m('variable', '@\\{[\\w-]+\\}'),
						{
							begin: '\\b(' + e.join('|') + ')\\b',
							className: 'selector-tag',
						},
						l.CSS_NUMBER_MODE,
						m('selector-tag', d, 0),
						m('selector-id', '#' + d),
						m('selector-class', '\\.' + d, 0),
						m('selector-tag', '&', 0),
						l.ATTRIBUTE_SELECTOR_MODE,
						{
							className: 'selector-pseudo',
							begin: ':(' + r.join('|') + ')',
						},
						{
							className: 'selector-pseudo',
							begin: ':(:)?(' + i.join('|') + ')',
						},
						{ begin: /\(/, end: /\)/, relevance: 0, contains: h },
						{ begin: '!important' },
						l.FUNCTION_DISPATCH,
					],
				},
				x = {
					begin: `[\\w-]+:(:)?(${s.join('|')})`,
					returnBegin: !0,
					contains: [y],
				};
			return (
				c.push(
					a.C_LINE_COMMENT_MODE,
					a.C_BLOCK_COMMENT_MODE,
					w,
					v,
					x,
					k,
					y,
					f,
					l.FUNCTION_DISPATCH,
				),
				{
					name: 'Less',
					case_insensitive: !0,
					illegal: '[=>\'/<($"]',
					contains: c,
				}
			);
		};
	})();
	hljs.registerLanguage('less', e);
})(); /*! `cpp` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const t = e.regex,
				a = e.COMMENT('//', '$', {
					contains: [{ begin: /\\\n/ }],
				}),
				n = '[a-zA-Z_]\\w*::',
				r =
					'(?!struct)(decltype\\(auto\\)|' +
					t.optional(n) +
					'[a-zA-Z_]\\w*' +
					t.optional('<[^<>]+>') +
					')',
				i = {
					className: 'type',
					begin: '\\b[a-z\\d_]*_t\\b',
				},
				s = {
					className: 'string',
					variants: [
						{
							begin: '(u8?|U|L)?"',
							end: '"',
							illegal: '\\n',
							contains: [e.BACKSLASH_ESCAPE],
						},
						{
							begin: "(u8?|U|L)?'(\\\\(x[0-9A-Fa-f]{2}|u[0-9A-Fa-f]{4,8}|[0-7]{3}|\\S)|.)",
							end: "'",
							illegal: '.',
						},
						e.END_SAME_AS_BEGIN({
							begin: /(?:u8?|U|L)?R"([^()\\ ]{0,16})\(/,
							end: /\)([^()\\ ]{0,16})"/,
						}),
					],
				},
				c = {
					className: 'number',
					variants: [
						{ begin: "\\b(0b[01']+)" },
						{
							begin: "(-?)\\b([\\d']+(\\.[\\d']*)?|\\.[\\d']+)((ll|LL|l|L)(u|U)?|(u|U)(ll|LL|l|L)?|f|F|b|B)",
						},
						{
							begin: "(-?)(\\b0[xX][a-fA-F0-9']+|(\\b[\\d']+(\\.[\\d']*)?|\\.[\\d']+)([eE][-+]?[\\d']+)?)",
						},
					],
					relevance: 0,
				},
				o = {
					className: 'meta',
					begin: /#\s*[a-z]+\b/,
					end: /$/,
					keywords: {
						keyword:
							'if else elif endif define undef warning error line pragma _Pragma ifdef ifndef include',
					},
					contains: [
						{ begin: /\\\n/, relevance: 0 },
						e.inherit(s, { className: 'string' }),
						{
							className: 'string',
							begin: /<.*?>/,
						},
						a,
						e.C_BLOCK_COMMENT_MODE,
					],
				},
				l = {
					className: 'title',
					begin: t.optional(n) + e.IDENT_RE,
					relevance: 0,
				},
				d = t.optional(n) + e.IDENT_RE + '\\s*\\(',
				u = {
					type: [
						'bool',
						'char',
						'char16_t',
						'char32_t',
						'char8_t',
						'double',
						'float',
						'int',
						'long',
						'short',
						'void',
						'wchar_t',
						'unsigned',
						'signed',
						'const',
						'static',
					],
					keyword: [
						'alignas',
						'alignof',
						'and',
						'and_eq',
						'asm',
						'atomic_cancel',
						'atomic_commit',
						'atomic_noexcept',
						'auto',
						'bitand',
						'bitor',
						'break',
						'case',
						'catch',
						'class',
						'co_await',
						'co_return',
						'co_yield',
						'compl',
						'concept',
						'const_cast|10',
						'consteval',
						'constexpr',
						'constinit',
						'continue',
						'decltype',
						'default',
						'delete',
						'do',
						'dynamic_cast|10',
						'else',
						'enum',
						'explicit',
						'export',
						'extern',
						'false',
						'final',
						'for',
						'friend',
						'goto',
						'if',
						'import',
						'inline',
						'module',
						'mutable',
						'namespace',
						'new',
						'noexcept',
						'not',
						'not_eq',
						'nullptr',
						'operator',
						'or',
						'or_eq',
						'override',
						'private',
						'protected',
						'public',
						'reflexpr',
						'register',
						'reinterpret_cast|10',
						'requires',
						'return',
						'sizeof',
						'static_assert',
						'static_cast|10',
						'struct',
						'switch',
						'synchronized',
						'template',
						'this',
						'thread_local',
						'throw',
						'transaction_safe',
						'transaction_safe_dynamic',
						'true',
						'try',
						'typedef',
						'typeid',
						'typename',
						'union',
						'using',
						'virtual',
						'volatile',
						'while',
						'xor',
						'xor_eq',
					],
					literal: ['NULL', 'false', 'nullopt', 'nullptr', 'true'],
					built_in: ['_Pragma'],
					_type_hints: [
						'any',
						'auto_ptr',
						'barrier',
						'binary_semaphore',
						'bitset',
						'complex',
						'condition_variable',
						'condition_variable_any',
						'counting_semaphore',
						'deque',
						'false_type',
						'future',
						'imaginary',
						'initializer_list',
						'istringstream',
						'jthread',
						'latch',
						'lock_guard',
						'multimap',
						'multiset',
						'mutex',
						'optional',
						'ostringstream',
						'packaged_task',
						'pair',
						'promise',
						'priority_queue',
						'queue',
						'recursive_mutex',
						'recursive_timed_mutex',
						'scoped_lock',
						'set',
						'shared_future',
						'shared_lock',
						'shared_mutex',
						'shared_timed_mutex',
						'shared_ptr',
						'stack',
						'string_view',
						'stringstream',
						'timed_mutex',
						'thread',
						'true_type',
						'tuple',
						'unique_lock',
						'unique_ptr',
						'unordered_map',
						'unordered_multimap',
						'unordered_multiset',
						'unordered_set',
						'variant',
						'vector',
						'weak_ptr',
						'wstring',
						'wstring_view',
					],
				},
				p = {
					className: 'function.dispatch',
					relevance: 0,
					keywords: {
						_hint: [
							'abort',
							'abs',
							'acos',
							'apply',
							'as_const',
							'asin',
							'atan',
							'atan2',
							'calloc',
							'ceil',
							'cerr',
							'cin',
							'clog',
							'cos',
							'cosh',
							'cout',
							'declval',
							'endl',
							'exchange',
							'exit',
							'exp',
							'fabs',
							'floor',
							'fmod',
							'forward',
							'fprintf',
							'fputs',
							'free',
							'frexp',
							'fscanf',
							'future',
							'invoke',
							'isalnum',
							'isalpha',
							'iscntrl',
							'isdigit',
							'isgraph',
							'islower',
							'isprint',
							'ispunct',
							'isspace',
							'isupper',
							'isxdigit',
							'labs',
							'launder',
							'ldexp',
							'log',
							'log10',
							'make_pair',
							'make_shared',
							'make_shared_for_overwrite',
							'make_tuple',
							'make_unique',
							'malloc',
							'memchr',
							'memcmp',
							'memcpy',
							'memset',
							'modf',
							'move',
							'pow',
							'printf',
							'putchar',
							'puts',
							'realloc',
							'scanf',
							'sin',
							'sinh',
							'snprintf',
							'sprintf',
							'sqrt',
							'sscanf',
							'std',
							'stderr',
							'stdin',
							'stdout',
							'strcat',
							'strchr',
							'strcmp',
							'strcpy',
							'strcspn',
							'strlen',
							'strncat',
							'strncmp',
							'strncpy',
							'strpbrk',
							'strrchr',
							'strspn',
							'strstr',
							'swap',
							'tan',
							'tanh',
							'terminate',
							'to_underlying',
							'tolower',
							'toupper',
							'vfprintf',
							'visit',
							'vprintf',
							'vsprintf',
						],
					},
					begin: t.concat(
						/\b/,
						/(?!decltype)/,
						/(?!if)/,
						/(?!for)/,
						/(?!switch)/,
						/(?!while)/,
						e.IDENT_RE,
						t.lookahead(/(<[^<>]+>|)\s*\(/),
					),
				},
				_ = [p, o, i, a, e.C_BLOCK_COMMENT_MODE, c, s],
				m = {
					variants: [
						{ begin: /=/, end: /;/ },
						{
							begin: /\(/,
							end: /\)/,
						},
						{ beginKeywords: 'new throw return else', end: /;/ },
					],
					keywords: u,
					contains: _.concat([
						{
							begin: /\(/,
							end: /\)/,
							keywords: u,
							contains: _.concat(['self']),
							relevance: 0,
						},
					]),
					relevance: 0,
				},
				g = {
					className: 'function',
					begin: '(' + r + '[\\*&\\s]+)+' + d,
					returnBegin: !0,
					end: /[{;=]/,
					excludeEnd: !0,
					keywords: u,
					illegal: /[^\w\s\*&:<>.]/,
					contains: [
						{
							begin: 'decltype\\(auto\\)',
							keywords: u,
							relevance: 0,
						},
						{
							begin: d,
							returnBegin: !0,
							contains: [l],
							relevance: 0,
						},
						{
							begin: /::/,
							relevance: 0,
						},
						{ begin: /:/, endsWithParent: !0, contains: [s, c] },
						{
							relevance: 0,
							match: /,/,
						},
						{
							className: 'params',
							begin: /\(/,
							end: /\)/,
							keywords: u,
							relevance: 0,
							contains: [
								a,
								e.C_BLOCK_COMMENT_MODE,
								s,
								c,
								i,
								{
									begin: /\(/,
									end: /\)/,
									keywords: u,
									relevance: 0,
									contains: [
										'self',
										a,
										e.C_BLOCK_COMMENT_MODE,
										s,
										c,
										i,
									],
								},
							],
						},
						i,
						a,
						e.C_BLOCK_COMMENT_MODE,
						o,
					],
				};
			return {
				name: 'C++',
				aliases: ['cc', 'c++', 'h++', 'hpp', 'hh', 'hxx', 'cxx'],
				keywords: u,
				illegal: '</',
				classNameAliases: { 'function.dispatch': 'built_in' },
				contains: [].concat(m, g, p, _, [
					o,
					{
						begin: '\\b(deque|list|queue|priority_queue|pair|stack|vector|map|set|bitset|multiset|multimap|unordered_map|unordered_set|unordered_multiset|unordered_multimap|array|tuple|optional|variant|function)\\s*<(?!<)',
						end: '>',
						keywords: u,
						contains: ['self', i],
					},
					{ begin: e.IDENT_RE + '::', keywords: u },
					{
						match: [
							/\b(?:enum(?:\s+(?:class|struct))?|class|struct|union)/,
							/\s+/,
							/\w+/,
						],
						className: { 1: 'keyword', 3: 'title.class' },
					},
				]),
			};
		};
	})();
	hljs.registerLanguage('cpp', e);
})(); /*! `shell` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var s = (() => {
		'use strict';
		return (s) => ({
			name: 'Shell Session',
			aliases: ['console', 'shellsession'],
			contains: [
				{
					className: 'meta.prompt',
					begin: /^\s{0,3}[/~\w\d[\]()@-]*[>%$#][ ]?/,
					starts: { end: /[^\\](?=\s*$)/, subLanguage: 'bash' },
				},
			],
		});
	})();
	hljs.registerLanguage('shell', s);
})(); /*! `r` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const a = e.regex,
				n = /(?:(?:[a-zA-Z]|\.[._a-zA-Z])[._a-zA-Z0-9]*)|\.(?!\d)/,
				i = a.either(
					/0[xX][0-9a-fA-F]+\.[0-9a-fA-F]*[pP][+-]?\d+i?/,
					/0[xX][0-9a-fA-F]+(?:[pP][+-]?\d+)?[Li]?/,
					/(?:\d+(?:\.\d*)?|\.\d+)(?:[eE][+-]?\d+)?[Li]?/,
				),
				s =
					/[=!<>:]=|\|\||&&|:::?|<-|<<-|->>|->|\|>|[-+*\/?!$&|:<=>@^~]|\*\*/,
				t = a.either(/[()]/, /[{}]/, /\[\[/, /[[\]]/, /\\/, /,/);
			return {
				name: 'R',
				keywords: {
					$pattern: n,
					keyword: 'function if in break next repeat else for while',
					literal:
						'NULL NA TRUE FALSE Inf NaN NA_integer_|10 NA_real_|10 NA_character_|10 NA_complex_|10',
					built_in:
						'LETTERS letters month.abb month.name pi T F abs acos acosh all any anyNA Arg as.call as.character as.complex as.double as.environment as.integer as.logical as.null.default as.numeric as.raw asin asinh atan atanh attr attributes baseenv browser c call ceiling class Conj cos cosh cospi cummax cummin cumprod cumsum digamma dim dimnames emptyenv exp expression floor forceAndCall gamma gc.time globalenv Im interactive invisible is.array is.atomic is.call is.character is.complex is.double is.environment is.expression is.finite is.function is.infinite is.integer is.language is.list is.logical is.matrix is.na is.name is.nan is.null is.numeric is.object is.pairlist is.raw is.recursive is.single is.symbol lazyLoadDBfetch length lgamma list log max min missing Mod names nargs nzchar oldClass on.exit pos.to.env proc.time prod quote range Re rep retracemem return round seq_along seq_len seq.int sign signif sin sinh sinpi sqrt standardGeneric substitute sum switch tan tanh tanpi tracemem trigamma trunc unclass untracemem UseMethod xtfrm',
				},
				contains: [
					e.COMMENT(/#'/, /$/, {
						contains: [
							{
								scope: 'doctag',
								match: /@examples/,
								starts: {
									end: a.lookahead(
										a.either(
											/\n^#'\s*(?=@[a-zA-Z]+)/,
											/\n^(?!#')/,
										),
									),
									endsParent: !0,
								},
							},
							{
								scope: 'doctag',
								begin: '@param',
								end: /$/,
								contains: [
									{
										scope: 'variable',
										variants: [
											{ match: n },
											{ match: /`(?:\\.|[^`\\])+`/ },
										],
										endsParent: !0,
									},
								],
							},
							{ scope: 'doctag', match: /@[a-zA-Z]+/ },
							{ scope: 'keyword', match: /\\[a-zA-Z]+/ },
						],
					}),
					e.HASH_COMMENT_MODE,
					{
						scope: 'string',
						contains: [e.BACKSLASH_ESCAPE],
						variants: [
							e.END_SAME_AS_BEGIN({
								begin: /[rR]"(-*)\(/,
								end: /\)(-*)"/,
							}),
							e.END_SAME_AS_BEGIN({
								begin: /[rR]"(-*)\{/,
								end: /\}(-*)"/,
							}),
							e.END_SAME_AS_BEGIN({
								begin: /[rR]"(-*)\[/,
								end: /\](-*)"/,
							}),
							e.END_SAME_AS_BEGIN({
								begin: /[rR]'(-*)\(/,
								end: /\)(-*)'/,
							}),
							e.END_SAME_AS_BEGIN({
								begin: /[rR]'(-*)\{/,
								end: /\}(-*)'/,
							}),
							e.END_SAME_AS_BEGIN({
								begin: /[rR]'(-*)\[/,
								end: /\](-*)'/,
							}),
							{ begin: '"', end: '"', relevance: 0 },
							{ begin: "'", end: "'", relevance: 0 },
						],
					},
					{
						relevance: 0,
						variants: [
							{
								scope: {
									1: 'operator',
									2: 'number',
								},
								match: [s, i],
							},
							{
								scope: { 1: 'operator', 2: 'number' },
								match: [/%[^%]*%/, i],
							},
							{
								scope: { 1: 'punctuation', 2: 'number' },
								match: [t, i],
							},
							{
								scope: {
									2: 'number',
								},
								match: [/[^a-zA-Z0-9._]|^/, i],
							},
						],
					},
					{
						scope: { 3: 'operator' },
						match: [n, /\s+/, /<-/, /\s+/],
					},
					{
						scope: 'operator',
						relevance: 0,
						variants: [
							{ match: s },
							{
								match: /%[^%]*%/,
							},
						],
					},
					{ scope: 'punctuation', relevance: 0, match: t },
					{ begin: '`', end: '`', contains: [{ begin: /\\./ }] },
				],
			};
		};
	})();
	hljs.registerLanguage('r', e);
})(); /*! `makefile` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		return (e) => {
			const i = {
					className: 'variable',
					variants: [
						{
							begin: '\\$\\(' + e.UNDERSCORE_IDENT_RE + '\\)',
							contains: [e.BACKSLASH_ESCAPE],
						},
						{ begin: /\$[@%<?\^\+\*]/ },
					],
				},
				a = {
					className: 'string',
					begin: /"/,
					end: /"/,
					contains: [e.BACKSLASH_ESCAPE, i],
				},
				n = {
					className: 'variable',
					begin: /\$\([\w-]+\s/,
					end: /\)/,
					keywords: {
						built_in:
							'subst patsubst strip findstring filter filter-out sort word wordlist firstword lastword dir notdir suffix basename addsuffix addprefix join wildcard realpath abspath error warning shell origin flavor foreach if or and call eval file value',
					},
					contains: [i],
				},
				s = { begin: '^' + e.UNDERSCORE_IDENT_RE + '\\s*(?=[:+?]?=)' },
				r = {
					className: 'section',
					begin: /^[^\s]+:/,
					end: /$/,
					contains: [i],
				};
			return {
				name: 'Makefile',
				aliases: ['mk', 'mak', 'make'],
				keywords: {
					$pattern: /[\w-]+/,
					keyword:
						'define endef undefine ifdef ifndef ifeq ifneq else endif include -include sinclude override export unexport private vpath',
				},
				contains: [
					e.HASH_COMMENT_MODE,
					i,
					a,
					n,
					s,
					{
						className: 'meta',
						begin: /^\.PHONY:/,
						end: /$/,
						keywords: { $pattern: /[\.\w]+/, keyword: '.PHONY' },
					},
					r,
				],
			};
		};
	})();
	hljs.registerLanguage('makefile', e);
})(); /*! `javascript` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		const e = '[A-Za-z$_][0-9A-Za-z$_]*',
			n = [
				'as',
				'in',
				'of',
				'if',
				'for',
				'while',
				'finally',
				'var',
				'new',
				'function',
				'do',
				'return',
				'void',
				'else',
				'break',
				'catch',
				'instanceof',
				'with',
				'throw',
				'case',
				'default',
				'try',
				'switch',
				'continue',
				'typeof',
				'delete',
				'let',
				'yield',
				'const',
				'class',
				'debugger',
				'async',
				'await',
				'static',
				'import',
				'from',
				'export',
				'extends',
			],
			a = ['true', 'false', 'null', 'undefined', 'NaN', 'Infinity'],
			t = [
				'Object',
				'Function',
				'Boolean',
				'Symbol',
				'Math',
				'Date',
				'Number',
				'BigInt',
				'String',
				'RegExp',
				'Array',
				'Float32Array',
				'Float64Array',
				'Int8Array',
				'Uint8Array',
				'Uint8ClampedArray',
				'Int16Array',
				'Int32Array',
				'Uint16Array',
				'Uint32Array',
				'BigInt64Array',
				'BigUint64Array',
				'Set',
				'Map',
				'WeakSet',
				'WeakMap',
				'ArrayBuffer',
				'SharedArrayBuffer',
				'Atomics',
				'DataView',
				'JSON',
				'Promise',
				'Generator',
				'GeneratorFunction',
				'AsyncFunction',
				'Reflect',
				'Proxy',
				'Intl',
				'WebAssembly',
			],
			s = [
				'Error',
				'EvalError',
				'InternalError',
				'RangeError',
				'ReferenceError',
				'SyntaxError',
				'TypeError',
				'URIError',
			],
			r = [
				'setInterval',
				'setTimeout',
				'clearInterval',
				'clearTimeout',
				'require',
				'exports',
				'eval',
				'isFinite',
				'isNaN',
				'parseFloat',
				'parseInt',
				'decodeURI',
				'decodeURIComponent',
				'encodeURI',
				'encodeURIComponent',
				'escape',
				'unescape',
			],
			c = [
				'arguments',
				'this',
				'super',
				'console',
				'window',
				'document',
				'localStorage',
				'module',
				'global',
			],
			i = [].concat(r, t, s);
		return (o) => {
			const l = o.regex,
				b = e,
				d = {
					begin: /<[A-Za-z0-9\\._:-]+/,
					end: /\/[A-Za-z0-9\\._:-]+>|\/>/,
					isTrulyOpeningTag: (e, n) => {
						const a = e[0].length + e.index,
							t = e.input[a];
						if ('<' === t || ',' === t) return void n.ignoreMatch();
						let s;
						'>' === t &&
							(((e, { after: n }) => {
								const a = '</' + e[0].slice(1);
								return -1 !== e.input.indexOf(a, n);
							})(e, { after: a }) ||
								n.ignoreMatch()),
							(s = e.input
								.substring(a)
								.match(/^\s+extends\s+/)) &&
								0 === s.index &&
								n.ignoreMatch();
					},
				},
				g = {
					$pattern: e,
					keyword: n,
					literal: a,
					built_in: i,
					'variable.language': c,
				},
				u = '\\.([0-9](_?[0-9])*)',
				m = '0|[1-9](_?[0-9])*|0[0-7]*[89][0-9]*',
				E = {
					className: 'number',
					variants: [
						{
							begin: `(\\b(${m})((${u})|\\.)?|(${u}))[eE][+-]?([0-9](_?[0-9])*)\\b`,
						},
						{
							begin: `\\b(${m})\\b((${u})\\b|\\.)?|(${u})\\b`,
						},
						{
							begin: '\\b(0|[1-9](_?[0-9])*)n\\b',
						},
						{
							begin: '\\b0[xX][0-9a-fA-F](_?[0-9a-fA-F])*n?\\b',
						},
						{
							begin: '\\b0[bB][0-1](_?[0-1])*n?\\b',
						},
						{ begin: '\\b0[oO][0-7](_?[0-7])*n?\\b' },
						{
							begin: '\\b0[0-7]+n?\\b',
						},
					],
					relevance: 0,
				},
				A = {
					className: 'subst',
					begin: '\\$\\{',
					end: '\\}',
					keywords: g,
					contains: [],
				},
				y = {
					begin: 'html`',
					end: '',
					starts: {
						end: '`',
						returnEnd: !1,
						contains: [o.BACKSLASH_ESCAPE, A],
						subLanguage: 'xml',
					},
				},
				N = {
					begin: 'css`',
					end: '',
					starts: {
						end: '`',
						returnEnd: !1,
						contains: [o.BACKSLASH_ESCAPE, A],
						subLanguage: 'css',
					},
				},
				_ = {
					className: 'string',
					begin: '`',
					end: '`',
					contains: [o.BACKSLASH_ESCAPE, A],
				},
				f = {
					className: 'comment',
					variants: [
						o.COMMENT(/\/\*\*(?!\/)/, '\\*/', {
							relevance: 0,
							contains: [
								{
									begin: '(?=@[A-Za-z]+)',
									relevance: 0,
									contains: [
										{
											className: 'doctag',
											begin: '@[A-Za-z]+',
										},
										{
											className: 'type',
											begin: '\\{',
											end: '\\}',
											excludeEnd: !0,
											excludeBegin: !0,
											relevance: 0,
										},
										{
											className: 'variable',
											begin: b + '(?=\\s*(-)|$)',
											endsParent: !0,
											relevance: 0,
										},
										{ begin: /(?=[^\n])\s/, relevance: 0 },
									],
								},
							],
						}),
						o.C_BLOCK_COMMENT_MODE,
						o.C_LINE_COMMENT_MODE,
					],
				},
				h = [o.APOS_STRING_MODE, o.QUOTE_STRING_MODE, y, N, _, E];
			A.contains = h.concat({
				begin: /\{/,
				end: /\}/,
				keywords: g,
				contains: ['self'].concat(h),
			});
			const v = [].concat(f, A.contains),
				p = v.concat([
					{
						begin: /\(/,
						end: /\)/,
						keywords: g,
						contains: ['self'].concat(v),
					},
				]),
				S = {
					className: 'params',
					begin: /\(/,
					end: /\)/,
					excludeBegin: !0,
					excludeEnd: !0,
					keywords: g,
					contains: p,
				},
				w = {
					variants: [
						{
							match: [
								/class/,
								/\s+/,
								b,
								/\s+/,
								/extends/,
								/\s+/,
								l.concat(b, '(', l.concat(/\./, b), ')*'),
							],
							scope: {
								1: 'keyword',
								3: 'title.class',
								5: 'keyword',
								7: 'title.class.inherited',
							},
						},
						{
							match: [/class/, /\s+/, b],
							scope: { 1: 'keyword', 3: 'title.class' },
						},
					],
				},
				R = {
					relevance: 0,
					match: l.either(
						/\bJSON/,
						/\b[A-Z][a-z]+([A-Z][a-z]*|\d)*/,
						/\b[A-Z]{2,}([A-Z][a-z]+|\d)+([A-Z][a-z]*)*/,
						/\b[A-Z]{2,}[a-z]+([A-Z][a-z]+|\d)*([A-Z][a-z]*)*/,
					),
					className: 'title.class',
					keywords: { _: [...t, ...s] },
				},
				O = {
					variants: [
						{
							match: [/function/, /\s+/, b, /(?=\s*\()/],
						},
						{ match: [/function/, /\s*(?=\()/] },
					],
					className: { 1: 'keyword', 3: 'title.function' },
					label: 'func.def',
					contains: [S],
					illegal: /%/,
				},
				k = {
					match: l.concat(
						/\b/,
						((I = [...r, 'super']),
						l.concat('(?!', I.join('|'), ')')),
						b,
						l.lookahead(/\(/),
					),
					className: 'title.function',
					relevance: 0,
				};
			var I;
			const x = {
					begin: l.concat(
						/\./,
						l.lookahead(l.concat(b, /(?![0-9A-Za-z$_(])/)),
					),
					end: b,
					excludeBegin: !0,
					keywords: 'prototype',
					className: 'property',
					relevance: 0,
				},
				T = {
					match: [/get|set/, /\s+/, b, /(?=\()/],
					className: { 1: 'keyword', 3: 'title.function' },
					contains: [{ begin: /\(\)/ }, S],
				},
				C =
					'(\\([^()]*(\\([^()]*(\\([^()]*\\)[^()]*)*\\)[^()]*)*\\)|' +
					o.UNDERSCORE_IDENT_RE +
					')\\s*=>',
				M = {
					match: [
						/const|var|let/,
						/\s+/,
						b,
						/\s*/,
						/=\s*/,
						/(async\s*)?/,
						l.lookahead(C),
					],
					keywords: 'async',
					className: { 1: 'keyword', 3: 'title.function' },
					contains: [S],
				};
			return {
				name: 'Javascript',
				aliases: ['js', 'jsx', 'mjs', 'cjs'],
				keywords: g,
				exports: {
					PARAMS_CONTAINS: p,
					CLASS_REFERENCE: R,
				},
				illegal: /#(?![$_A-z])/,
				contains: [
					o.SHEBANG({
						label: 'shebang',
						binary: 'node',
						relevance: 5,
					}),
					{
						label: 'use_strict',
						className: 'meta',
						relevance: 10,
						begin: /^\s*['"]use (strict|asm)['"]/,
					},
					o.APOS_STRING_MODE,
					o.QUOTE_STRING_MODE,
					y,
					N,
					_,
					f,
					E,
					R,
					{
						className: 'attr',
						begin: b + l.lookahead(':'),
						relevance: 0,
					},
					M,
					{
						begin:
							'(' +
							o.RE_STARTERS_RE +
							'|\\b(case|return|throw)\\b)\\s*',
						keywords: 'return throw case',
						relevance: 0,
						contains: [
							f,
							o.REGEXP_MODE,
							{
								className: 'function',
								begin: C,
								returnBegin: !0,
								end: '\\s*=>',
								contains: [
									{
										className: 'params',
										variants: [
											{
												begin: o.UNDERSCORE_IDENT_RE,
												relevance: 0,
											},
											{
												className: null,
												begin: /\(\s*\)/,
												skip: !0,
											},
											{
												begin: /\(/,
												end: /\)/,
												excludeBegin: !0,
												excludeEnd: !0,
												keywords: g,
												contains: p,
											},
										],
									},
								],
							},
							{ begin: /,/, relevance: 0 },
							{ match: /\s+/, relevance: 0 },
							{
								variants: [
									{ begin: '<>', end: '</>' },
									{
										match: /<[A-Za-z0-9\\._:-]+\s*\/>/,
									},
									{
										begin: d.begin,
										'on:begin': d.isTrulyOpeningTag,
										end: d.end,
									},
								],
								subLanguage: 'xml',
								contains: [
									{
										begin: d.begin,
										end: d.end,
										skip: !0,
										contains: ['self'],
									},
								],
							},
						],
					},
					O,
					{
						beginKeywords: 'while if switch catch for',
					},
					{
						begin:
							'\\b(?!function)' +
							o.UNDERSCORE_IDENT_RE +
							'\\([^()]*(\\([^()]*(\\([^()]*\\)[^()]*)*\\)[^()]*)*\\)\\s*\\{',
						returnBegin: !0,
						label: 'func.def',
						contains: [
							S,
							o.inherit(o.TITLE_MODE, {
								begin: b,
								className: 'title.function',
							}),
						],
					},
					{ match: /\.\.\./, relevance: 0 },
					x,
					{ match: '\\$' + b, relevance: 0 },
					{
						match: [/\bconstructor(?=\s*\()/],
						className: { 1: 'title.function' },
						contains: [S],
					},
					k,
					{
						relevance: 0,
						match: /\b[A-Z][A-Z_0-9]+\b/,
						className: 'variable.constant',
					},
					w,
					T,
					{ match: /\$[(.]/ },
				],
			};
		};
	})();
	hljs.registerLanguage('javascript', e);
})(); /*! `swift` grammar compiled for Highlight.js 11.6.0 */
(() => {
	var e = (() => {
		'use strict';
		function e(e) {
			return e ? ('string' == typeof e ? e : e.source) : null;
		}
		function a(e) {
			return t('(?=', e, ')');
		}
		function t(...a) {
			return a.map((a) => e(a)).join('');
		}
		function n(...a) {
			const t = ((e) => {
				const a = e[e.length - 1];
				return 'object' == typeof a && a.constructor === Object
					? (e.splice(e.length - 1, 1), a)
					: {};
			})(a);
			return (
				'(' +
				(t.capture ? '' : '?:') +
				a.map((a) => e(a)).join('|') +
				')'
			);
		}
		const i = (e) => t(/\b/, e, /\w$/.test(e) ? /\b/ : /\B/),
			s = ['Protocol', 'Type'].map(i),
			u = ['init', 'self'].map(i),
			c = ['Any', 'Self'],
			r = [
				'actor',
				'any',
				'associatedtype',
				'async',
				'await',
				/as\?/,
				/as!/,
				'as',
				'break',
				'case',
				'catch',
				'class',
				'continue',
				'convenience',
				'default',
				'defer',
				'deinit',
				'didSet',
				'distributed',
				'do',
				'dynamic',
				'else',
				'enum',
				'extension',
				'fallthrough',
				/fileprivate\(set\)/,
				'fileprivate',
				'final',
				'for',
				'func',
				'get',
				'guard',
				'if',
				'import',
				'indirect',
				'infix',
				/init\?/,
				/init!/,
				'inout',
				/internal\(set\)/,
				'internal',
				'in',
				'is',
				'isolated',
				'nonisolated',
				'lazy',
				'let',
				'mutating',
				'nonmutating',
				/open\(set\)/,
				'open',
				'operator',
				'optional',
				'override',
				'postfix',
				'precedencegroup',
				'prefix',
				/private\(set\)/,
				'private',
				'protocol',
				/public\(set\)/,
				'public',
				'repeat',
				'required',
				'rethrows',
				'return',
				'set',
				'some',
				'static',
				'struct',
				'subscript',
				'super',
				'switch',
				'throws',
				'throw',
				/try\?/,
				/try!/,
				'try',
				'typealias',
				/unowned\(safe\)/,
				/unowned\(unsafe\)/,
				'unowned',
				'var',
				'weak',
				'where',
				'while',
				'willSet',
			],
			o = ['false', 'nil', 'true'],
			l = [
				'assignment',
				'associativity',
				'higherThan',
				'left',
				'lowerThan',
				'none',
				'right',
			],
			m = [
				'#colorLiteral',
				'#column',
				'#dsohandle',
				'#else',
				'#elseif',
				'#endif',
				'#error',
				'#file',
				'#fileID',
				'#fileLiteral',
				'#filePath',
				'#function',
				'#if',
				'#imageLiteral',
				'#keyPath',
				'#line',
				'#selector',
				'#sourceLocation',
				'#warn_unqualified_access',
				'#warning',
			],
			p = [
				'abs',
				'all',
				'any',
				'assert',
				'assertionFailure',
				'debugPrint',
				'dump',
				'fatalError',
				'getVaList',
				'isKnownUniquelyReferenced',
				'max',
				'min',
				'numericCast',
				'pointwiseMax',
				'pointwiseMin',
				'precondition',
				'preconditionFailure',
				'print',
				'readLine',
				'repeatElement',
				'sequence',
				'stride',
				'swap',
				'swift_unboxFromSwiftValueWithType',
				'transcode',
				'type',
				'unsafeBitCast',
				'unsafeDowncast',
				'withExtendedLifetime',
				'withUnsafeMutablePointer',
				'withUnsafePointer',
				'withVaList',
				'withoutActuallyEscaping',
				'zip',
			],
			d = n(
				/[/=\-+!*%<>&|^~?]/,
				/[\u00A1-\u00A7]/,
				/[\u00A9\u00AB]/,
				/[\u00AC\u00AE]/,
				/[\u00B0\u00B1]/,
				/[\u00B6\u00BB\u00BF\u00D7\u00F7]/,
				/[\u2016-\u2017]/,
				/[\u2020-\u2027]/,
				/[\u2030-\u203E]/,
				/[\u2041-\u2053]/,
				/[\u2055-\u205E]/,
				/[\u2190-\u23FF]/,
				/[\u2500-\u2775]/,
				/[\u2794-\u2BFF]/,
				/[\u2E00-\u2E7F]/,
				/[\u3001-\u3003]/,
				/[\u3008-\u3020]/,
				/[\u3030]/,
			),
			F = n(
				d,
				/[\u0300-\u036F]/,
				/[\u1DC0-\u1DFF]/,
				/[\u20D0-\u20FF]/,
				/[\uFE00-\uFE0F]/,
				/[\uFE20-\uFE2F]/,
			),
			b = t(d, F, '*'),
			h = n(
				/[a-zA-Z_]/,
				/[\u00A8\u00AA\u00AD\u00AF\u00B2-\u00B5\u00B7-\u00BA]/,
				/[\u00BC-\u00BE\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u00FF]/,
				/[\u0100-\u02FF\u0370-\u167F\u1681-\u180D\u180F-\u1DBF]/,
				/[\u1E00-\u1FFF]/,
				/[\u200B-\u200D\u202A-\u202E\u203F-\u2040\u2054\u2060-\u206F]/,
				/[\u2070-\u20CF\u2100-\u218F\u2460-\u24FF\u2776-\u2793]/,
				/[\u2C00-\u2DFF\u2E80-\u2FFF]/,
				/[\u3004-\u3007\u3021-\u302F\u3031-\u303F\u3040-\uD7FF]/,
				/[\uF900-\uFD3D\uFD40-\uFDCF\uFDF0-\uFE1F\uFE30-\uFE44]/,
				/[\uFE47-\uFEFE\uFF00-\uFFFD]/,
			),
			f = n(
				h,
				/\d/,
				/[\u0300-\u036F\u1DC0-\u1DFF\u20D0-\u20FF\uFE20-\uFE2F]/,
			),
			w = t(h, f, '*'),
			y = t(/[A-Z]/, f, '*'),
			g = [
				'autoclosure',
				t(/convention\(/, n('swift', 'block', 'c'), /\)/),
				'discardableResult',
				'dynamicCallable',
				'dynamicMemberLookup',
				'escaping',
				'frozen',
				'GKInspectable',
				'IBAction',
				'IBDesignable',
				'IBInspectable',
				'IBOutlet',
				'IBSegueAction',
				'inlinable',
				'main',
				'nonobjc',
				'NSApplicationMain',
				'NSCopying',
				'NSManaged',
				t(/objc\(/, w, /\)/),
				'objc',
				'objcMembers',
				'propertyWrapper',
				'requires_stored_property_inits',
				'resultBuilder',
				'testable',
				'UIApplicationMain',
				'unknown',
				'usableFromInline',
			],
			E = [
				'iOS',
				'iOSApplicationExtension',
				'macOS',
				'macOSApplicationExtension',
				'macCatalyst',
				'macCatalystApplicationExtension',
				'watchOS',
				'watchOSApplicationExtension',
				'tvOS',
				'tvOSApplicationExtension',
				'swift',
			];
		return (e) => {
			const d = { match: /\s+/, relevance: 0 },
				h = e.COMMENT('/\\*', '\\*/', {
					contains: ['self'],
				}),
				v = [e.C_LINE_COMMENT_MODE, h],
				A = {
					match: [/\./, n(...s, ...u)],
					className: { 2: 'keyword' },
				},
				N = { match: t(/\./, n(...r)), relevance: 0 },
				C = r.filter((e) => 'string' == typeof e).concat(['_|0']),
				D = {
					variants: [
						{
							className: 'keyword',
							match: n(
								...r
									.filter((e) => 'string' != typeof e)
									.concat(c)
									.map(i),
								...u,
							),
						},
					],
				},
				k = {
					$pattern: n(/\b\w+/, /#\w+/),
					keyword: C.concat(m),
					literal: o,
				},
				B = [A, N, D],
				_ = [
					{
						match: t(/\./, n(...p)),
						relevance: 0,
					},
					{
						className: 'built_in',
						match: t(/\b/, n(...p), /(?=\()/),
					},
				],
				S = { match: /->/, relevance: 0 },
				M = [
					S,
					{
						className: 'operator',
						relevance: 0,
						variants: [{ match: b }, { match: `\\.(\\.|${F})+` }],
					},
				],
				x = '([0-9a-fA-F]_*)+',
				I = {
					className: 'number',
					relevance: 0,
					variants: [
						{
							match: '\\b(([0-9]_*)+)(\\.(([0-9]_*)+))?([eE][+-]?(([0-9]_*)+))?\\b',
						},
						{
							match: `\\b0x(${x})(\\.(${x}))?([pP][+-]?(([0-9]_*)+))?\\b`,
						},
						{
							match: /\b0o([0-7]_*)+\b/,
						},
						{ match: /\b0b([01]_*)+\b/ },
					],
				},
				L = (e = '') => ({
					className: 'subst',
					variants: [
						{ match: t(/\\/, e, /[0\\tnr"']/) },
						{
							match: t(/\\/, e, /u\{[0-9a-fA-F]{1,8}\}/),
						},
					],
				}),
				O = (e = '') => ({
					className: 'subst',
					match: t(/\\/, e, /[\t ]*(?:[\r\n]|\r\n)/),
				}),
				T = (e = '') => ({
					className: 'subst',
					label: 'interpol',
					begin: t(/\\/, e, /\(/),
					end: /\)/,
				}),
				$ = (e = '') => ({
					begin: t(e, /"""/),
					end: t(/"""/, e),
					contains: [L(e), O(e), T(e)],
				}),
				j = (e = '') => ({
					begin: t(e, /"/),
					end: t(/"/, e),
					contains: [L(e), T(e)],
				}),
				P = {
					className: 'string',
					variants: [
						$(),
						$('#'),
						$('##'),
						$('###'),
						j(),
						j('#'),
						j('##'),
						j('###'),
					],
				},
				K = {
					match: t(/`/, w, /`/),
				},
				z = [
					K,
					{ className: 'variable', match: /\$\d+/ },
					{
						className: 'variable',
						match: `\\$${f}+`,
					},
				],
				q = [
					{
						match: /(@|#(un)?)available/,
						className: 'keyword',
						starts: {
							contains: [
								{
									begin: /\(/,
									end: /\)/,
									keywords: E,
									contains: [...M, I, P],
								},
							],
						},
					},
					{ className: 'keyword', match: t(/@/, n(...g)) },
					{
						className: 'meta',
						match: t(/@/, w),
					},
				],
				U = {
					match: a(/\b[A-Z]/),
					relevance: 0,
					contains: [
						{
							className: 'type',
							match: t(
								/(AV|CA|CF|CG|CI|CL|CM|CN|CT|MK|MP|MTK|MTL|NS|SCN|SK|UI|WK|XC)/,
								f,
								'+',
							),
						},
						{ className: 'type', match: y, relevance: 0 },
						{ match: /[?!]+/, relevance: 0 },
						{
							match: /\.\.\./,
							relevance: 0,
						},
						{ match: t(/\s+&\s+/, a(y)), relevance: 0 },
					],
				},
				Z = {
					begin: /</,
					end: />/,
					keywords: k,
					contains: [...v, ...B, ...q, S, U],
				};
			U.contains.push(Z);
			const V = {
					begin: /\(/,
					end: /\)/,
					relevance: 0,
					keywords: k,
					contains: [
						'self',
						{
							match: t(w, /\s*:/),
							keywords: '_|0',
							relevance: 0,
						},
						...v,
						...B,
						..._,
						...M,
						I,
						P,
						...z,
						...q,
						U,
					],
				},
				W = { begin: /</, end: />/, contains: [...v, U] },
				G = {
					begin: /\(/,
					end: /\)/,
					keywords: k,
					contains: [
						{
							begin: n(
								a(t(w, /\s*:/)),
								a(t(w, /\s+/, w, /\s*:/)),
							),
							end: /:/,
							relevance: 0,
							contains: [
								{
									className: 'keyword',
									match: /\b_\b/,
								},
								{ className: 'params', match: w },
							],
						},
						...v,
						...B,
						...M,
						I,
						P,
						...q,
						U,
						V,
					],
					endsParent: !0,
					illegal: /["']/,
				},
				R = {
					match: [/func/, /\s+/, n(K.match, w, b)],
					className: { 1: 'keyword', 3: 'title.function' },
					contains: [W, G, d],
					illegal: [/\[/, /%/],
				},
				X = {
					match: [/\b(?:subscript|init[?!]?)/, /\s*(?=[<(])/],
					className: { 1: 'keyword' },
					contains: [W, G, d],
					illegal: /\[|%/,
				},
				H = {
					match: [/operator/, /\s+/, b],
					className: {
						1: 'keyword',
						3: 'title',
					},
				},
				J = {
					begin: [/precedencegroup/, /\s+/, y],
					className: {
						1: 'keyword',
						3: 'title',
					},
					contains: [U],
					keywords: [...l, ...o],
					end: /}/,
				};
			for (const e of P.variants) {
				const a = e.contains.find((e) => 'interpol' === e.label);
				a.keywords = k;
				const t = [...B, ..._, ...M, I, P, ...z];
				a.contains = [
					...t,
					{ begin: /\(/, end: /\)/, contains: ['self', ...t] },
				];
			}
			return {
				name: 'Swift',
				keywords: k,
				contains: [
					...v,
					R,
					X,
					{
						beginKeywords:
							'struct protocol class extension enum actor',
						end: '\\{',
						excludeEnd: !0,
						keywords: k,
						contains: [
							e.inherit(e.TITLE_MODE, {
								className: 'title.class',
								begin: /[A-Za-z$_][\u00C0-\u02B80-9A-Za-z$_]*/,
							}),
							...B,
						],
					},
					H,
					J,
					{
						beginKeywords: 'import',
						end: /$/,
						contains: [...v],
						relevance: 0,
					},
					...B,
					..._,
					...M,
					I,
					P,
					...z,
					...q,
					U,
					V,
				],
			};
		};
	})();
	hljs.registerLanguage('swift', e);
})();
